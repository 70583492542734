/** Inline Combo for Hour selection, styled as Bootstrap.  */
import * as React from 'react';
import { DatesManager } from '../common/util/DatesManager';

/** Value is the Hour 0-23 */
interface IHSProps {
  value: number,
  onChange: (newValue: number) => void,
  disabled?: boolean
}
interface IHSEProps extends IHSProps {
  minHour: number
}

export class InlineHourSelector extends React.PureComponent<IHSProps> {
  private hours = ["00:00", "01:00", "02:00", "03:00", "04:00", "05:00", "06:00", "07:00", "08:00",
    "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00",
    "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00"];

  public render() {
    return (
      <select className='hourpicker-select'
        defaultValue={this.hours[this.props.value]} onChange={this.handleChange}>
        {this.hours.map(h => (<option key={h} value={h}>{h}</option>))}
      </select>
    );
  }

  private handleChange = (e: React.FormEvent<HTMLSelectElement>) => {
    const selectedHourVal = e.currentTarget.value;
    this.props.onChange(this.hours.findIndex(hval => (hval === selectedHourVal)));
  };
}
export class InlineStartHourSelector extends React.PureComponent<IHSProps> {
  private hours = DatesManager.get().getStartHours();

  public render() {
    return (
      <select className='hourpicker-select' disabled={this.props.disabled}
        value={this.hours[this.props.value]} onChange={this.handleChange}>
        {this.hours.map(h => (<option key={h} value={h}>{h}</option>))}
      </select>
    );
  }

  private handleChange = (e: React.FormEvent<HTMLSelectElement>) => {
    const selectedHourVal = e.currentTarget.value;
    this.props.onChange(this.hours.findIndex(hval => (hval === selectedHourVal)));
  };
}

export class InlineEndHourSelector extends React.PureComponent<IHSEProps> {

  public render() {
    const hours = DatesManager.get().getEndHours(this.props.minHour);
    return (
      <select className='hourpicker-select' disabled={this.props.disabled}
        value={hours[this.props.value - this.props.minHour]} onChange={this.handleChange}>
        {hours.map(h => (<option key={h} value={h}>{h}</option>))}
      </select>
    );
  }

  private handleChange = (e: React.FormEvent<HTMLSelectElement>) => {
    const selectedHourVal = e.currentTarget.value;
    const hours = DatesManager.get().getEndHours(this.props.minHour);
    this.props.onChange(hours.findIndex(hval => (hval === selectedHourVal)) + this.props.minHour);
  };
}
