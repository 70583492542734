import { getLogger } from "./pmlogger";
import i18next from "i18next";
import { TsParsedContact } from "../tsmodel/TsParsedContact";

/**
 * Formatting / Parsing utilities.
 * Singleton: use Formatter.get().
 * 
 * It provides Formatting/Validation. Date/Time formatting is deprecated - See DatesManager.
 * The default Locale is the Browser Locale. You can set it explicitely in the constructor, or using .setLocale().
 * 
 * @author Philippe Martinou (corole)
 */

// Year Month Day must be supported (see https://developer.mozilla.org/fr/docs/Web/JavaScript/Reference/Objets_globaux/DateTimeFormat)
//const sndateOptions = {     // Directly inserted in code see lines 137,142
//  year: "numeric",
//  month: "2-digit",
//  day: "2-digit"
//};

const allLocales = [
  'en-US',
  'ca-ES',
  'cs-CZ',
  'cx-PH',
  'cy-GB',
  'da-DK',
  'de-DE',
  'eu-ES',
  'en-UD',
  'es-LA',
  'es-ES',
  'gn-PY',
  'fi-FI',
  'fr-FR',
  'gl-ES',
  'hu-HU',
  'it-IT',
  'ja-JP',
  'ko-KR',
  'nb-NO',
  'nn-NO',
  'nl-NL',
  'fy-NL',
  'pl-PL',
  'pt-BR',
  'pt-PT',
  'ro-RO',
  'ru-RU',
  'sk-SK',
  'sl-SI',
  'sv-SE',
  'th-TH',
  'tr-TR',
  'ku-TR',
  'zh-CN',
  'zh-HK',
  'zh-TW',
  'af-ZA',
  'sq-AL',
  'hy-AM',
  'az-AZ',
  'be-BY',
  'bn-IN',
  'bs-BA',
  'bg-BG',
  'hr-HR',
  'nl-BE',
  'en-GB',
  'et-EE',
  'fo-FO',
  'fr-CA',
  'ka-GE',
  'el-GR',
  'gu-IN',
  'hi-IN',
  'is-IS',
  'id-ID',
  'ga-IE',
  'jv-ID',
  'kn-IN',
  'kk-KZ',
  'lv-LV',
  'lt-LT',
  'mk-MK',
  'mg-MG',
  'ms-MY',
  'mt-MT',
  'mr-IN',
  'mn-MN',
  'ne-NP',
  'pa-IN',
  'sr-RS',
  'so-SO',
  'sw-KE',
  'tl-PH',
  'ta-IN',
  'te-IN',
  'ml-IN',
  'uk-UA',
  'uz-UZ',
  'vi-VN',
  'km-KH',
  'tg-TJ',
  'ar-AR',
  'he-IL',
  'ur-PK',
  'fa-IR',
  'ps-AF',
  'my-MM',
  'qz-MM',
  'or-IN',
  'si-LK',
  'rw-RW',
  'cb-IQ',
  'ha-NG',
  'ja-KS',
  'br-FR',
  'tz-MA',
  'co-FR',
  'as-IN',
  'ff-NG',
  'sc-IT',
  'sz-PL'];


export class Formatter {
  static instance = new Formatter();
  static get = () => (Formatter.instance);

  // Day - Month - Year order in Short Numeric Dates ( 22/08/2019 )
  private sndateFieldOrder: Array<'D' | 'M' | 'Y'> = [];
  private sndateSeparator: string = "";
  private sndateFormatter: Intl.DateTimeFormat;

  constructor(locale?: string) {
    this.sndateFormatter = new Intl.DateTimeFormat(locale, { year: 'numeric', month: '2-digit', day: '2-digit' });
    this.setLocale(locale);
  }

  public setLocale(locale?: string) {
    this.sndateFormatter = new Intl.DateTimeFormat(locale, { year: 'numeric', month: '2-digit', day: '2-digit' });
    this.sndateFieldOrder = [];
    // console.error("OPTIONS: %o", this.sndateFormatter.resolvedOptions());
    this.sndateFormatter.formatToParts().forEach(({ type, value }) => {
      switch (type) {
        case 'literal':
          this.sndateSeparator = value; break;
        case 'day':
          this.sndateFieldOrder.push('D'); break;
        case 'month':
          this.sndateFieldOrder.push('M'); break;
        case 'year':
          this.sndateFieldOrder.push('Y'); break;
        default: break;
      }
    });
    if (this.sndateFieldOrder.length !== 3 || ("-/. ".indexOf(this.sndateSeparator) < 0)) {
      getLogger().error("formatter", "Unrecognized Date format options: %o", this.sndateFormatter.formatToParts());
      // Default to ISO 8601 format
      this.sndateSeparator = "-";
      this.sndateFieldOrder = ["Y", "M", "D"];
    }
  }

  /** 
   * Parses a Date in Short Numeric format.
   * @deprecated
   * @returns the parsed date, or undefined if the string is not parseable as a date.
   */
  public parseSndate = (str: string) => {
    if (str == null) {
      return undefined;
    }
    const elements = str.split(this.sndateSeparator);
    if (elements.length !== 3) {
      console.error("Invalid Date Elements count: %o", elements);
      return undefined;
    }
    let day = -1, month = -1, year = -1;
    this.sndateFieldOrder.forEach((type, ix) => {
      switch (type) {
        case 'D':
          if (elements[ix].length === 2) {
            // else ignore - date being edited, incomplete
            day = parseInt(elements[ix]);
          }
          break;
        case 'M':
          if (elements[ix].length === 2) {
            // else ignore - date being edited, incomplete
            month = parseInt(elements[ix]);
          }
          break;
        case 'Y':
          if (elements[ix].length === 4) {
            // else ignore - date being edited, incomplete
            year = parseInt(elements[ix]);
          }
          break;
      }
    });
    if (day === -1 || isNaN(day) || month === -1 || isNaN(month) || year === -1 || isNaN(year)) {
      // missing component
      return undefined;
    }
    const d = new Date(year, month - 1, day);
    if (isNaN(d.getTime())) {
      // Invalid date
      return undefined;
    }
    return d;
  }

  /**
   * @deprecated
   */
  public formatSndate = (d: Date) => {
    return this.sndateFormatter.format(d);
  }
  /**
   * @deprecated
   */
  public formatSndateMillis = (m: number) => {
    const d: Date = new Date(m);
    return this.sndateFormatter.format(d);
  }

  /**
   * @deprecated
   * @returns the format string with dd,MM,yyyy for a Short Numeric Date format.
   */
  public getSndateFormatString = (): string => {
    return this.sndateFieldOrder.map(type => {
      switch (type) {
        case 'D': return "dd";
        case 'M': return "MM";
        case 'Y': return "yyyy";
        default: return "";
      }
    }).join(this.sndateSeparator);
  }

  public listSupportedLocales = () => {
    const ret = Intl.DateTimeFormat.supportedLocalesOf(allLocales);
    return ret;
  }

  // const mailRegexp = "^[_A-Za-z0-9-\\+\\.]+@" + "[A-Za-z0-9-]+(\\.[A-Za-z0-9-]+)*(\\.[A-Za-z]{2,})$";
  // Above is the Java one; Below the W3C 'email' type
  // const mailRegexp = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  // Use the Java one, for consistency with the Server
  private static mailRegexp = /^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[A-Za-z]{2,})$/;

  public isValidEmail = (s: string) => {
    return Formatter.mailRegexp.test(s);
  }

  /** Returns true if the Code, stripped of spaces and -, is of good length */
  public isGoodLength = (s: string, len: number): boolean => {
    if (!s) {
      return false;
    }
    return s.replace(" ", "").replace("-", "").length === len;
  }

  /** Created at first use, as it is an i18n-dependent expression. */
  private remDangerousChars?: RegExp;

  /** Keeps only the 0-9, Letters A-Z,a-z and accented, - and space. */
  public sanitizeName = (name: string) => {
    if (!this.remDangerousChars) {
      this.remDangerousChars = new RegExp(i18next.t('global.notAlphanumRegexp'), 'g');
    }
    return name.replace(this.remDangerousChars, '');
  }
}



/**
 * Checks whether the Dates is initialized and within bounds.
 * @deprecated
 * @param d - Date in millis since 1/1/70 Oh UTC
 * @param minD  Minimum Date - Date in millis since 1/1/70 Oh UTC
 * @param maxD Maximum Date - Date in millis since 1/1/70 Oh UTC
 * @return an error message if not OK
 */
export const validateDate = (d: number, minD?: number, maxD?: number) => {
  if (d === 0) {
    return i18next.t('basics.DayHourRangeSelector.err-unset-from');
  }
  if (minD != null && minD > d) {
    return i18next.t('basics.DayHourRangeSelector.err-from-belowmin', { min: new Date(minD).toLocaleString() });
  }
  if (maxD != null && maxD < d) {
    return i18next.t('basics.DayHourRangeSelector.err-to-abovemax', { max: new Date(maxD).toLocaleString() });
  }
  return '';
}

/** 
 * Retourne la date à 00:00
 * @deprecated
 */
export const to0h = (d: Date): Date => {
  const date0 = new Date(d);
  date0.setHours(0);
  date0.setMinutes(0);
  date0.setSeconds(0);
  date0.setMilliseconds(0);
  return date0;
}

/** 
 * Retourne la date à l'heure n
 * @deprecated
 */
export const toNh = (d: Date, hour: number): Date => {
  const date0 = new Date(d);
  date0.setHours(hour);
  date0.setMinutes(0);
  date0.setSeconds(0);
  date0.setMilliseconds(0);
  return date0;
}

/** 
 * Extrait l'heure de la date indiquée (local time)
 * @deprecated
 */
export const getHour = (d: Date): number => {
  return d.getHours();
}

/** 
 * Décompose les start/end dates pour ScrutinView.
 * @deprecated
 */
export const decomposeDates = (openDateMillis: number, closeDateMillis: number, minDateMillis: number, maxDateMillis: number, editable: boolean): DecomposedDates => {
  let startDate = new Date(openDateMillis);
  let endDate = new Date(closeDateMillis);
  let startHour = startDate.getHours();
  let endHour = endDate.getHours();
  if (editable) {
    if (openDateMillis < minDateMillis) {
      startDate = new Date(minDateMillis);
    }
    if (closeDateMillis > maxDateMillis) {
      endDate = new Date(maxDateMillis);
    }
    if (startDate > endDate) {
      endDate = startDate;
    }
    if (startDate === endDate) {
      if (startHour > endHour) {
        endHour = startHour;
      }
    }
  }
  return { startDate, endDate, startHour, endHour };
}
/**
 * @deprecated
 */
export interface DecomposedDates {
  startDate: Date,
  endDate: Date,
  startHour: number,
  endHour: number
}

export const contactDisplayString = (pc: TsParsedContact): string => {
  const dn = (pc.displayName === '' ? "" : pc.displayName + ' ');
  return dn + pc.emails.map(eml => "<" + eml + ">").join(', ');
}
