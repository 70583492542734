import { getHttpLayer } from "../common/backend/HttpLayer";
import { SupportedLang } from "../common/tsmodel/TsModelDefs";
import { TsMailSecret, TsPingBody, TsSendSecret, TsSidEmail, TsVote, TsVoterId } from "../common/tsmodel/TsQueries";
import { TsCodesResponse, TsPingResponse, TsScrutinViewResponse, TsVoteResponse, TsVoterStatus, TsVoterStatuses } from "../common/tsmodel/TsResponses";

export interface VoteApi {
  /** Allows to check that some data volume passes back and forth. */
  votePingPost: (data: string) => Promise<TsPingResponse>;

  /** Given a ScrutinID (+mac) and Email, returns the matching Voters and their statuses. */
  voterStatuses: (scrutinId: string, email: string, mac?: string) => Promise<TsVoterStatuses>;

  /** Returns the public status of a Voter, given its VoterId and ScrutinID. If mac is not provided, only for ACTIVE/FINISHED scrutins. */
  voterStatus: (scrutinId: string, voterId: string, voterMail: string, mac?: string) => Promise<TsVoterStatus>;

  /** Expresses a Vote for a TESTING scrutin. */
  testVote: (scrutinId: string, voterId: string, voterMail: string, voterSecret: string, choices: string[], mac: string) => Promise<TsVoteResponse>;

  /** Sends the VoterSecret to the email of the specified Voter, identified by scrutinId, voterId, voterMail.  If mac is not provided, only for ACTIVE/FINISHED scrutins. */
  sendSecret: (scrutinId: string, voterId: string, voterMail: string, lang: SupportedLang, timezone: string, mac?: string) => Promise<TsVoterStatus>;

  /** Validates the Mail/Secret pair and returns the Vote Codes. */
  validateSecret: (scrutinId: string, email: string, secret: string, mac?: string) => Promise<TsCodesResponse>;

  /** Expresses a Vote for an ACTIVE scrutin. */
  realVote: (scrutinId: string, voterId: string, voterMail: string, voterSecret: string, choices: string[]) => Promise<TsVoteResponse>;

  /** 
   * Returns all public info for a Scrutin, including the VoteURL and the vote results.
   * Does not disclose organizer info (mac, sOrganizerEmail, ...) and follows VotersVisibility.
   * Can be exploited by a independent page.
   */
  viewScrutin: (scrutinId: string, mac?: string) => Promise<TsScrutinViewResponse>;
}

/** Implementation */
export class VoteApiImpl implements VoteApi {

  /** Allows to check that some data volume passes back and forth. */
  public votePingPost = (data: string): Promise<TsPingResponse> => {
    const body = new TsPingBody(data);
    return getHttpLayer().HttpPostPromise<TsPingBody, TsPingResponse>("/votepingpost", body);
  }

  /** Returns the public status of a Voter, given its email and ScrutinID. If mac is not provided, only for ACTIVE/FINISHED scrutins. */
  public voterStatus = (scrutinId: string, voterId: string, voterMail: string, mac?: string): Promise<TsVoterStatus> => {
    const body = new TsVoterId(scrutinId, voterId, voterMail, mac);
    return getHttpLayer().HttpPostPromise<TsVoterId, TsVoterStatus>("/vstatus", body);
  }

  /** Returns the matching Voters, given an email and ScrutinID. If mac is not provided, only for ACTIVE/FINISHED scrutins. */
  public voterStatuses = (scrutinId: string, email: string, mac?: string): Promise<TsVoterStatuses> => {
    const body = new TsSidEmail(scrutinId, email, mac);
    return getHttpLayer().HttpPostPromise<TsSidEmail, TsVoterStatuses>("/votersbymail", body);
  }

  /** Expresses a Vote for a TESTING scrutin. */
  public testVote = (scrutinId: string, voterId: string, voterMail: string, voterSecret: string, choices: string[], mac: string): Promise<TsVoteResponse> => {
    const body = new TsVote(scrutinId, voterId, voterMail, voterSecret, choices, mac);
    return getHttpLayer().HttpPostPromise<TsVote, TsVoteResponse>("/vtestvote", body);
  }

  /** Sends the VoterSecret to the email of the specified Voter, identified by scrutinId, voterId, voterMail.  If mac is not provided, only for ACTIVE/FINISHED scrutins. */
  public sendSecret = (scrutinId: string, voterId: string, voterMail: string, lang: SupportedLang, timezone: string, mac?: string): Promise<TsVoterStatus> => {
    const body = new TsSendSecret(scrutinId, voterId, voterMail, lang, timezone, false, mac);
    return getHttpLayer().HttpPostPromise<TsSendSecret, TsVoterStatus>("/vsendsecret", body);
  }

  public validateSecret = (scrutinId: string, email: string, secret: string, mac?: string): Promise<TsCodesResponse> => {
    const body = new TsMailSecret(scrutinId, email, secret, mac);
    return getHttpLayer().HttpPostPromise<TsMailSecret, TsCodesResponse>("/validatesecret", body);
  }


  /** Expresses a Vote for an ACTIVE scrutin. */
  public realVote = (scrutinId: string, voterId: string, voterMail: string, voterSecret: string, choices: string[]): Promise<TsVoteResponse> => {
    const body = new TsVote(scrutinId, voterId, voterMail, voterSecret, choices);
    return getHttpLayer().HttpPostPromise<TsVote, TsVoteResponse>("/vvote", body);
  }

  /** 
   * Returns all public info for a Scrutin, including the VoteURL and the vote results.
   * Does not disclose organizer info (mac, sOrganizerEmail, ...) and follows VotersVisibility.
   * Can be exploited by a independent page.
   */
  public viewScrutin = (scrutinId: string, mac?: string): Promise<TsScrutinViewResponse> => {
    if (mac) {
      return getHttpLayer().HttpGetPromise<TsScrutinViewResponse>("/vview/" + scrutinId + "/" + mac);
    } else {
      return getHttpLayer().HttpGetPromise<TsScrutinViewResponse>("/vview/" + scrutinId);
    }
  }
}

/** Current instance, may be replaced for Testing. */
let currentInstance: VoteApi = new VoteApiImpl();

export const getVoteApi = () => (currentInstance);
export const setVoteApi = (voteApi: VoteApi) => {
  currentInstance = voteApi;
}
