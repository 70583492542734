import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { XLations } from './common/XLations';
import { PmConsoleLog } from './common/util/pmconsolelog';
import { setLogger, LogLevel } from './common/util/pmlogger';

const logr: PmConsoleLog = new PmConsoleLog();
logr.setDefaultLevel(LogLevel.INFO);
logr.addSpecificLevel("vsstore", LogLevel.DEBUG);
setLogger(logr);

i18next.use(LanguageDetector).init({ resources: XLations, fallbackLng: 'fr', debug: false }).then(() => {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  );
});


