import { DateTime, Settings } from 'luxon';
import { getLogger } from './pmlogger';
import i18next from 'i18next';

/**
 * Gestion localisée des dates et heures dans l'application.
 * ATTENTION: pour qu'une Locale soit réellement supportée par Moment, il faut l'import ci-dessus
 * ('moment/locale/fr').
 * En effet, on observe dans Firefox un comportement différent de celui de NodeJS (Unit Tests): un 
 * appel à moment.locale(locale) ne change pas globalement la Locale sinon: les moments créés subséquemment sont en 'en'.
 * <p/>
 * Changement de moment => Luxon, cf https://momentjs.com/docs/ - not reco for new projects, and Intl support using Intl
 * <br/>
 * https://moment.github.io/luxon/
 */
export class DatesManager {
  private static instance = new DatesManager();
  /** Singleton. */
  public static get = () => (DatesManager.instance);

  private static LOGTAG = "datesmanager";

  private currentLocale: string;
  private startHours: string[] = [];   // 00:00 etc
  private endHours: string[] = [];     // 21:59 etc

  constructor() {
    // On a browser, that means whatever the user has their browser or OS language set to.
    this.currentLocale = DateTime.now().locale;
    this.updateHours();
    getLogger().info(DatesManager.LOGTAG, "Locale set to %s", this.currentLocale);
  }

  /** Force a Locale different from the default one. */
  public setLocale = (locale: string): void => {
    Settings.defaultLocale = locale;
    this.currentLocale = DateTime.now().locale;
    this.updateHours();
    getLogger().info(DatesManager.LOGTAG, "Locale set to %s", this.currentLocale);
  }

  public getLocale = (): string => {
    return this.currentLocale;
  }

  public getStartHours = () => (this.startHours);
  public getEndHours = (minHour: number) => (this.endHours.slice(minHour, 24));

  /** 12/12/2020 */
  public formatShortNumericDate = (dt: DateTime): string => {
    return dt.toLocaleString(DateTime.DATE_SHORT);
  }

  /** 12/12/2020 12:30 */
  public formatShortNumericDateTime = (dt: DateTime): string => {
    return dt.toLocaleString(DateTime.DATETIME_SHORT);
  }

  public formatVeryShortNumericDateTime = (dt: DateTime): string => {
    return dt.toLocaleString({ day: 'numeric', month: 'numeric', year: '2-digit', hour: 'numeric', minute: 'numeric' });
  }
  public formatVeryShortYearlessNumericDateTime = (dt: DateTime): string => {
    return dt.toLocaleString({ day: 'numeric', month: 'numeric', hour: 'numeric', minute: 'numeric' });
  }

  public formatMedNumericDateTime = (dt: DateTime): string => {
    return dt.toLocaleString(DateTime.DATETIME_MED);
  }

  /** 12/12/2020 12:30 ou 'maintenant' */
  public formatFutureShortNumericDateTime = (dateMillis: number): string => {
    if (dateMillis <= new Date().getTime()) {
      return i18next.t('basics.DatesManager.maintenant');
    }
    return this.formatShortNumericDateTime(DateTime.fromMillis(dateMillis));
  }

  /** Retourne true si la date (J/M/A) est la même. */
  public isSameDate = (dt1: DateTime, dt2: DateTime) => {
    return (dt1.year === dt2.year && dt1.month === dt2.month && dt1.day === dt2.day);
  }

  /**
   * Presets sensible values for Open/Close dates if not set, and adjust invalid date ranges (assumed editable).
   * NOTE: avoid storing Moments -- they do not adjust when the locale is globally changed.
   */
  public computeOpenCloseMoments = (openDateMillis: number, closeDateMillis: number): [DateTime, DateTime] => {
    // Default Open Date: Now; Default Close Date: Tomorrow 19:59
    const startMoment = openDateMillis === 0 ? DateTime.now() : DateTime.fromMillis(openDateMillis);
    const endMoment = closeDateMillis === 0 ? startMoment.plus({ days: 1 }).set({ hour: 19 }) : DateTime.fromMillis(closeDateMillis);
    // Enforce MaxDate
    // .minute(59).second(0).millisecond(0);
    return [startMoment, endMoment];
  }

  /** Provide a Max Date : 4 month from now. */
  public getMaxDate = () => {
    return DateTime.now().startOf('day').plus({ months: 4 });
  }

  /** Applique la startHour sans changer les dates. Peut ajuster la EndHour pour garder start<end */
  public applyStartHour = (openDateMillis: number, closeDateMillis: number, newStartHour: number) => {
    const openDate = new Date(openDateMillis);
    openDate.setHours(newStartHour);
    const closeDate = new Date(closeDateMillis);
    this.ensureEndAfterStart(openDate, closeDate);
    return [openDate.getTime(), closeDate.getTime()];
  }

  /** Applique la endHour sans changer les dates. Peut ajuster la EndHour pour garder start<end */
  public applyEndHour = (openDateMillis: number, closeDateMillis: number, newStartHour: number) => {
    const openDate = new Date(openDateMillis);
    const closeDate = new Date(closeDateMillis);
    closeDate.setHours(newStartHour);
    this.ensureEndAfterStart(openDate, closeDate);
    return [openDate.getTime(), closeDate.getTime()];
  }

  /** Applique les dates sans changer les temps, sauf pour ajuster les Heures pour garder start<end */
  public applyDates = (openDateMillis: number, closeDateMillis: number, newOpenDate: Date, newCloseDate: Date) => {
    const openDate = this.applyNewDate(new Date(openDateMillis), newOpenDate);
    const closeDate = this.applyNewDate(new Date(closeDateMillis), newCloseDate);
    this.ensureEndAfterStart(openDate, closeDate);
    return [openDate.getTime(), closeDate.getTime()];
  }

  /** Applique la Date (jour-mois-année) de newDate à la Date d, sans changer le temps (h:m:s...) */
  private applyNewDate = (d: Date, newDate: Date): Date => {
    d.setDate(newDate.getDate());
    d.setMonth(newDate.getMonth());
    d.setFullYear(newDate.getFullYear());
    return d;
  }

  /** Change la EndHour si besoin pour garder start<end. */
  private ensureEndAfterStart = (openDate: Date, closeDate: Date) => {
    if (openDate.getFullYear() === closeDate.getFullYear()
      && openDate.getMonth() === closeDate.getMonth()
      && openDate.getDate() === closeDate.getDate()) {
      if (closeDate.getHours() < openDate.getHours()) {
        closeDate.setHours(openDate.getHours());
      }
    }
  }

  /** Calcule la liste des heures affichées dans les sélecteurs d'heures. */
  private updateHours = () => {
    this.startHours = [];
    for (let h = 0; h < 24; h++) {
      // See https://moment.github.io/luxon/#/formatting?id=formatting-with-tokens-strings-for-cthulhu
      const hourString = DateTime.now().startOf('day').plus({ hours: h }).toLocaleString(DateTime.TIME_SIMPLE);
      this.startHours.push(hourString);
    }
    this.endHours = [];
    for (let h = 0; h < 24; h++) {
      const hourString = DateTime.now().startOf('day').plus({ hours: h, minutes: 59 }).toLocaleString(DateTime.TIME_SIMPLE);
      this.endHours.push(hourString);
    }
  }

  /**
   * Checks whether the From/To dates are initialized, within bounds, and from < to.
   * @deprecated
   * @param from - Date in millis since 1/1/70 Oh UTC
   * @param to  - Date in millis since 1/1/70 Oh UTC
   * @param minFrom  Minimum Date - Date in millis since 1/1/70 Oh UTC
   * @param maxTo Maximum Date - Date in millis since 1/1/70 Oh UTC
   * @return an error message if not OK
   */
  public validateDateRange = (from: number, to: number, minFrom?: number, maxTo?: number) => {
    if (from === 0) {
      return i18next.t('basics.DayHourRangeSelector.err-unset-from');
    }
    if (to === 0) {
      return i18next.t('basics.DayHourRangeSelector.err-unset-to');
    }
    if (from >= to) {
      return i18next.t('basics.DayHourRangeSelector.err-from-to-order');
    }
    if (minFrom != null && minFrom > from) {
      return i18next.t('basics.DayHourRangeSelector.err-from-belowmin', { min: new Date(minFrom).toLocaleString() });
    }
    if (maxTo != null && maxTo < to) {
      return i18next.t('basics.DayHourRangeSelector.err-to-abovemax', { max: new Date(maxTo).toLocaleString() });
    }
    return '';
  }

}
