import { LogLevel, PmLogger } from './pmlogger';

// tslint:disable:no-console
export class PmConsoleLog implements PmLogger {
  /** The LogLevel applied unless the TAG is is specificLevels. */
  private defaultLevel: LogLevel = LogLevel.INFO;
  /** Maps a LogLevel to a TAG. */
  private specificLevels: { [key: string]: LogLevel } = {};

  public debug(tag: string | string[], msg: string | (() => string) | Error, ...args: any[]): void {
    this._logimpl(console.debug, LogLevel.DEBUG, tag, msg, args);
  }
  public info(tag: string | string[], msg: string | (() => string) | Error, ...args: any[]): void {
    this._logimpl(console.info, LogLevel.INFO, tag, msg, args);
  }
  public warn(tag: string | string[], msg: string | Error | (() => string), ...args: any[]): void {
    this._logimpl(console.warn, LogLevel.WARN, tag, msg, args);
  }
  public error(tag: string | string[], msg: string | Error | (() => string), ...args: any[]): void {
    this._logimpl(console.error, LogLevel.ERROR, tag, msg, args);
  }

  public isDebugEnabled(tag?: string): boolean {
    return this._isLevelEnabled(LogLevel.DEBUG, tag);
  }

  public isInfoEnabled(tag?: string): boolean {
    return this._isLevelEnabled(LogLevel.INFO, tag);
  }
  public isWarnEnabled(tag?: string): boolean {
    return this._isLevelEnabled(LogLevel.WARN, tag);
  }
  public isErrorEnabled(tag?: string): boolean {
    return this._isLevelEnabled(LogLevel.ERROR, tag);
  }


  public getDefaultLevel(): LogLevel {
    return this.defaultLevel;
  }
  public setDefaultLevel(level: LogLevel): void {
    this.defaultLevel = level;
  }
  public addSpecificLevel(tag: string, level: LogLevel): void {
    this.specificLevels[tag] = level;
  }
  public removeSpecificLevel(tag: string) {
    delete this.specificLevels[tag];
  }
  public removeAllSpecificLevels() {
    for (const tag in this.specificLevels) {
      if (this.specificLevels.hasOwnProperty(tag)) {
        delete this.specificLevels[tag];
      }
    }
  }

  private _logimpl(fn: any, level: LogLevel, tag: string | string[], msg: string | (() => string) | Error, args: any[]): void {
    if (typeof tag === 'string') {
      if (this._isLevelEnabled(level, tag)) {
        this._logit(tag, fn, msg, args);
      }
    } else {
      // Check if Log is enabled for at least one tag
      for (const onetag of tag) {
        if (this._isLevelEnabled(level, onetag)) {
          this._logit(onetag, fn, msg, args);
          return;
        }
      }
    }
  }

  private _logit(tag: string, fn: any, msg: string | (() => string) | Error, args: any[]) {
    if (typeof msg === 'string') {
      fn("[" + tag + "] " + msg, ...args);
    } else if (typeof msg === 'function') {
      fn("[" + tag + "] " + msg(), ...args);
    } else if (msg instanceof Error) {
      fn(msg as Error);
    } else {
      console.error("Invalid msg Type in Logger: ", msg);
    }
  }
  private _isLevelEnabled(level: LogLevel, tag: string | string[] | undefined) {
    if (typeof tag === 'string') {
      let tagLevel = this.specificLevels[tag];
      if (!tagLevel) {
        tagLevel = this.defaultLevel;
      }
      return (level >= tagLevel);
    } else if (tag === undefined) {
      return (level >= this.defaultLevel);
    } else {
      // Check if Log is enabled for at least one tag
      for (const onetag of tag) {
        if (this._isLevelEnabled(level, onetag)) {
          return true;
        }
      }
    }
    return false;
  }
}
