import React from "react";
import { Alert, Modal, Button } from "react-bootstrap";
import { RouteComponentProps } from "react-router";
import { ScrutinView } from "../ScrutinView";
import { appStore, AppStore } from "../../app/AppStore";
import i18next from "i18next";
import { SyncObject, SyncStatus } from "../../common/backend/SyncObject";
import { getLogger } from "../../common/util/pmlogger";
import { EditorNav } from "./EditorNav";
import { Validators } from "../../workers/Validators";
import { VotersView } from "../VotersView";
import { CandidatesView } from "../CandidatesView";
import { SummaryView } from "../SummaryView";
import { UnSync } from "../Unsync";
import { CandidatesBean, ScrutinBean, TsCreateUpdateScrutin } from "../../backend/tsmodel/TsCreateUpdateScrutin";
import { TsModelDefs } from "../../common/tsmodel/TsModelDefs";
import { TsParsedContact } from "../../common/tsmodel/TsParsedContact";
import { ErrCodes, ErrCodeString } from "../../common/backend/ErrCodes";
import { errMessage } from "../../common/tsmodel/Converters";

/**
 * Modal view that contains the Scrutin Editor and manages the editor pages.
 * Path contains the mac/scrutinId or not (in case of Local Bean), e.g. /create, or /edit/1234
 * Pattern: WIZARD. Il gère localement la sous-vue à afficher, et rend lui même ses composants, en
 * pouvant y injecter des props.
 * <p/>
 * <h2>Fonctionnement</h2>
 * Au début, seul le Tab "SCRUTIN" est accessible, et sélectionné. Il apparaît grisé et les erreurs ne sont pas
 * montrées sur le formulaire Scrutin.
 * <br>Lors du clic sur "Suivant", on évalue l'état du formulaire et si OK, on rend VOTERS accessible et bascule dessus.
 * Si on revient sur SCRUTIN, soit par Précédent soit par clic sur le Tab, on peut modifier les params et on valide à chaque changement.
 * <p/>
 * Etat des Tabs:
 * INACCESSIBLE (gris non cliquable) => NEUTRAL (gris cliquable) => GREEN <=> RED
 */


interface MatchParams {
  mac?: string,
  scrutinId?: string
}
interface ECState {
  /** id is mac+'/'+scrutinId */
  currentId?: string,
  /** Tab actuellement sélectionné. */
  tab: AppTabs,
  /** Attente de confirmation d'exit du Wizard. */
  closeDialog: boolean,
  /** Confirm close: if closing is due to error */
  closeError?: ErrCodes,
  /** Etat du Tab Scrutin. */
  scrutinTab: TabState,
  votersTab: TabState,
  candidatesTab: TabState,
  summaryTab: TabState,
  /** Pour indiquer à VotersView si doit revalider les votersLine et afficher une erreur. */
  votersSubmitted: boolean,
  // save on server in progress
  submitting: boolean
}

export enum AppTabs {
  SCRUTIN,
  VOTERS,
  CANDIDATES,
  SUMMARY,
  TEST,
  RESULTS
}
export enum TabState {
  NOT_INITIALIZED,
  INACCESSIBLE,
  NEUTRAL,
  GREEN,
  RED
}


export class EditorContainer extends React.PureComponent<RouteComponentProps<MatchParams>, ECState> {

  private static LOGTAG = "edcontainer";

  constructor(props: RouteComponentProps<MatchParams>) {
    super(props);
    this.state = {
      tab: props.location.pathname.includes('create') ? AppTabs.SCRUTIN : AppTabs.SUMMARY,
      closeDialog: false,
      scrutinTab: TabState.NOT_INITIALIZED,
      votersTab: TabState.NOT_INITIALIZED,
      candidatesTab: TabState.NOT_INITIALIZED,
      summaryTab: TabState.NOT_INITIALIZED,
      votersSubmitted: false,
      submitting: false
    };
  }

  componentDidMount() {
    appStore.on(AppStore.SYNC_Event, this.onSyncEvent);
    const mac = this.props.match.params.mac || TsModelDefs.LOCAL_MAC;
    const scrutinId = this.props.match.params.scrutinId || TsModelDefs.LOCAL_SID;
    getLogger().debug(EditorContainer.LOGTAG, "EditorContainer mounted for %s / %s", mac, scrutinId);
    appStore.acquireCurrent(mac, scrutinId);
  }

  componentWillUnmount() {
    appStore.off(AppStore.SYNC_Event, this.onSyncEvent);
  }

  private onSyncEvent = (current: SyncObject<TsCreateUpdateScrutin>) => {
    if ((current.sync === SyncStatus.LOCAL || current.sync === SyncStatus.SYNCHRONIZED) &&
      (this.state.currentId === undefined || current.id !== this.state.currentId)) {

      // Initialize states
      if (current.sync === SyncStatus.LOCAL) {
        this.setState({
          tab: AppTabs.SCRUTIN,
          scrutinTab: TabState.NEUTRAL,
          votersTab: TabState.INACCESSIBLE,
          candidatesTab: TabState.INACCESSIBLE,
          summaryTab: TabState.INACCESSIBLE,
          votersSubmitted: false,
          currentId: current.id
        });
      } else {
        const ab: TsCreateUpdateScrutin = current.getValue();
        getLogger().info(EditorContainer.LOGTAG, "InitDisplay avec le Scrutin: %o", ab);
        const editable: boolean = ab.sStatus === 'ON_CLIENT' || ab.sStatus === 'TESTING';
        if (editable) {
          const scrutinTab = Validators.scrutin(ab).ok ? TabState.GREEN : TabState.RED;
          const votersTab = Validators.voters(ab.voters).ok ? TabState.GREEN : TabState.RED;
          const candidatesTab = Validators.candidates(ab.candidates, ab.nVotes).ok ? TabState.GREEN : TabState.RED;
          const summaryTab = (scrutinTab === TabState.GREEN &&
            votersTab === TabState.GREEN &&
            candidatesTab === TabState.GREEN ? TabState.GREEN : TabState.NEUTRAL);
          this.setState({
            tab: this.props.location.pathname.includes('create') ? AppTabs.SCRUTIN : AppTabs.SUMMARY,
            scrutinTab,
            votersTab,
            candidatesTab,
            summaryTab,
            votersSubmitted: false,
            currentId: current.id
          });
        } else {
          // Not Editable -- View scrutin infos
          this.setState({
            tab: AppTabs.SUMMARY,
            scrutinTab: TabState.NEUTRAL,
            votersTab: TabState.NEUTRAL,
            candidatesTab: TabState.NEUTRAL,
            summaryTab: TabState.NEUTRAL,
            votersSubmitted: false,
            currentId: current.id
          });
        }
      }
    }
  }

  private onClose = () => {
    if (this.state.closeDialog || (!appStore.hasUnsavedChanges())) {
      // Exit the Wizard
      const mac = this.props.match.params.mac || TsModelDefs.LOCAL_MAC;
      const scrutinId = this.props.match.params.scrutinId || TsModelDefs.LOCAL_SID;
      const create: boolean = (mac === TsModelDefs.LOCAL_MAC);
      if (create) {
        getLogger().info(EditorContainer.LOGTAG, "Closing the Editor - restoring the initial state (no data).");
        appStore.setInitialState();
        this.props.history.replace('/');
      } else {
        getLogger().info(EditorContainer.LOGTAG, "Closing the Editor - restoring the server state.");
        appStore.restoreServerState();
        const url = '/admin/' + mac + '/' + scrutinId;
        this.props.history.replace(url);
      }
    } else {
      this.setState({ closeDialog: true, closeError: undefined });
    }
  }
  private onCancelClose = () => {
    this.setState({ closeDialog: false, closeError: undefined });
  }

  private onChangeTab = (tab: AppTabs): void => {
    if ((tab === AppTabs.SCRUTIN && this.state.scrutinTab !== TabState.INACCESSIBLE) ||
      (tab === AppTabs.VOTERS && this.state.votersTab !== TabState.INACCESSIBLE) ||
      (tab === AppTabs.CANDIDATES && this.state.candidatesTab !== TabState.INACCESSIBLE) ||
      (tab === AppTabs.SUMMARY && this.state.summaryTab !== TabState.INACCESSIBLE)) {
      this.setState({ tab: tab });
    }
  }

  private static summaryTabState = (scrutinTab: TabState, votersTab: TabState, candidatesTab: TabState, summaryTab: TabState): TabState => {
    if (summaryTab === TabState.INACCESSIBLE) {
      return TabState.INACCESSIBLE;
    }
    return (scrutinTab === TabState.GREEN &&
      votersTab === TabState.GREEN &&
      candidatesTab === TabState.GREEN ? TabState.GREEN : TabState.NEUTRAL);
  }

  private scrutinDirty = () => {
    this.setState(st => ({
      scrutinTab: TabState.NEUTRAL,
      summaryTab: EditorContainer.summaryTabState(TabState.NEUTRAL, st.votersTab, st.candidatesTab, st.summaryTab)
    }));
  }
  /** Sur Submission */
  private submitScrutin = (scrutin: ScrutinBean) => {
    getLogger().info(EditorContainer.LOGTAG, "Submitting Valid Scrutin: %o", scrutin);
    appStore.localSaveScrutin(scrutin);
    const votersTab = this.state.votersTab === TabState.INACCESSIBLE ? TabState.NEUTRAL : this.state.votersTab;
    this.setState(st => ({
      scrutinTab: TabState.GREEN,
      votersTab,
      summaryTab: EditorContainer.summaryTabState(TabState.GREEN, votersTab, st.candidatesTab, st.summaryTab),
      tab: AppTabs.VOTERS
    }));
  }


  private votersDirty = () => {
    this.setState(st => ({
      votersTab: TabState.NEUTRAL,
      summaryTab: EditorContainer.summaryTabState(st.scrutinTab, TabState.NEUTRAL, st.candidatesTab, st.summaryTab)
    }));
  }
  private submitVoters = (contacts: TsParsedContact[]) => {
    getLogger().info(EditorContainer.LOGTAG, "Submitting Valid Voters: %o", contacts);
    appStore.localSaveVoters(contacts);
    const candidatesTab = this.state.candidatesTab === TabState.INACCESSIBLE ? TabState.NEUTRAL : this.state.candidatesTab;
    this.setState(st => ({
      votersTab: TabState.GREEN,
      candidatesTab,
      summaryTab: EditorContainer.summaryTabState(st.scrutinTab, TabState.GREEN, candidatesTab, st.summaryTab),
      tab: AppTabs.CANDIDATES,
      votersSubmitted: true
    }));
  }

  private candidatesDirty = () => {
    this.setState(st => ({
      candidatesTab: TabState.NEUTRAL,
      summaryTab: EditorContainer.summaryTabState(st.scrutinTab, st.votersTab, TabState.NEUTRAL, st.summaryTab)
    }));
  }
  private submitCandidates = (candidates: CandidatesBean) => {
    getLogger().info(EditorContainer.LOGTAG, "Submitting Valid Candidates: %o", candidates);
    appStore.localSaveCandidates(candidates);
    const summaryTab = this.state.summaryTab === TabState.INACCESSIBLE ? TabState.NEUTRAL : this.state.summaryTab;
    this.setState(st => ({
      candidatesTab: TabState.GREEN,
      summaryTab: EditorContainer.summaryTabState(st.scrutinTab, st.votersTab, TabState.GREEN, summaryTab),
      tab: AppTabs.SUMMARY
    }));
  }

  private submitSummary = () => {
    getLogger().info(EditorContainer.LOGTAG, "Saving scrutin on Server.");
    this.setState({ submitting: true });
    appStore.saveOnServer((ok, errCode) => {
      this.setState({ submitting: false });
      if (ok) {
        const mac = appStore.getCurrent().getValue().mac;
        const scrutinId = appStore.getCurrent().getValue().scrutinId;
        this.props.history.push('/admin/' + mac + '/' + scrutinId);
      } else {
        const errCodeStr = ErrCodeString(errCode as ErrCodes);
        getLogger().error(EditorContainer.LOGTAG, "Error Saving Scrutin on Server %s.", errCodeStr);
        this.setState({ closeDialog: true, closeError: errCode as ErrCodes });
      }
    })
  }

  public render() {
    const current: SyncObject<TsCreateUpdateScrutin> = appStore.getCurrent();

    if (!current.hasValue()) {
      return <UnSync sync={current} />;
    }
    const appBean: TsCreateUpdateScrutin = current.getValue();

    const create: boolean = (appBean.mac === TsModelDefs.LOCAL_MAC);
    const editable: boolean = appBean.sStatus === 'ON_CLIENT' || appBean.sStatus === 'TESTING';

    const title = editable ? (create ? i18next.t('editor.crea_title') : i18next.t('editor.edit_title')) :
      appBean.sDisplayName;
    const cancel_text = create ? i18next.t('editor.cancel_create') : i18next.t('editor.cancel_edit');
    const tabStates = [this.state.scrutinTab, this.state.votersTab, this.state.candidatesTab, this.state.summaryTab];
    const allOk = (this.state.summaryTab === TabState.GREEN);
    // Only applicable if closeDialog:
    const closeTitle = (this.state.closeError ? i18next.t('editor.error') : i18next.t('editor.cancel_title'));
    const closeText = (this.state.closeError ? errMessage(this.state.closeError) : cancel_text);

    return (
      <Modal show={true} onHide={this.onClose} size='lg'>
        <Modal.Header closeButton className='ed-header'>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.closeDialog ?
            <Alert variant="danger" onClose={this.onCancelClose} dismissible>
              <Alert.Heading>{closeTitle}</Alert.Heading>
              <p>{closeText}</p>
              <div className='ed-controls'>
                <Button variant='secondary' className='ed-button' onClick={this.onCancelClose}>{i18next.t('editor.cancel_btn_no')}</Button>&nbsp;&nbsp;
                <Button variant='danger' className='ed-button' onClick={this.onClose}>{i18next.t('editor.cancel_btn_yes')}</Button>
              </div>
            </Alert>
            :
            <>
              <EditorNav tab={this.state.tab} tabStates={tabStates} onChange={this.onChangeTab} />
              <ScrutinView scrutin={appBean} dirty={this.scrutinDirty} submit={this.submitScrutin}
                visible={this.state.tab === AppTabs.SCRUTIN} editable={editable} />
              <VotersView voters={appBean.voters} dirty={this.votersDirty} submit={this.submitVoters}
                visible={this.state.tab === AppTabs.VOTERS} editable={editable} />
              <CandidatesView candidates={appBean} dirty={this.candidatesDirty} submit={this.submitCandidates}
                visible={this.state.tab === AppTabs.CANDIDATES} editable={editable} />
              <SummaryView appBean={appBean} allOk={allOk} submit={this.submitSummary}
                visible={this.state.tab === AppTabs.SUMMARY} editable={editable}
                submitting={this.state.submitting} />
            </>
          }
        </Modal.Body>
      </Modal>
    );
  }
}