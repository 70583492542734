/**
 * Contenu de AdminView en mode TESTING.
 */
import i18next from 'i18next';
import * as React from 'react';
import { Button, Alert } from 'react-bootstrap';
import { DateTime } from 'luxon';
import { DatesManager } from '../common/util/DatesManager';
import { getLogger } from '../common/util/pmlogger';
import { AppConfig } from '../app/AppConfig';

interface TVProps {
  sDisplayName: string,
  voteUrl: string,
  resultsUrl: string,
  openDateMillis: number,
  closeDateMillis: number,
  scrutinId: string,   // Pour acquisition Résultats
  lang: string,        // unused but dunno how to force update when it changes
  modify: () => void,
  validate: () => void
}

export class TestView extends React.PureComponent<TVProps> {

  private static LOGTAG = "testview";

  private onRefresh = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
  }

  private modify = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    this.props.modify();
  }

  private validate = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    this.props.validate();
  }

  public render() {
    const props = this.props;
    getLogger().info(TestView.LOGTAG, "Rendering TestView for scrutin: %s", this.props.sDisplayName);
    // On pourra revenir à TESTING après READY seulement jusqu'à 5 mn avant le début
    const irreversible = (new Date().getTime() > props.openDateMillis - AppConfig.MIN_TESTING_TO_OPEN_MS);
    const canGoReady = (new Date().getTime() <= props.closeDateMillis - AppConfig.MAX_READY_TO_CLOSE_MS);
    const lastModif = DatesManager.get().formatShortNumericDateTime(DateTime.fromMillis(props.openDateMillis - AppConfig.MIN_TESTING_TO_OPEN_MS))
    const start = (props.openDateMillis < new Date().getTime() ? i18next.t('testview.maintenant') : DatesManager.get().formatShortNumericDateTime(DateTime.fromMillis(props.openDateMillis)));
    const end = DatesManager.get().formatShortNumericDateTime(DateTime.fromMillis(props.closeDateMillis));
    // const realVoteLink = voteUrl(props.scrutinId);
    // const realResultsLink = resultsUrl(props.scrutinId);
    return (
      <div className='container-fluid'>
        <div className='container'>
          <div className='div-section'>
            <h2><span className='strong-value'>{props.sDisplayName}</span></h2>
          </div>
          <Alert variant='primary'>
            <p style={{ fontWeight: 'bold' }}>{i18next.t('testview.test_1')}</p>
            <ul style={{ marginBottom: '0' }}>
              <li>{i18next.t('testview.test_2')}</li>
              <li>{i18next.t('testview.test_3')}<br />
                {i18next.t('testview.test_4')}
              </li>
              <li>{i18next.t('testview.test_5')}<br />
                {i18next.t('testview.test_6')}</li>
            </ul>
            <div className='test-modify'>
              <Button variant='primary' type='button' className='ed-button' onClick={this.modify}>
                {i18next.t('testview.test_update')}
              </Button>
            </div>
          </Alert>
          <div className='div-section'>
            <div className='div-subsection'>
              {i18next.t('testview.testvote_text')}:&nbsp;
              <a href={props.voteUrl} target='_blank' rel='noopener noreferrer'>{i18next.t('testview.testvote_link')}</a>
            </div>
          </div>
          <div className='div-section'>
            <div className='div-subsection'>
              {i18next.t('testview.testresults_text')}:&nbsp;
              <a href={props.resultsUrl} target='_blank' rel='noopener noreferrer'>{i18next.t('testview.testresults_link')}</a>
            </div>
          </div>

          <div className='div-section'>
            <div className='div-subsection'>
              <div style={{ fontWeight: 'bold' }}>{i18next.t('testview.validate_info1')}</div>
            </div>
            <div className='ed-controls'>
              <Button variant='primary' size='lg' type='submit' onClick={this.validate} disabled={!canGoReady} className='ed-button'>
                {i18next.t('testview.validate')}
              </Button>
            </div>
            {canGoReady ?
              <div className='smaller'>
                {i18next.t('testview.willhavemail')}
              </div> :
              <div className='ed-error'>{i18next.t('testview.validate_err_enddate')}</div>
            }
            <div className='div-subsection smaller'>
              {irreversible ?
                <div>{i18next.t('testview.validate_info2_irreversible')}</div>
                :
                <div>{i18next.t('testview.validate_info2', { lastmodif: lastModif })}</div>
              }
              <div>{i18next.t('testview.validate_info3')}</div>
              {irreversible ?
                <div>{i18next.t('testview.validate_info4_irreversible', { max: end })}</div>
                :
                <div>{i18next.t('testview.validate_info4', { min: start, max: end })}</div>
              }
              <div>{i18next.t('testview.validate_info5')}</div>
            </div>
          </div>
        </div>
      </div >
    );
  }

}