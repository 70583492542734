import * as superagent from 'superagent';
import { getLogger } from '../util/pmlogger';
import { ErrCodes, ErrCodeString } from './ErrCodes';
import { HttpLayer } from './HttpLayer';

/**
 * This module provides low-Level methods for communicating with the Server.
 * Two variants are proposed: With a Promise and with Callback functions.
 * It features aborting of previously unfinished requests when a new request is
 * requested, and considers that the Server will respond with the same data structure
 * on the same URL, be it invoked with GET or POST
 * 
 * @author Philippe
 */


// Key: Request URL; Value: the SuperAgent Request.
// interface PendingRequestsType { [reqUrl: string]: superagent.SuperAgentRequest };

export class HttpLayerImpl implements HttpLayer {
  private static LOGGERTAG = 'httplayer';

  /** Such as http://localhost:5395 */
  private API_URL;

  private readonly TIMEOUT = {
    response: 60000,
    deadline: 120000
  };

  // private pendingRequests: PendingRequestsType = {};

  /** Such as http://localhost:5395 */
  public constructor(apiUrl: string) {
    this.API_URL = apiUrl;
  }

  public setHttpApiUrl(apiUrl: string): void {
    this.API_URL = apiUrl;
  }
  public getHttpApiUrl(): string {
    return this.API_URL;
  }
  public setHttpTimeouts(response: number, deadline: number): void {
    this.TIMEOUT.response = response;
    this.TIMEOUT.deadline = deadline;
  }
  public getHttpTimeouts() {
    return this.TIMEOUT;
  }

  public HttpGetPromise<T>(url: string): Promise<T> {
    const promise = new Promise<T>(
      (resolve: (value: T) => void, reject: (errCode: ErrCodes) => void) => {
        // const oldRequest = this.pendingRequests[url];
        // if (oldRequest !== undefined && oldRequest.method === 'POST') {
        //   getLogger().debug(HttpLayerImpl.LOGGERTAG, "GET request not executed, POST in progress: %s", url);
        //   reject(ErrCodes.HTTP_IGNORE);
        //   return;
        // }
        // if (oldRequest !== undefined && oldRequest.method === 'GET') {
        //   getLogger().debug(HttpLayerImpl.LOGGERTAG, "GET request interrupts previous one: %s", url);
        //   oldRequest.abort();
        // }

        const req = superagent.get(this.API_URL + url)
          .set('Accept', 'application/json')
          .timeout(this.TIMEOUT);
        // this.pendingRequests[url] = req;
        req.end(this.makeCallbackHandler(url, resolve, reject));
      }
    );
    return promise;
  }

  public HttpPostPromise<B extends object, T>(url: string, body: B): Promise<T> {
    const promise = new Promise<T>(
      (resolve: (value: T) => void, reject: (errCode: ErrCodes) => void) => {
        // const oldRequest = this.pendingRequests[url];
        // if (oldRequest !== undefined && oldRequest.method === 'POST') {
        //   getLogger().debug(HttpLayerImpl.LOGGERTAG, "Consecutive POST requests, all will be executed: %s", url);
        // } else if (oldRequest !== undefined && oldRequest.method === 'GET') {
        //   getLogger().debug(HttpLayerImpl.LOGGERTAG, "POST request interrupts previous GET: %s", url);
        //   oldRequest.abort();
        // }
        const req = superagent.post(this.API_URL + url)
          .set('Accept', 'application/json')
          .set('Content-Type', 'application/json')
          .timeout(this.TIMEOUT)
          .send(body);
        // this.pendingRequests[url] = req;
        req.end(this.makeCallbackHandler(url, resolve, reject));
      }
    );
    return promise;
  }

  public HttpGet<T>(url: string, onData: (data: T) => void, onError?: (err: ErrCodes) => void, onComplete?: () => void): void {
    // const oldRequest = this.pendingRequests[url];
    // if (oldRequest !== undefined && oldRequest.method === 'POST') {
    //   getLogger().debug(HttpLayerImpl.LOGGERTAG, "GET request not executed, POST in progress: %s", url);
    //   if (onError) { onError(ErrCodes.HTTP_IGNORE); }
    //   if (onComplete) {
    //     onComplete();
    //   }
    //   return;
    // }
    // if (oldRequest !== undefined && oldRequest.method === 'GET') {
    //   getLogger().debug(HttpLayerImpl.LOGGERTAG, "GET request interrupts previous one: %s", url);
    //   oldRequest.abort();
    // }
    const req = superagent.get(this.API_URL + url)
      .set('Accept', 'application/json')
      .timeout(this.TIMEOUT);
    // this.pendingRequests[url] = req;
    req.then(resp => {
      // delete (this.pendingRequests[url]);
      const errCode = this.mapResponseErrors(resp);
      if (errCode === ErrCodes.OK) {
        onData(resp.body.data as T);
      } else {
        getLogger().warn(HttpLayerImpl.LOGGERTAG, "GET to %s failed: %i [%s]", url, errCode, ErrCodeString(errCode));
        if (onError) {
          onError(errCode);
        }
      }
      if (onComplete) {
        onComplete();
      }
    }, err => {
      // delete (this.pendingRequests[url]);
      const errCode = this.mapErrcode(err);
      getLogger().warn(HttpLayerImpl.LOGGERTAG, "GET to %s failed: %i [%s] - ", url, errCode, ErrCodeString(errCode), err);
      if (onError) {
        onError(errCode);
      }
      if (onComplete) {
        onComplete();
      }
    });
  }

  public HttpPost<B extends object, T>(url: string, body: B, onData: (data: T) => void, onError?: (err: ErrCodes) => void, onComplete?: () => void): void {
    // const oldRequest = this.pendingRequests[url];
    // if (oldRequest !== undefined && oldRequest.method === 'GET') {
    //   getLogger().debug(HttpLayerImpl.LOGGERTAG, "POST request interrupts previous GET: %s", url);
    //  oldRequest.abort();
    //}
    const req = superagent.post(this.API_URL + url)
      .set('Accept', 'application/json')
      .timeout(this.TIMEOUT).send(body);
    // this.pendingRequests[url] = req;
    req.then(resp => {
      // delete (this.pendingRequests[url]);
      const errCode = this.mapResponseErrors(resp);
      if (errCode === ErrCodes.OK) {
        onData(resp.body.data as T);
      } else {
        getLogger().warn(HttpLayerImpl.LOGGERTAG, "POST to %s failed: %i [%s]", url, errCode, ErrCodeString(errCode));
        if (onError) {
          onError(errCode);
        }
      }
      if (onComplete) {
        onComplete();
      }
    }, err => {
      // delete (this.pendingRequests[url]);
      const errCode = this.mapErrcode(err);
      getLogger().warn(HttpLayerImpl.LOGGERTAG, "POST to %s failed: %i [%s] - ", url, errCode, ErrCodeString(errCode), err);
      if (onError) {
        onError(errCode);
      }
      if (onComplete) {
        onComplete();
      }
    });
  }


  /**
   * Qualifies errors returned by SuperAgent as ErrCodes.
   * @param err the 'err' returned by SuperAgent in case of network failures, Timeouts or errors without Response
   */
  private mapErrcode(err: any): ErrCodes {
    if (err === undefined || err === null) {
      return ErrCodes.HTTP_UNKNOWN;
    }
    // https://visionmedia.github.io/superagent/#timeouts
    // https://visionmedia.github.io/superagent/#error-handling
    if (err.timeout === this.TIMEOUT || err.code === 'ABORTED') {
      return ErrCodes.HTTP_TIMEOUT;
    } else if (err.status === 401 || err.status === 403) {
      return ErrCodes.HTTP_UNAUTHORIZED;
    } else if (Math.floor(err.status / 100) === 4) {
      return ErrCodes.HTTP_CLIENT_ERROR;
    } else if (Math.floor(err.status / 100) === 5) {
      return ErrCodes.HTTP_SERVER_ERROR;
    }
    return ErrCodes.HTTP_UNKNOWN;
  }

  /**
   * The Response given by Superagent may indicate an error condition, notably when the server replies with a 4xx / 5xx code.
   * This method also assumes that the server returns a body with a 'ret' number indicating a 'business-level' error.
   * @param resp the superagent.Response object
   * @returns null if no Error is indicated, the Error Code otherwise.
   */
  private mapResponseErrors(resp: superagent.Response): ErrCodes {
    getLogger().warn(HttpLayerImpl.LOGGERTAG, "ApiResponseHandler recvd: %o", resp ? resp.body : null);
    if (resp === undefined || resp === null) {
      return ErrCodes.HTTP_UNKNOWN;
    }
    // https://visionmedia.github.io/superagent/#timeouts
    // https://visionmedia.github.io/superagent/#error-handling
    if (resp.status === 401 || resp.status === 403) {
      return ErrCodes.HTTP_UNAUTHORIZED;
    } else if (resp.statusType === 4) {
      return ErrCodes.HTTP_CLIENT_ERROR;
    } else if (resp.statusType === 5) {
      return ErrCodes.HTTP_SERVER_ERROR;
    } else if (!resp.ok) {
      return (-resp.statusType);
    } else if (resp.body === undefined || resp.body.ret === undefined) {
      return ErrCodes.HTTP_MALFORMED;
    } else if (resp.body.ret !== 0) {
      // Business error
      return resp.body.ret;
    }
    return ErrCodes.OK;
  }

  private makeCallbackHandler<T>(url: string, resolve: (arg: T) => void, reject: (errCode: ErrCodes) => void) {
    // This is the Callback given to superagent
    return (err: any, res: superagent.Response) => {
      // delete (this.pendingRequests[url]);
      if (err) {
        const errCode = this.mapErrcode(err);
        getLogger().warn(HttpLayerImpl.LOGGERTAG, "OP. to %s failed: %i [%s] - ", url, errCode, ErrCodeString(errCode), err);
        reject(errCode);
      }
      const errCode = this.mapResponseErrors(res);
      if (errCode === ErrCodes.OK) {
        resolve(res.body as T);
      } else {
        getLogger().warn(HttpLayerImpl.LOGGERTAG, "OP. to %s failed: %i [%s]", url, errCode, ErrCodeString(errCode));
        reject(errCode);
      }
    };
  };
}


