
import React from "react";
import i18next from "i18next";
import { CheckIcon, XIcon, ChevronRightIcon } from "@primer/octicons-react";
import './editornav.css';
import { AppTabs, TabState } from "./EditorContainer";

/** Navigation tabs */

/** tab: Currently selected Tab; thisTab: référence ce TabTitle */
function TabTitle(props: { tab: AppTabs, tabStates: TabState[], thisTab: AppTabs, onChange: (tab: AppTabs) => void }) {
  const name = i18next.t('editor.tabname_' + props.thisTab);
  const thisTState = props.tabStates[props.thisTab];
  const neutral = thisTState === TabState.INACCESSIBLE || thisTState === TabState.NEUTRAL;
  const octicon = (neutral ? undefined : (thisTState === TabState.GREEN ? <CheckIcon /> : <XIcon />));
  let octclazz = (neutral ? 'ed-neutral' : (thisTState === TabState.GREEN ? 'ed-green' : 'ed-red'));
  octclazz = 'btn ed-nav-tab ' + octclazz;
  if (props.tab === props.thisTab) {
    octclazz += ' ed-control-actif';
  }
  const cb = props.onChange.bind(null, props.thisTab);
  const dis = thisTState === TabState.INACCESSIBLE;
  return <button className={octclazz} onClick={cb} disabled={dis ? true : false}> {octicon} {name}</button >
}

interface ENProps {
  tab: AppTabs,
  tabStates: TabState[],  // index = AppTab, Value = TabState
  onChange: (tab: AppTabs) => void
}
export class EditorNav extends React.Component<ENProps> {

  public render() {
    return (
      <div className='ed-nav-div'>
        <TabTitle thisTab={AppTabs.SCRUTIN} tab={this.props.tab} tabStates={this.props.tabStates} onChange={this.props.onChange} />
        <div className='ed-nav-chevron'><ChevronRightIcon /></div>
        <TabTitle thisTab={AppTabs.VOTERS} tab={this.props.tab} tabStates={this.props.tabStates} onChange={this.props.onChange} />
        <div className='ed-nav-chevron'><ChevronRightIcon /></div>
        <TabTitle thisTab={AppTabs.CANDIDATES} tab={this.props.tab} tabStates={this.props.tabStates} onChange={this.props.onChange} />
        <div className='ed-nav-chevron'><ChevronRightIcon /></div>
        <TabTitle thisTab={AppTabs.SUMMARY} tab={this.props.tab} tabStates={this.props.tabStates} onChange={this.props.onChange} />
      </div>
    );
  }
}
