import { EventEmitter } from "events";
import { getLogger } from "../common/util/pmlogger";
import { SyncObject, SyncStatus } from "../common/backend/SyncObject";
import { VooApi } from "../backend/VooApi";
import { CandidatesBean, hasChanged, ScrutinBean, TsCreateUpdateScrutin, updateCusWithResponse } from "../backend/tsmodel/TsCreateUpdateScrutin";
import { TsModelDefs } from "../common/tsmodel/TsModelDefs";
import { TsScrutinDataResponse, TsScrutinViewResponse } from "../common/tsmodel/TsResponses";
import { TsParsedContact } from "../common/tsmodel/TsParsedContact";
import { ErrCodes } from "../common/backend/ErrCodes";
import i18next from "i18next";

/**
 * LE STORE stocke seulement les infos PRIMAIRES - PAS les infos dérivées si un state d'édition
 */
export class AppStore extends EventEmitter {

  private static LOGTAG = "appstore";

  /** Changements d'état de synchronisation. */
  public static SYNC_Event = "SYNC.Event";

  private vooApi = new VooApi();

  /** Conteneur de l'objet d'état (Scrutin en cours). NOT_INITIALIZED. id= mac/sid */
  private current: SyncObject<TsCreateUpdateScrutin> = new SyncObject<TsCreateUpdateScrutin>();

  /** Dernier état Serveur. */
  private serverState?: TsScrutinDataResponse;

  /** 
   * Déclenche le chargement si nécessaire. Si changement de MAC ou ScrutinId, on passera toujours par SYNCHRONIZING,
   * et aura toujours un second SYNC_Event.
   * Si forceReacquisition est présent et true, on va toujours réacquérir -- utile pour Clearer les erreurs.
   * <p/>
   * Si aucune réacquisition, et hors chargement en cours, déclenche un SYNC_Event sur Timeout 0.
   */
  public acquireCurrent = (mac: string, scrutinId: string, forceReacquisition?: boolean): void => {
    if (this.current.sync === SyncStatus.SYNCHRONIZING) {
      // Already in progress.
      getLogger().info(AppStore.LOGTAG, "Bean update already in progress for: %s / %s", mac, scrutinId);
      return;
    }
    if (forceReacquisition || this.current.sync === SyncStatus.NOT_INITIALIZED || mac + '/' + scrutinId !== this.current.id) {
      // Reload needed.
      this.current.makeSynchronizing(mac + '/' + scrutinId);
      this.emit(AppStore.SYNC_Event, this.current);
      if (mac === TsModelDefs.LOCAL_MAC || scrutinId === TsModelDefs.LOCAL_SID) {
        setTimeout(this.loadLocal.bind(this), 0);
      } else {
        setTimeout(this.loadFromServer.bind(this, mac, scrutinId), 0);
      }
      getLogger().info(AppStore.LOGTAG, "Triggered Scrutin update for : %s / %s", mac, scrutinId);
    }
    setTimeout(this.emit.bind(this, AppStore.SYNC_Event, this.current), 0);
  }

  public getCurrent = (): SyncObject<TsCreateUpdateScrutin> => (this.current);

  private loadLocal = () => {
    this.setInitialState();
    this.emit(AppStore.SYNC_Event, this.current);
  }
  public setInitialState = () => {
    this.current.makeLocal(TsModelDefs.LOCAL_MAC + '/' + TsModelDefs.LOCAL_SID, new TsCreateUpdateScrutin());
    this.serverState = undefined;
  }
  private loadFromServer = (mac: string, scrutinId: string) => {
    this.vooApi.getScrutin(mac, scrutinId).then(
      scrutinResp => {
        getLogger().info(AppStore.LOGTAG, "Loaded from Server: %o", scrutinResp);
        this.serverState = scrutinResp;
        const scrutin = updateCusWithResponse(scrutinResp);
        this.current.makeSynchronized(mac + '/' + scrutinId, scrutin);
        this.emit(AppStore.SYNC_Event, this.current);
      }
    ).catch(err => {
      getLogger().warn(AppStore.LOGTAG, "Error Loading from Server: %o", err);
      this.current.makeError(err);
      this.emit(AppStore.SYNC_Event, this.current);
    });
  }

  /** Updates the local Bean with Scrutin info. No server interaction. No Event. */
  public localSaveScrutin = (scrutin: ScrutinBean) => {
    const { sDisplayName, sOrganizerEmail, sOrganizerName, openDateMillis, closeDateMillis, closeOnAllVoted } = scrutin;
    if (this.current.sync === SyncStatus.LOCAL || this.current.sync === SyncStatus.SYNCHRONIZED) {
      const app = this.current.getValue();
      app.sDisplayName = sDisplayName;
      app.sOrganizerEmail = sOrganizerEmail;
      app.sOrganizerName = sOrganizerName;
      app.openDateMillis = openDateMillis;
      app.closeDateMillis = closeDateMillis;
      app.closeOnAllVoted = closeOnAllVoted;
    } else {
      getLogger().error(AppStore.LOGTAG, "Cannot localSaveScrutin, bad state: %o", this.current.sync);
    }
  }

  /** Updates the local Bean with the Voters list. No server interaction. No Event. */
  public localSaveVoters = (contacts: TsParsedContact[]) => {
    if (this.current.sync === SyncStatus.LOCAL || this.current.sync === SyncStatus.SYNCHRONIZED) {
      const app = this.current.getValue();
      app.voters = contacts;
    } else {
      getLogger().error(AppStore.LOGTAG, "Cannot localSaveVoters, bad state: %o", this.current.sync);
    }
  }

  /** Updates the local Bean with the Candidates list. No server interaction. No Event. */
  public localSaveCandidates = (candidates: CandidatesBean) => {
    if (this.current.sync === SyncStatus.LOCAL || this.current.sync === SyncStatus.SYNCHRONIZED) {
      const app = this.current.getValue();
      app.candidates = candidates.candidates;
      app.nVotes = candidates.nVotes;
    } else {
      getLogger().error(AppStore.LOGTAG, "Cannot localSaveCandidates, bad state: %o", this.current.sync);
    }
  }

  /** Déclenche l'envoi au serveur. */
  public saveOnServer = (done: (ok: boolean, err?: ErrCodes) => void) => {
    if (this.current.sync === SyncStatus.SYNC_ERROR && this.current.forceGetValue()) {
      getLogger().error(AppStore.LOGTAG, "Forcing Error clear since the Scrutin is in this SyncState.ERROR");
      const curVal = this.current.forceGetValue() as TsCreateUpdateScrutin;
      this.current.forceToSyncStatus(curVal.mac === TsModelDefs.LOCAL_MAC ? SyncStatus.LOCAL : SyncStatus.SYNCHRONIZED);
    }
    const prevStatus = this.current.sync;
    if (this.current.sync === SyncStatus.LOCAL || this.current.sync === SyncStatus.SYNCHRONIZED) {
      const scrutin = this.current.getValue();
      // Update automatically computed attributes
      scrutin.supportedLang = i18next.language.toLowerCase().startsWith("fr") ? 'FR' : 'EN';
      scrutin.orgaTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      this.current.makeSynchronizing();
      this.emit(AppStore.SYNC_Event, this.current);
      const p: Promise<TsScrutinDataResponse> = (scrutin.mac === TsModelDefs.LOCAL_MAC ? this.vooApi.createScrutin(scrutin) : this.vooApi.updateScrutin(scrutin));
      p.then((scrutinResp) => {
        getLogger().info(AppStore.LOGTAG, "Successfully Saved on Server, mac: %s, sid:%i", scrutinResp.mac, scrutinResp.scrutinId);
        this.serverState = scrutinResp;
        const updatedScrutin = updateCusWithResponse(scrutinResp, scrutin);
        this.current.makeSynchronized(scrutin.mac + '/' + scrutin.scrutinId, updatedScrutin);
        this.emit(AppStore.SYNC_Event, this.current);
        done(true);
      }).catch(err => {
        getLogger().warn(AppStore.LOGTAG, "Error saving on Server: %o", err);
        // Restore the previous status to avoid erasing Editor dialog contents
        this.current.restoreSync(prevStatus);
        this.emit(AppStore.SYNC_Event, this.current);
        done(false, err);
      });
    } else {
      getLogger().error(AppStore.LOGTAG, "Cannot save a Scrutin in this SyncState: %o", this.current.sync);
      done(false, ErrCodes.UNKNOWN);
    }
  }

  /** Déclenche la transition TESTING => READY */
  public goReady = () => {
    if (this.current.sync === SyncStatus.SYNCHRONIZED && this.current.getValue().sStatus === 'TESTING') {
      const scrutin = this.current.getValue();
      const mac = scrutin.mac;
      const scrutinId = scrutin.scrutinId;
      this.current.makeSynchronizing();
      this.emit(AppStore.SYNC_Event, this.current);
      const p: Promise<TsScrutinDataResponse> = this.vooApi.goReady(mac, scrutinId);
      p.then((scrutinResp) => {
        getLogger().info(AppStore.LOGTAG, "Successfully goReady on Server, mac: %s, sid:%i", mac, scrutinId);
        this.serverState = scrutinResp;
        const updatedScrutin = updateCusWithResponse(scrutinResp, scrutin);
        this.current.makeSynchronized(scrutin.mac + '/' + scrutin.scrutinId, updatedScrutin);
        this.emit(AppStore.SYNC_Event, this.current);
      }).catch(err => {
        getLogger().warn(AppStore.LOGTAG, "Error goReady on Server: %o", err);
        this.current.makeError(err);
        this.emit(AppStore.SYNC_Event, this.current);
      });
    } else {
      getLogger().error(AppStore.LOGTAG, "Cannot goReady in this SyncState: %o or Status.", this.current.sync);
    }
  }

  /** Déclenche la transition READY => TESTING */
  public backToTest = () => {
    if (this.current.sync === SyncStatus.SYNCHRONIZED && this.current.getValue().sStatus === 'READY') {
      const scrutin = this.current.getValue();
      const mac = scrutin.mac;
      const scrutinId = scrutin.scrutinId;
      this.current.makeSynchronizing();
      this.emit(AppStore.SYNC_Event, this.current);
      const p: Promise<TsScrutinDataResponse> = this.vooApi.backTesting(mac, scrutinId);
      p.then((scrutinResp) => {
        getLogger().info(AppStore.LOGTAG, "Successfully backTesting on Server, mac: %s, sid:%i", mac, scrutinId);
        this.serverState = scrutinResp;
        const updatedScrutin = updateCusWithResponse(scrutinResp, scrutin);
        this.current.makeSynchronized(scrutin.mac + '/' + scrutin.scrutinId, updatedScrutin);
        this.emit(AppStore.SYNC_Event, this.current);
      }).catch(err => {
        getLogger().warn(AppStore.LOGTAG, "Error backTesting on Server: %o", err);
        this.current.makeError(err);
        this.emit(AppStore.SYNC_Event, this.current);
      });
    } else {
      getLogger().error(AppStore.LOGTAG, "Cannot backTesting in this SyncState: %o or Status.", this.current.sync);
    }
  }

  public getStatus = (): Promise<TsScrutinViewResponse> | SyncStatus => {
    if (this.current.sync === SyncStatus.SYNCHRONIZED) {
      const mac = this.current.getValue().mac;
      const scrutinId = this.current.getValue().scrutinId;
      return this.vooApi.viewScrutin(scrutinId, mac);
    } else {
      getLogger().warn(AppStore.LOGTAG, "Cannot getStatus in this SyncState: %o or Status.", this.current.sync);
      return this.current.sync;
    }
  }

  /** Returns true if the local value differs from the Server one. */
  public hasUnsavedChanges = (): boolean => {
    if (!this.current.hasValue) {
      return false;   // No comparison possible 
    }
    return hasChanged(this.current.getValue(), this.serverState);
  }

  /** No Event. */
  public restoreServerState = () => {
    if (!this.serverState) {
      getLogger().warn(AppStore.LOGTAG, "Cannot restore Server State: no data - Starting anew.");
      this.setInitialState();
    } else {
      const id = this.serverState.mac + '/' + this.serverState.scrutinId;
      this.current.makeSynchronized(id, updateCusWithResponse(this.serverState));
    }
  }

  // public -- restoreServerState ou getServerState ? Selon états SyncStatus ?
}

export const appStore = new AppStore();
