
export enum LogLevel { ALL, DEBUG, INFO, WARN, ERROR, NONE };

/**
 * Exposes a Logging facade, whose implementation can change without affecting the code.
 * The default Logger outputs to Console, with an Info level.
 * Typically, this Logger is used with: import {getLogger} from '../util/pmlogger'
 * An implementation must be set for real logging to occur, e.g.: setLogger(new PmConsoleLog());
 */
export interface PmLogger {
  /**
   * If the DEBUG level is enabled for one of the given tags, logs the message.
   * The msg field can contain %s = string, %i = integer, %o = object, %f = float
   * The args are substituted in this string (args are only used if msg is a string or function)
   */
  debug(tag: string | string[], msg: string | (() => string) | Error, ...args: any[]): void;
  /**
   * If the INFO level is enabled for one of the given tags, logs the message.
   * The msg field can contain %s = string, %i = integer, %o = object, %f = float
   * The args are substituted in this string (args are only used if msg is a string or function)
   */
  info(tag: string | string[], msg: string | (() => string) | Error, ...args: any[]): void;
  /**
   * If the WARN level is enabled for one of the given tags, logs the message.
   * The msg field can contain %s = string, %i = integer, %o = object, %f = float
   * The args are substituted in this string (args are only used if msg is a string or function)
   */
  warn(tag: string | string[], msg: string | (() => string) | Error, ...args: any[]): void;
  /**
   * If the ERROR level is enabled for one of the given tags, logs the message.
   * The msg field can contain %s = string, %i = integer, %o = object, %f = float
   * The args are substituted in this string (args are only used if msg is a string or function)
   */
  error(tag: string | string[], msg: string | (() => string) | Error, ...args: any[]): void;

  isDebugEnabled(tag?: string): boolean;
  isInfoEnabled(tag?: string): boolean;
  isWarnEnabled(tag?: string): boolean;
  isErrorEnabled(tag?: string): boolean;
}

class NoopLogger implements PmLogger {
  public debug(tag: string | string[], msg: string | (() => string) | Error, ...args: any[]): void { // noop
  }
  public info(tag: string | string[], msg: string | Error | (() => string), ...args: any[]): void { // noop
  }
  public warn(tag: string | string[], msg: string | Error | (() => string), ...args: any[]): void { // noop
  }
  public error(tag: string | string[], msg: string | Error | (() => string), ...args: any[]): void { // noop
  }
  public isDebugEnabled(tag?: string | undefined): boolean {
    return false;
  }
  public isInfoEnabled(tag?: string | undefined): boolean {
    return false;
  }
  public isWarnEnabled(tag?: string | undefined): boolean {
    return false;
  }
  public isErrorEnabled(tag?: string | undefined): boolean {
    return false;
  }
};

/** Only one entry of this Array is used, and the Default is NoopLogger. */
const currentLoggerHolder: PmLogger[] = new Array<PmLogger>();
currentLoggerHolder[0] = new NoopLogger();

export const getLogger = () => {
  return currentLoggerHolder[0];
}

export const setLogger = (logger: PmLogger) => {
  currentLoggerHolder[0] = logger;
}
