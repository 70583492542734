import { ErrCodes } from "../backend/ErrCodes";
import i18next from "i18next";
import { TsMessageStatus } from "./TsModelDefs";


export const errMessage = (errCode: ErrCodes): string => {
  switch (errCode) {
    case ErrCodes.HTTP_UNKNOWN: return i18next.t('global.errcodes.unknown');
    case ErrCodes.HTTP_TIMEOUT: return i18next.t('global.errcodes.timeout');
    case ErrCodes.HTTP_UNAUTHORIZED: return i18next.t('global.errcodes.unauthorized');
    case ErrCodes.HTTP_MALFORMED: return i18next.t('global.errcodes.malformed');
    case ErrCodes.HTTP_INFO: return i18next.t('global.errcodes.info');
    case ErrCodes.HTTP_CLIENT_ERROR: return i18next.t('global.errcodes.client-error');
    case ErrCodes.HTTP_SERVER_ERROR: return i18next.t('global.errcodes.svr-error');
    case ErrCodes.OK: return i18next.t('global.errcodes.OK');
    case ErrCodes.UNKNOWN: return i18next.t('global.errcodes.UNKNOWN');
    case ErrCodes.MISSING_FIELD: return i18next.t('global.errcodes.MISSING_FIELD');
    case ErrCodes.CLOSEDATE_TOO_FAR: return i18next.t('global.errcodes.CLOSEDATE_TOO_FAR');
    case ErrCodes.DBFAIL: return i18next.t('global.errcodes.DBFAIL');
    case ErrCodes.ERR_INVALID_INPUT: return i18next.t('global.errcodes.ERR_INVALID_INPUT');
    case ErrCodes.CANT_ALLOCATE_ID: return i18next.t('global.errcodes.CANT_ALLOCATE_ID');
    case ErrCodes.SCRUTIN_NOT_FOUND: return i18next.t('global.errcodes.SCRUTIN_NOT_FOUND');
    case ErrCodes.ERR_INVALID_STATES: return i18next.t('global.errcodes.ERR_INVALID_STATES');
    case ErrCodes.CANT_MODIFY_READY: return i18next.t('global.errcodes.CANT_MODIFY_READY');
    case ErrCodes.CANT_MODIFY_ACTIVE: return i18next.t('global.errcodes.CANT_MODIFY_ACTIVE');
    case ErrCodes.CANT_MODIFY_FINISHED: return i18next.t('global.errcodes.CANT_MODIFY_FINISHED');
    case ErrCodes.CLOSEDATE_TOO_CLOSE: return i18next.t('global.errcodes.CLOSEDATE_TOO_CLOSE');
    case ErrCodes.OPENDATE_TOO_CLOSE: return i18next.t('global.errcodes.OPENDATE_TOO_CLOSE');
    case ErrCodes.BAD_STATE_FOR_VOTE: return i18next.t('global.errcodes.BAD_STATE_FOR_VOTE');
    case ErrCodes.UNKNOWN_VOTERID: return i18next.t('global.errcodes.UNKNOWN_VOTERID');
    case ErrCodes.BAD_VOTER_SECRET: return i18next.t('global.errcodes.BAD_VOTER_SECRET');
    case ErrCodes.BAD_VOTER_MAIL: return i18next.t('global.errcodes.BAD_VOTER_MAIL');
    case ErrCodes.UNKNOWN_CANDIDATE: return i18next.t('global.errcodes.UNKNOWN_CANDIDATE');
    case ErrCodes.ALREADY_VOTED: return i18next.t('global.errcodes.ALREADY_VOTED');
    case ErrCodes.TOO_MANY_CHOICES: return i18next.t('global.errcodes.TOO_MANY_CHOICES');
    case ErrCodes.BEFORE_OPENTIME: return i18next.t('global.errcodes.BEFORE_OPENTIME');
    case ErrCodes.AFTER_CLOSETIME: return i18next.t('global.errcodes.AFTER_CLOSETIME');
    case ErrCodes.UNKNOWN_VOTER_MAIL: return i18next.t('global.errcodes.UNKNOWN_VOTER_MAIL');
    case ErrCodes.BAD_AUTHENTICATION: return i18next.t('global.errcodes.BAD_AUTHENTICATION');
    case ErrCodes.INSUFFICIENT_PERMISSION: return i18next.t('global.errcodes.INSUFFICIENT_PERMISSION');
    default: return i18next.t('global.errcodes.default');
  }
}

export const msgStatus = (mStatus: TsMessageStatus): string => {
  return i18next.t('global.messagestatus.' + mStatus);
}

