/**
 * Component for the Vote Page: Email input block.
 */
import i18next from "i18next";
import React from "react";
import { Button, InputGroup } from "react-bootstrap";
import { BackspaceFill } from 'react-bootstrap-icons';
import Spinner from "react-bootstrap/Spinner";
import Form from 'react-bootstrap/Form';
import './vote.css';

export interface MailBkProps {
  email: string;
  isMail: boolean;
  submittingMail: boolean;
  isMailValidated: boolean;
  unknownVoterMail: boolean;
  onMailChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onValidate?: () => void;
  onClear?: () => void;
}

/**
 * Mail Input Block, with email field and validate button.
 * @param props 
 */
export function MailBk(props: MailBkProps) {
  const canValidate = (!props.isMailValidated) && (!props.submittingMail) && (!props.unknownVoterMail) && (props.isMail);
  const onEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (props.onValidate && canValidate && e.code === 'Enter') {
      props.onValidate();
    }
  }
  return <div>
    <MailInput {...mailInputStatus(props, onEnter)} onMailChange={props.onMailChange} />
    <ValidateButton {...props} />
  </div>
}

export interface MailInputStatus {
  email: string;
  mode: 'NEUTRAL' | 'VALID_OK' | 'VALID_FAIL';
  feedback?: string;
  onMailChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClear?: () => void;
  onEnter?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

/** Computes the Email input field status. */
function mailInputStatus(props: MailBkProps, onEnter?: (e: React.KeyboardEvent<HTMLInputElement>) => void): MailInputStatus {
  if (props.unknownVoterMail) {
    return { email: props.email, mode: 'VALID_FAIL', feedback: i18next.t('votepage.unknown_voter_mail'), onEnter: onEnter };
  }
  if (!props.isMailValidated) {
    return { email: props.email, mode: 'NEUTRAL', feedback: "", onEnter: onEnter };
  }
  const feedback = i18next.t('votepage.mail_valid');
  return { email: props.email, mode: 'VALID_OK', feedback, onClear: props.onClear };
}

/** Email input field. */
function MailInput(props: MailInputStatus) {
  return (
    <Form.Group controlId="formBasicEmail">
      <Form.Label>{i18next.t('votepage.emailfield')}</Form.Label>
      <InputGroup>
        <Form.Control type="email" placeholder="votre email" onChange={props.onMailChange} onKeyPress={props.onEnter}
          value={props.email} isInvalid={props.mode === 'VALID_FAIL'} isValid={props.mode === 'VALID_OK'}
          disabled={props.mode === 'VALID_OK'} />
        {props.mode === 'VALID_OK' ?
          <InputGroup.Append>
            <Button variant='outline-secondary' style={{ padding: '0 2em' }} onClick={props.onClear}>
              <BackspaceFill style={{ verticalAlign: 'baseline' }} />
            </Button>
          </InputGroup.Append>
          : null}
        <Form.Control.Feedback type='invalid'>
          {props.feedback}
        </Form.Control.Feedback>
        <Form.Control.Feedback type='valid'>
          {props.feedback}
        </Form.Control.Feedback>
      </InputGroup>
    </Form.Group >);
}

/**
* Component for the VotePage: Validate Mail button.
*/
function ValidateButton(props: MailBkProps) {
  if (props.isMailValidated) {
    return null;
  }
  const btnText = i18next.t('votepage.validate_mail');
  if (props.submittingMail) {
    return <Button variant="primary" block disabled>
      <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
      <span className="sr-only">{btnText}</span>
    </Button>
  }
  if (props.unknownVoterMail || (!props.isMail)) {
    return <Button variant='primary' block disabled>{btnText}</Button>
  }
  return <Button variant='primary' block onClick={props.onValidate}>{btnText}</Button>
}


