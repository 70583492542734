/** Accueil - Point where one can Create a new Scrutin. */
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import Container from 'react-bootstrap/Container';
import i18next from 'i18next';
import { LangSignal } from './Root';
import { getLogger } from '../common/util/pmlogger';

export class About extends React.Component<RouteComponentProps, { lang: string }> {
  constructor(props: RouteComponentProps) {
    super(props);
    this.state = { lang: i18next.language };
  }
  componentDidMount() {
    LangSignal.on('setlang', () => {
      //console.log("About setting lang to " + i18next.language);
      this.setState({ lang: i18next.language });
    });
  }

  public render() {
    getLogger().debug("About", "Rendering with lang {}", this.state.lang);
    return (
      <Container className='about'>
        <h1>{i18next.t('about.title')}</h1>
        <p>{i18next.t('about.accro1')}</p>
        <p>{i18next.t('about.accro2')}</p>

        <h2>{i18next.t('about.progress')}:</h2>
        <ul>
          <li>{i18next.t('about.progress1')}</li>
          {i18next.t('about.progress1sub')}
          <li>{i18next.t('about.progress2')}</li>
          {i18next.t('about.progress2sub')}
          <li>{i18next.t('about.progress3')}</li>
          {i18next.t('about.progress3sub')}
          <li>{i18next.t('about.progress4')}</li>
          {i18next.t('about.progress4sub')}
          <li>{i18next.t('about.progress5')}</li>
          {i18next.t('about.progress5sub')}
        </ul>

        <h2>{i18next.t('about.orga')}:</h2>
        <h5>{i18next.t('about.orga1')}</h5>
        <ul>
          <li>{i18next.t('about.orga1p1')}</li>
          {i18next.t('about.orga1p1sub')}
          <li>{i18next.t('about.orga1p2')}</li>
          <li>{i18next.t('about.orga1p3')}</li>
        </ul>
        <h5>{i18next.t('about.orga2')}</h5>
        <ul><li>{i18next.t('about.orga2p1')}</li></ul>

        <h5>{i18next.t('about.orga3')}</h5>
        <ul><li>{i18next.t('about.orga3p1')}</li></ul>

        <h5>{i18next.t('about.orga4')}</h5>
        <ul>
          <li>{i18next.t('about.orga4p1')}</li>
          <li>{i18next.t('about.orga4p2')}</li>
        </ul>

        <h2>{i18next.t('about.polls')}</h2>
        <h5>{i18next.t('about.polls1')}</h5>
        <ul>
          <li>{i18next.t('about.polls1p1')}</li>
          <li>{i18next.t('about.polls1p2')}</li>
          {i18next.t('about.polls1p2sub')}
        </ul>
        <h5>{i18next.t('about.polls2')}</h5>
        <ul>
          <li>{i18next.t('about.polls2p1')}</li>
          <li>{i18next.t('about.polls2p2')}</li>
          <li>{i18next.t('about.polls2p3')}</li>
        </ul>
        <h5>{i18next.t('about.polls3')}</h5>
        <ul>
          <li>{i18next.t('about.polls3p1')}</li>
          <li>{i18next.t('about.polls3p2')}</li>
        </ul>
        <h5>{i18next.t('about.polls4')}</h5>
        <ul>
          <li>{i18next.t('about.polls4p1')}</li>
          <ul>
            <li>{i18next.t('about.polls4p1p1')}</li>
            <li>{i18next.t('about.polls4p1p2')}</li>
            <li>{i18next.t('about.polls4p1p3')}</li>
          </ul>
          <li>{i18next.t('about.polls4p2')}</li>
        </ul>
        <h5>{i18next.t('about.polls5')}</h5>
        <ul>
          <li>{i18next.t('about.polls5p1')}</li>
          <li>{i18next.t('about.polls5p2')}</li>
        </ul>
        <h5>{i18next.t('about.polls6')}</h5>
        <ul>
          <li>{i18next.t('about.polls6p1')}</li>
          <li>{i18next.t('about.polls6p2')}</li>
          <li>{i18next.t('about.polls6p3')}</li>
          <li>{i18next.t('about.polls6p4')}</li>
          <li>{i18next.t('about.polls6p5')}</li>
          <li>{i18next.t('about.polls6p6')}</li>
        </ul>

        <h2>{i18next.t('about.voo')}</h2>
        <h5>{i18next.t('about.voo0')}</h5>
        <ul>
          <li>{i18next.t('about.voo0p1')}</li>
          <li>{i18next.t('about.voo0p2')}</li>
          <li>{i18next.t('about.voo0p3')}</li>
        </ul>
        <h5>{i18next.t('about.voo1')}</h5>
        <ul>
          <li>{i18next.t('about.voo1p1')}</li>
          <li>{i18next.t('about.voo1p2')}</li>
          <li>{i18next.t('about.voo1p3')}</li>
        </ul>
        <h5>{i18next.t('about.voo2')}</h5>
        <ul><li>{i18next.t('about.voo2p1')}</li></ul>
        <h5>{i18next.t('about.voo3')}</h5>
        <ul>
          <li>{i18next.t('about.voo3p1')}</li>
          <li>{i18next.t('about.voo3p2')}</li>
          <li>{i18next.t('about.voo3p3')}</li>
        </ul>
        <h5>{i18next.t('about.voo4')}</h5>
        <ul>
          <li>{i18next.t('about.voo4p1')}</li>
          <li>{i18next.t('about.voo4p2')}</li>
          <li>{i18next.t('about.voo4p3')}</li>
          <li>{i18next.t('about.voo4p4')}</li>
        </ul>
        <h5>{i18next.t('about.voo5')}</h5>
        <ul><li>{i18next.t('about.voo5p1')}</li></ul>

      </Container >
    );
  }
}
