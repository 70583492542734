/**
 * Affiche l'audit complet du scrutin.
 */
import i18next from 'i18next';
import * as React from 'react';
import { Button, Badge, Modal } from 'react-bootstrap';
import { DateTime } from 'luxon';
import { DatesManager } from '../common/util/DatesManager';
import { getLogger } from '../common/util/pmlogger';
import { TsAuditResponse, TsSentMessageDetail, TsVoterView } from '../common/tsmodel/TsResponses';
import { ErrorBlock } from './UtilViews';
import { msgStatus } from '../common/tsmodel/Converters';
import { VooApi } from '../backend/VooApi';

interface TProps {
  // Note: as shortcut, the contained TsMessageDetails may be directly updated from this view
  audit: TsAuditResponse,
}
interface TState {
  // VoterId with details visible
  detailId?: string,
  // A MailStatus is being refreshed
  refreshing: boolean
}

export class AuditView extends React.PureComponent<TProps, TState> {

  private static LOGTAG = "auditview";

  constructor(props: TProps) {
    super(props);
    this.state = { refreshing: false };
  }



  private static countVotes = (voterViews: TsVoterView[]): number => {
    return voterViews.reduce<number>((prevCount, voter) => voter.hasVoted ? prevCount + 1 : prevCount, 0);
  }

  /** Adds to each VoterView the corresponding sent messages. */
  private static computeVoterMessages = (voterId: string, sentCodeMessages: TsSentMessageDetail[]): TsSentMessageDetail[] => {
    console.log("VoterId: %s, Sent: %o", voterId, sentCodeMessages);
    const ret: TsSentMessageDetail[] = [];
    sentCodeMessages.forEach(msg => {
      if (msg.voterId === voterId) {
        ret.push(msg);
      }
    });
    return ret;
  }

  private onShowDetail = (voterId: string): void => {
    this.setState({ detailId: voterId });
  }
  private onHideDetail = () => {
    this.setState({ detailId: undefined });
  }

  /** 
   * Queries the Mailer API to find the latest status.
   * <p/>
   * IMPORTANT: we will directly update the Audit structure passed as props, to avoid having to going up/down all the way
   */
  private onRefreshMailStatus = (voterMail: string, transportId: string) => {
    if (this.state.detailId) {
      new VooApi().updateMailResult(this.props.audit.scrutinId, this.state.detailId, voterMail, transportId).then((tsSentMessageResponse => {
        // Find the Message object and update it
        for (let i = 0; i < this.props.audit.sentCodeMessages.length; i++) {
          if (this.props.audit.sentCodeMessages[i].transportId === transportId) {
            this.props.audit.sentCodeMessages[i].status = tsSentMessageResponse.sentMessage.status;
            this.props.audit.sentCodeMessages[i].statusKnownFinal = tsSentMessageResponse.sentMessage.statusKnownFinal;
            getLogger().info(AuditView.LOGTAG, "Updated Mail status: %o", tsSentMessageResponse.sentMessage);
            break;
          }
        }
      })).catch(err => {
        getLogger().warn(AuditView.LOGTAG, "Could not update Mail status: %o", err);
      }).finally(() => {
        this.setState({ refreshing: false, detailId: undefined });  // crude closing of Dialog to make sure will update
      });
    }
  }

  public render() {
    getLogger().debug(AuditView.LOGTAG, "Rendering AuditView with: %o", this.props.audit);
    const audit = this.props.audit;
    if (audit.sStatus !== 'ACTIVE' && audit.sStatus !== 'FINISHED') {
      return (
        <ErrorBlock msg={i18next.t('auditview.bad_state')} />
      );
    }
    const startDateStr = DatesManager.get().formatShortNumericDateTime(DateTime.fromMillis(audit.openDateMillis));
    const endDateStr = DatesManager.get().formatShortNumericDateTime(DateTime.fromMillis(audit.closeDateMillis));
    const status_badge = audit.sStatus === 'ACTIVE' ? i18next.t('auditview.badge_active') : i18next.t('auditview.badge_finished');
    const numvoted = AuditView.countVotes(audit.voterViews);
    const taux_part = Math.round(100 * numvoted / audit.voterViews.length).toFixed(2) + '%';
    const vMessages = this.state.detailId ? AuditView.computeVoterMessages(this.state.detailId, audit.sentCodeMessages) : undefined;
    const noMessage = i18next.t('auditview.no_msg');
    return (
      <div className='container-fluid'>
        <form onSubmit={this.onSubmit}>
          <div className='page-header'>
            <h1>{i18next.t('auditview.head')} <small>{i18next.t('auditview.head_sub')}</small></h1>
          </div>
          <div className='container'>
            <div className='div-section'>
              <div className='div-subsection'>
                {i18next.t('auditview.scrutin_dn')}: <span className='strong-value'>{audit.sDisplayName}</span>
              </div>
              <div className='div-subsection'>
                {i18next.t('auditview.orga_dn')}: <span className='strong-value'>{audit.sOrganizerName}</span>
              </div>
              <div className='div-subsection'>
                {i18next.t('auditview.orga_mail')}: <span className='strong-value'>{audit.sOrganizerEmail}</span>
              </div>
              <div className='div-subsection'>
                {i18next.t('auditview.fromdate')} <span className='strong-value'>{startDateStr}</span>&nbsp;
                {i18next.t('auditview.todate')} <span className='strong-value'>{endDateStr}</span>&nbsp;
                <Badge variant='info'>{status_badge}</Badge>
              </div>
              {audit.closeOnAllVoted ?
                <div className='smaller-subsection'>
                  {i18next.t('auditview.close_on_all_voted')}
                </div>
                : null}
              <div className='div-subsection'>
                {i18next.t('auditview.vote_nbvotes', { count: audit.nVotes })}
              </div>
            </div>
            <div className='div-section'>
              {i18next.t('auditview.candidates')}: <Badge variant='info'>{audit.candidates.length}</Badge>
              <div className='div-subsection'>
                <ul className='smaller'>
                  {audit.candidates.map(cand => (<li key={cand}>{cand}</li>))}
                </ul>
              </div>
            </div>
            <div className='div-section'>
              {i18next.t('auditview.voter_statuses')}: <Badge variant='info'>{audit.voters.length}</Badge>
              &nbsp;
              {i18next.t('auditview.voted')}: <Badge variant='success'>{numvoted}</Badge>
              &nbsp;&nbsp;
              (&nbsp;{taux_part}&nbsp;)
              <div className='div-subsection'>
                {audit.voterViews.map((voter, ix) => (<ExtVoterBlock key={ix} voter={voter} onDetail={this.onShowDetail} />))}
              </div>
            </div>


          </div>

        </form>
        <Modal show={vMessages !== undefined} onHide={this.onHideDetail}>
          <Modal.Header closeButton>
            <Modal.Title>{i18next.t('auditview.sent_messages')} :</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {vMessages?.length === 0 ? <div>{noMessage}</div> : vMessages?.map(vMessage => (<DetailMessage key={vMessage.uuid} mStatus={msgStatus(vMessage.status)} statusFinal={vMessage.statusKnownFinal} msg={vMessage} onRefresh={this.onRefreshMailStatus} refreshing={this.state.refreshing} />))}
          </Modal.Body>
        </Modal>
      </div >
    );
  }

  private onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    //    this.props.submit();
  }
}

class ExtVoterBlock extends React.PureComponent<{ voter: TsVoterView, onDetail: (voterId: string) => void }> {

  private onShowDetail = () => {
    this.props.onDetail.call(this, this.props.voter.voterId);
  }

  public render() {
    const voter = this.props.voter;
    const dn = (voter.displayName === '' ? "" : voter.displayName + ' ');
    if (voter.hasVoted) {
      const time = DatesManager.get().formatMedNumericDateTime(DateTime.fromMillis(voter.votedTime as number));
      //return dn + pc.emails.map(eml => "<" + eml + ">").join(', ');

      return (<div className='audit-voter'>
        <Badge variant='success'>{time}</Badge>&nbsp;
        {dn}
        {voter.emails.map(eml => {
          return eml === voter.voterMail ?
            (<span key={eml} className='audit-matchmail'>&lt;{eml}&gt;</span>) :
            (<span key={eml} className='audit-amail'>&lt;{eml}&gt;</span>)
        })}
        &nbsp;&nbsp;
        <Button variant='link' style={{ verticalAlign: 'baseline' }} onClick={this.onShowDetail}>{i18next.t('auditview.showdetail')}</Button>
      </div>);
    } else {
      return (<div className='audit-voter'>
        <Badge variant='warning'>{i18next.t('auditview.badgeno')}</Badge>&nbsp;
        {dn}
        {voter.emails.map(eml => (<span key={eml} className='audit-amail'>&lt;{eml}&gt;</span>))}
        &nbsp;&nbsp;
        <Button variant='link' style={{ verticalAlign: 'baseline' }} onClick={this.onShowDetail}>{i18next.t('auditview.showdetail')}</Button>
      </div>);
    }
  }
}

// mStatus and statusFinal provided as props to ensure updated status refreshes display
function DetailMessage(props: { mStatus: string, statusFinal: boolean, msg: TsSentMessageDetail, onRefresh: (voterMail: string, transportId: string) => void, refreshing: boolean }) {
  const msg = props.msg;
  const mType = i18next.t('auditview.mtype_' + (msg.type as string));
  const mTime = DatesManager.get().formatMedNumericDateTime(DateTime.fromMillis(msg.opTime));
  const onRefreshMailStatus = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    props.onRefresh(msg.destination, msg.transportId);
  }
  return (
    <div className='audit-msg'>
      <div className='audit-msg-field'>
        "{msg.subject}"
      </div>
      <div className='audit-msg-field'>
        {i18next.t('auditview.msg_type')}: <span className='strong-value'>{mType}</span>
      </div>
      <div className='audit-msg-field'>
        {i18next.t('auditview.msg_time')}: <span className='strong-value'>{mTime}</span>
      </div>
      <div className='audit-msg-field'>
        {i18next.t('auditview.msg_dest')}: <span className='strong-value'>{msg.destination}</span>
      </div>
      <div className='audit-msg-field'>
        {i18next.t('auditview.msg_status')}: <span className='strong-value'>{props.mStatus}</span>&nbsp;&nbsp;
        {props.statusFinal || props.refreshing ? null : <a href="/" onClick={onRefreshMailStatus}>{i18next.t('auditview.refresh_mail')}</a>}
      </div>
    </div>);
}