/**
 * Autonomously manages the SendSecret button & status, the Code Input field and the Validate Mail button.
 * <p/>
 * Invokes as needed the VoteUiStore, that will submit the email for validation and update its internal VoterStatuses (emitting change events).
 * <p/>
 * Uses the SendSecretStatus and ValidateSecretStatus from VoteUiStore, and the sendSecret and validateSecret actions.
 */
import i18next from "i18next";
import React from "react";
import { ErrCodes } from "../common/backend/ErrCodes";
import { TsOneVoter } from "../common/tsmodel/TsResponses";
import { SecretBk } from "./SecretBk";
import { SendSecretBk } from "./SendSecretBk";
import './vote.css';
import { getVoteUiStore, VUIS_CHANGE } from "./VoteUiStore";


export interface CodeBlockProps {
  /** Initial value of Code, presets the Code field. */
  initialSecret?: string;
}
interface CodeBlockState {
  secret: string;
  // SendSecretStatus
  sendingSecret: boolean;
  sendingSecretError: ErrCodes;
  voter?: TsOneVoter; // Reference Voter (contains the sentMessage)
  // ValidateSecretStatus
  submittingSecret: boolean;
  secretValidated: boolean;
  secretError: ErrCodes;
  testMode: boolean;
}

/**
 * Behaviour and Query Logic for the Code Input and Validation block.
 */
export class CodeBlock extends React.Component<CodeBlockProps, CodeBlockState> {

  constructor(props: CodeBlockProps) {
    super(props);
    const vstore = getVoteUiStore();
    this.state = {
      secret: props.initialSecret || "",
      sendingSecret: vstore.getSendSecretStatus().sendingSecret,
      sendingSecretError: vstore.getSendSecretStatus().sendingSecretError,
      voter: vstore.getSendSecretStatus().voter,
      submittingSecret: vstore.getValidateSecretStatus().submittingSecret,
      secretValidated: vstore.getValidateSecretStatus().secretValidated,
      secretError: vstore.getValidateSecretStatus().secretError,
      testMode: vstore.getScrutin() !== undefined && vstore.getScrutin()?.sStatus === 'TESTING'
    }
  }

  public componentDidMount() {
    getVoteUiStore().on(VUIS_CHANGE, this.updateState);
  }

  private updateState = () => {
    const vstore = getVoteUiStore();
    this.setState({
      sendingSecret: vstore.getSendSecretStatus().sendingSecret,
      sendingSecretError: vstore.getSendSecretStatus().sendingSecretError,
      voter: vstore.getSendSecretStatus().voter,
      submittingSecret: vstore.getValidateSecretStatus().submittingSecret,
      secretValidated: vstore.getValidateSecretStatus().secretValidated,
      secretError: vstore.getValidateSecretStatus().secretError,
      testMode: vstore.getScrutin() !== undefined && vstore.getScrutin()?.sStatus === 'TESTING'
    });
  }

  public componentWillUnmount() {
    getVoteUiStore().off(VUIS_CHANGE, this.updateState);
  }

  private onSecretChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const val = e.currentTarget.value as string;
    this.setState({ secret: val });
  }

  private onSendSecret = (e: React.MouseEvent) => {
    e.preventDefault();
    getVoteUiStore().sendSecret();
  }

  private onValidateSecret = () => {
    getVoteUiStore().validateSecret(this.state.secret);
  }

  public render() {
    return (
      <>
        <SendSecretBk {...this.state} onSendSecret={this.onSendSecret} />
        <SecretBk {...this.state} onSecretChange={this.onSecretChange} onValidate={this.onValidateSecret} />
        {this.state.testMode ?
          <div className='testmailinfodiv text-secondary'>{i18next.t('votepage.testmode')}</div>
          : null
        }
      </>);
  }
}
