/**
 * API Constants and definitions.
 */
export class TsModelDefs {

  public static LOCAL_MAC = '';
  public static LOCAL_SID = '';

  /** Today 0h (local time) */
  public static defaultOpenDate = () => {
    const d = new Date();
    d.setHours(0);
    d.setMinutes(0);
    d.setSeconds(0);
    d.setMilliseconds(0);
    return d;
  }
  /** Tomorrow, 20h (local time) */
  public static defaultCloseDate = () => {
    const d = new Date();
    d.setDate(d.getDate() + 1);
    d.setHours(19);
    d.setMinutes(59);
    d.setSeconds(0);
    d.setMilliseconds(0);
    return d;
  }
}
/** 
 * ON_CLIENT: Local, pas de contrepartie serveur.
 * TESTING: Validé et sauvé sur Serveur. Modifiable. Résultats accessibles.
 * READY: Validé et sauvé sur Serveur. Non Modifiable. On peut repasser en TESTING avant la OpenDate.
 * ACTIVE: En cours. Non modifiable.
 * FINISHED: Terminé. Nom modifiable. Résultats accessibles.
 * <p/>
 * Les Transitions autorisées:
 * ON_CLIENT => TESTING: par le Serveur, lors de la création du Scrutin en DB
 * TESTING <=> READY: par le Client, confirmé par Serveur; Tout Save en TESTING efface les votes;
 *   Un passage en READY après la date de départ résultera en un passage ACTIVE peu après.
 * READY => ACTIVE: par le Serveur à la date de départ.
 * ACTIVE => FINISHED: par le Serveur à la date de fin.
 *
 * Process Serveur: READY => ACTIVE et ACTIVE => FINISHED selon les dates.
 */
export type SStatus = 'ON_CLIENT' | 'TESTING' | 'READY' | 'ACTIVE' | 'FINISHED';
// Note: avoid enum for transfer to Java (not directly deserialized)
export type VotersVisibility = 'NONE' | 'DISPLAY_NAME' | 'ALL';
export type TsMessageType = 'EMAIL' | 'SMS';
export type TsMessageStatus = 'UNKNOWN' | 'SENT' | 'QUEUED' | 'MAX_MESSAGES_TO_TARGET' | 'SENDING_ERROR' | 'RECIPIENT_ISSUE' | 'LABELLED_SPAM' | 'OTHER_ERROR';
export type SupportedLang = 'FR' | 'EN';
export type TsDbMessageType = 'CODE_MAIL' | 'FINISHED_MAIL' | 'ORGA_MAIL';

