import { ParsedContactsList, ParsedContact } from "./ParsedContactsList";
import { Formatter } from "../common/util/Formatter";

/** 
 * This class handles the Parsing of Contacts.
 */

const lineSplitterRe = /[^(\r\n|\n|\v|\f|\r|\x85|\u2028|\u2029)]+/g;
const commentLineRe = /^(\/\/|#).*/;
const wordSplitterRe = /([^\s,;:])+/g;
const bracketsRemoveRe = /[[\]<>()]/g;

/**
 * Format is: Display Name and a series of emails, separated by commas, spaces or semi-colons.
 * Or just a Series of Emails separated by commas, spaces or semicolons.
 * Algorithm: Split in words. All that is not email belongs to Display Name
 * Comment Lines: start with: // or #
 */
export const parseContacts = (mailsField: string): ParsedContactsList => {
  const results = new ParsedContactsList();
  // split into lines whatever the platform
  const mailLines = mailsField.match(lineSplitterRe);
  if (mailLines === null) {
    // No matching - One line
    parseOneLine(mailsField, results);
  } else {
    mailLines.forEach(line => {
      parseOneLine(line, results);
    });
  }
  results.validated = (results.validContacts.length > 0 && results.invalidContacts.length === 0);
  return results;
}

/** Exported for Unit Tests. */
export const parseOneLine = (line: string, results: ParsedContactsList): void => {
  if (line.trim() === '') {
    // Empty line - Ignore silently
    return;
  }
  if (commentLineRe.test(line)) {
    // Comment lines
    results.ignoredLines.push(line);
  } else {
    const contactLine = new ParsedContact();
    // Split into Words
    const words = line.match(wordSplitterRe);
    if (words === null) {
      // One word only
      addWord(line, contactLine);
    } else if (words.length === 0) {
      // Empty line - Ignore silently
      return;
    } else {
      words.forEach(w => {
        addWord(w, contactLine);
      })
    }
    if (contactLine.emails.length > 0) {
      results.validContacts.push(contactLine);
    } else {
      results.invalidContacts.push(line);
    }
  }
}

/** Exported for Unit Tests. */
export const addWord = (w: string, contactLine: ParsedContact): void => {
  w = w.replace(bracketsRemoveRe, '');
  if (Formatter.get().isValidEmail(w)) {
    contactLine.emails.push(w);
  } else {
    contactLine.appendToDisplayName(Formatter.get().sanitizeName(w));
  }
}

/**
 * Splits in lines.
 * Comment Lines: start with: // or #
 */
export const parseCandidates = (candidatesField: string): string[] => {
  // split into lines whatever the platform
  const mailLines = candidatesField.match(lineSplitterRe);
  if (mailLines === null) {
    // No matching - One line
    if (candidatesField.trim() === '') {
      return [];
    }
    return [candidatesField.trim()];
  } else {
    const ret: string[] = [];
    mailLines.forEach(line => {
      const trimmedLine = line.trim();
      if (trimmedLine !== '' && (!ret.includes(trimmedLine))) {
        ret.push(trimmedLine);
      }
    });
    return ret;
  }
}
