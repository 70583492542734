/**
 * Appartient au Wizard géré par EditorContainer.
 * Permet de coller / entrer une liste de Candidats.
 * 
 * TODO: Ajouter ici le DELETE
 *                   <Button variant='warning' className='pull-right'>
                    <span className='glyphicon glyphicon-trash' />{' '}{i18next.t('scrutin.delete')}
                  </Button> :

 */
import i18next from 'i18next';
import * as React from 'react';
import { Button, Alert, Badge } from 'react-bootstrap';
import { DateTime } from 'luxon';
import { DatesManager } from '../common/util/DatesManager';
import { getLogger } from '../common/util/pmlogger';
import { TsCreateUpdateScrutin } from '../backend/tsmodel/TsCreateUpdateScrutin';
import { contactDisplayString } from '../common/util/Formatter';

interface TProps {
  visible: boolean,
  appBean: TsCreateUpdateScrutin,
  allOk: boolean,
  editable: boolean,
  submitting: boolean,
  submit: () => void
}
interface TState {
}

export class SummaryView extends React.PureComponent<TProps, TState> {

  private static LOGTAG = "summaryview";

  constructor(props: any) {
    super(props);
    this.state = {};
  }

  public render() {
    if (!this.props.visible) {
      return null;
    }
    getLogger().debug(SummaryView.LOGTAG, "Rendering SummaryView with: %o", this.props.appBean)
    const ab = this.props.appBean;
    const startDateStr = DatesManager.get().formatFutureShortNumericDateTime(ab.openDateMillis);
    const endDateStr = DatesManager.get().formatShortNumericDateTime(DateTime.fromMillis(ab.closeDateMillis));
    const testMode = ab.sStatus === 'ON_CLIENT' || ab.sStatus === 'TESTING';
    const editable = this.props.editable;
    return (
      <div className='container-fluid'>
        <form onSubmit={this.onSubmit}>
          <div className='page-header'>
            <h1>{i18next.t('summary.head')} <small>{i18next.t('summary.head_sub')}</small></h1>
          </div>
          <div className='container'>
            {testMode && this.props.allOk ?
              <Alert variant='primary'>
                <p>{i18next.t('summary.test_mode')}</p>
              </Alert>
              : null}
            {testMode && (!this.props.allOk) ?
              <Alert variant='warning'>
                <p>{i18next.t('summary.has_errors')}</p>
              </Alert>
              : null}

            <div className='div-section'>
              <div className='div-subsection'>
                {i18next.t('summary.scrutin_dn')}: <span className='strong-value'>{ab.sDisplayName}</span>
              </div>
              <div className='div-subsection'>
                {i18next.t('summary.orga_dn')}: <span className='strong-value'>{ab.sOrganizerName}</span>
              </div>
              <div className='div-subsection'>
                {i18next.t('summary.orga_mail')}: <span className='strong-value'>{ab.sOrganizerEmail}</span>
              </div>
              <div className='div-subsection'>
                {i18next.t('summary.fromdate')} <span className='strong-value'>{startDateStr}</span>&nbsp;
                {i18next.t('summary.todate')} <span className='strong-value'>{endDateStr}</span>&nbsp;
              </div>
              {ab.closeOnAllVoted ?
                <div className='smaller-subsection'>
                  {i18next.t('summary.close_on_all_voted')}
                </div>
                : null}
              <div className='div-subsection'>
                {i18next.t('summary.vote_nbvotes', { count: ab.nVotes })}
              </div>
            </div>
            <div className='div-section'>
              {i18next.t('summary.candidates')}: <Badge variant='info'>{ab.candidates.length}</Badge>
              <div className='div-subsection'>
                <ul className='smaller'>
                  {ab.candidates.map(cand => (<li key={cand}>{cand}</li>))}
                </ul>
              </div>
            </div>
            <div className='div-section'>
              {i18next.t('summary.voters')}: <Badge variant='info'>{ab.voters.length}</Badge>
              <div className='div-subsection'>
                <ul className='smaller'>
                  {ab.voters.map((contact, ix) => (<li key={ix}>{contactDisplayString(contact)}</li>))}
                </ul>
              </div>
            </div>

            {editable ?
              <div className='ed-controls'>
                <Button variant='primary' type='submit' disabled={this.props.submitting || (!this.props.allOk)} className='ed-button'>{i18next.t('summary.gotest')}</Button>
              </div>
              : null}
          </div>

          {editable ?
            <div style={{ padding: '10px', margin: '15px 0px', backgroundColor: '#EEEECC' }}>
              <h3>{i18next.t('summary.vote_info_title')}:</h3>
              <div>{i18next.t('summary.vote_link')}</div>
              <div>{i18next.t('summary.vote_info1')}</div>
              <div>{i18next.t('summary.vote_info2')}</div>
              <div>{i18next.t('summary.vote_info3', { ncands: ab.candidates.length, nvotes: ab.nVotes })}</div>
              <div>{i18next.t('summary.vote_info4')}</div>
              <div>{i18next.t('summary.vote_info5')}</div>
            </div>
            : null}
        </form>
      </div >
    );
  }

  private onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.props.submit();
  }
}