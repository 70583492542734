/**
 * Error Codes for Network interactions
 */
export enum ErrCodes {
  // Our Codes:
  HTTP_UNKNOWN = -999,
  HTTP_TIMEOUT = -10,
  HTTP_UNAUTHORIZED = -11, // Http Response 401 or 403
  HTTP_MALFORMED = -12,    // Malformed Server Response

  // From HTTP Response, do not change values (HTTP code / 100)
  HTTP_INFO = -1,      // Http Response 1xx
  HTTP_CLIENT_ERROR = -4,  // Http Response 4xx, except 401/403
  HTTP_SERVER_ERROR = -5,  // Http Response 5xx

  // From Java (AppOperationException.Code)
  OK = 0,
  UNKNOWN = 1,
  MISSING_FIELD = 2,
  /** Create or Update scrutin. CloseDate is too far (4 month from now). */
  CLOSEDATE_TOO_FAR = 3,
  /** Db Failure or error response. */
  DBFAIL = 4,
  /** Invalid data provided to the API. */
  ERR_INVALID_INPUT = 5,
  /** MAC or SID allocation error. */
  CANT_ALLOCATE_ID = 6,
  /** Mac and/or ScrutinId does not match a known Scrutin. */
  SCRUTIN_NOT_FOUND = 7,
  /** Server-side error: Scrutin found in several States. */
  ERR_INVALID_STATES = 8,
  /** Attempt to Update or GoReady a Scrutin in the READY state. */
  CANT_MODIFY_READY = 9,
  /** Attempt to Update, GoReady or BackTesting a Scrutin in the ACTIVE state.  */
  CANT_MODIFY_ACTIVE = 10,
  /** Attempt to Update, GoReady or BackTesting a Scrutin in the FINISHED state.  */
  CANT_MODIFY_FINISHED = 11,
  /** Attempt to Update a Scrutin with a CloseDate less than 15-min in the Future. */
  CLOSEDATE_TOO_CLOSE = 12,
  /** Attempt to BackToTesting a Scrutin to be opened in less than 5 minutes.  */
  OPENDATE_TOO_CLOSE = 13,
  /** Attempt to cast a TestVote on a Scrutin not TESTING, or SendSecret if neither TESTING nor ACTIVE */
  BAD_STATE_FOR_VOTE = 14,
  /** The VoterId does not match any Voter on this Scrutin.  */
  UNKNOWN_VOTERID = 15,
  /** The Voter Secret does not match the Secret of this Voter. */
  BAD_VOTER_SECRET = 16,
  /** The Email does not match any of the emails associated with this Voter, identified by ID & Secret. */
  BAD_VOTER_MAIL = 17,
  /** In a TestVote or RealVote, the supplied Candidate is not associated with a Candidate. */
  UNKNOWN_CANDIDATE = 18,
  /** In a TestVote or RealVote, the supplied Voter has already expressed a vote. */
  ALREADY_VOTED = 19,
  /** In a TestVote or RealVote, more Candidates than nVotes. */
  TOO_MANY_CHOICES = 20,
  /** Attempt to express a RealVote before the scrutin OpenDate. */
  BEFORE_OPENTIME = 21,
  /** Attempt to express a RealVote after the scrutin CloseDate. */
  AFTER_CLOSETIME = 22,
  /** For a VoterStatus or SendSecret request, the Email does not match any Voter. */
  UNKNOWN_VOTER_MAIL = 23,
  /** For an Admin command, wrong login and/or password. */
  BAD_AUTHENTICATION = 24,
  /** The User identified by login/password has an insufficient AuthLevel (VIEWER vs ADMIN). */
  INSUFFICIENT_PERMISSION = 25,
}

export const ErrCodeString = (errCode: ErrCodes) => {
  switch (errCode) {
    case ErrCodes.HTTP_UNKNOWN: return "HTTP_UNKNOWN";
    case ErrCodes.HTTP_TIMEOUT: return "HTTP_TIMEOUT";
    case ErrCodes.HTTP_UNAUTHORIZED: return "HTTP_UNAUTHORIZED";
    case ErrCodes.HTTP_MALFORMED: return "HTTP_MALFORMED";
    case ErrCodes.HTTP_INFO: return "HTTP_INFO";
    case ErrCodes.HTTP_CLIENT_ERROR: return "HTTP_CLIENT_ERROR";
    case ErrCodes.HTTP_SERVER_ERROR: return "HTTP_SERVER_ERROR";
    case ErrCodes.OK: return "OK";
    case ErrCodes.UNKNOWN: return "UNKNOWN";
    case ErrCodes.MISSING_FIELD: return "MISSING_FIELD";
    case ErrCodes.CLOSEDATE_TOO_FAR: return "CLOSEDATE_TOO_FAR";
    case ErrCodes.DBFAIL: return "DBFAIL";
    case ErrCodes.ERR_INVALID_INPUT: return "ERR_INVALID_INPUT";
    case ErrCodes.CANT_ALLOCATE_ID: return "CANT_ALLOCATE_ID";
    case ErrCodes.SCRUTIN_NOT_FOUND: return "SCRUTIN_NOT_FOUND";
    case ErrCodes.ERR_INVALID_STATES: return "ERR_INVALID_STATES";
    case ErrCodes.CANT_MODIFY_READY: return "CANT_MODIFY_READY";
    case ErrCodes.CANT_MODIFY_ACTIVE: return "CANT_MODIFY_ACTIVE";
    case ErrCodes.CANT_MODIFY_FINISHED: return "CANT_MODIFY_FINISHED";
    case ErrCodes.CLOSEDATE_TOO_CLOSE: return "CLOSEDATE_TOO_CLOSE";
    case ErrCodes.OPENDATE_TOO_CLOSE: return "OPENDATE_TOO_CLOSE";
    case ErrCodes.BAD_STATE_FOR_VOTE: return "BAD_STATE_FOR_VOTE";
    case ErrCodes.UNKNOWN_VOTERID: return "UNKNOWN_VOTERID";
    case ErrCodes.BAD_VOTER_SECRET: return "BAD_VOTER_SECRET";
    case ErrCodes.BAD_VOTER_MAIL: return "BAD_VOTER_MAIL";
    case ErrCodes.UNKNOWN_CANDIDATE: return "UNKNOWN_CANDIDATE";
    case ErrCodes.ALREADY_VOTED: return "ALREADY_VOTED";
    case ErrCodes.TOO_MANY_CHOICES: return "TOO_MANY_CHOICES";
    case ErrCodes.BEFORE_OPENTIME: return "BEFORE_OPENTIME";
    case ErrCodes.AFTER_CLOSETIME: return "AFTER_CLOSETIME";
    case ErrCodes.UNKNOWN_VOTER_MAIL: return "UNKNOWN_VOTER_MAIL";
    case ErrCodes.BAD_AUTHENTICATION: return "BAD_AUTHENTICATION";
    case ErrCodes.INSUFFICIENT_PERMISSION: return "INSUFFICIENT_PERMISSION";
  }
}
