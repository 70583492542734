/**
 * Page autonome - vue d'un Scrutin par son Admin, quelque soit son état.
 * <br/>/admin/:mac/:scrutinId
 */
import i18next from 'i18next';
import * as React from 'react';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import { getLogger } from '../common/util/pmlogger';
import { TestView } from './TestView';
import { ReadyView } from './ReadyView';
import { ActiveView } from './ActiveView';
import { TsAuditResponse } from '../common/tsmodel/TsResponses';
import { TsModelDefs } from '../common/tsmodel/TsModelDefs';
import { VooApi } from '../backend/VooApi';
import { errMessage } from '../common/tsmodel/Converters';
import { ErrorBlock, LoadingBlock } from './UtilViews';
import { FinishedView } from './FinishedView';
import { LangSignal } from '../app/Root';
import { AppStore, appStore } from '../app/AppStore';
import { TsCreateUpdateScrutin } from '../backend/tsmodel/TsCreateUpdateScrutin';
import { SyncObject, SyncStatus } from '../common/backend/SyncObject';

interface MatchParams {
  mac: string,
  scrutinId: string
}

/** We always have at least one of: loading,errMessage,scrutin */
interface TState {
  loading: boolean;
  errMessage?: string;
  scrutin?: TsCreateUpdateScrutin;
  loadingAudit: boolean;
  errAudit?: string;
  audit?: TsAuditResponse;
  lang: string;
}

export class AdminView extends React.PureComponent<RouteComponentProps<MatchParams>, TState> {

  private static LOGTAG = "adminview";

  constructor(props: any) {
    super(props);
    this.state = {
      loading: true,
      loadingAudit: false,
      lang: i18next.language
    };
  }

  componentDidMount() {
    LangSignal.on('setlang', () => {
      // console.log("AdminView setting lang to " + i18next.language);
      this.setState({ lang: i18next.language });
    });
    const mac = this.props.match.params.mac;
    const scrutinId = this.props.match.params.scrutinId;
    if ((!mac) || (mac === TsModelDefs.LOCAL_MAC) || (!scrutinId) || (scrutinId === TsModelDefs.LOCAL_SID)) {
      getLogger().warn(AdminView.LOGTAG, "AdminView should not be accessed without MAC/SID values.");
      this.setState({ errMessage: i18next.t('adminview.missing_urlparams') });
      return;
    }
    appStore.on(AppStore.SYNC_Event, this.onSyncEvent);
    appStore.acquireCurrent(mac, scrutinId);
  }

  componentWillUnmount() {
    appStore.off(AppStore.SYNC_Event, this.onSyncEvent);
  }

  private onSyncEvent = (current: SyncObject<TsCreateUpdateScrutin>) => {
    if (current.sync === SyncStatus.SYNCHRONIZING) {
      this.setState({ loading: true, errMessage: undefined, scrutin: undefined });
    } else if (current.sync === SyncStatus.SYNC_ERROR) {
      this.setState({ loading: false, errMessage: errMessage(current.getErrCode()), scrutin: undefined });
    } else if (current.sync === SyncStatus.SYNCHRONIZED) {
      this.setState({ loading: false, errMessage: undefined, scrutin: current.getValue() });
    } else {
      // Unexpected Local or Not_Initialized - probable bad reload, force page reload.
      getLogger().error(AdminView.LOGTAG, "Unexpected Sync State %o - reloading page.", current.sync);
      document.location.reload();
      this.setState({ loading: true, errMessage: undefined, scrutin: undefined });
    }
  }

  private vooRoot = () => {
    this.props.history.push('/');
  }

  private modify = () => {
    this.props.history.push('/edit/' + this.props.match.params.mac + '/' + this.props.match.params.scrutinId);
  }

  private result = () => {
    if (this.state.scrutin && (this.state.scrutin.sStatus === 'ACTIVE' || this.state.scrutin.sStatus === 'FINISHED')) {
      this.props.history.push('/results/' + this.state.scrutin.scrutinId);
    } else if (this.state.scrutin) {
      this.props.history.push('/results/' + this.state.scrutin.scrutinId + '/' + this.state.scrutin.mac);
    }
  }
  private testResultsUrl = () => {
    if (this.state.scrutin) {
      return '/results/' + this.state.scrutin.scrutinId + '/' + this.state.scrutin.mac;
    }
    return "";
  }

  private triggerRefresh = () => {
    setTimeout(this.doRefresh, 0);
  }
  private doRefresh = () => {
    const mac = this.props.match.params.mac;
    const scrutinId = this.props.match.params.scrutinId;
    appStore.acquireCurrent(mac, scrutinId, true);
  }

  private doGoReady = () => {
    appStore.goReady();
  }

  private doBackTesting = () => {
    appStore.backToTest();
  }

  private triggerAudit = () => {
    this.setState({ loadingAudit: true, errAudit: undefined, audit: undefined });
    setTimeout(this.loadAudit, 0);
  }
  private loadAudit = () => {
    if (this.state.scrutin && this.state.scrutin.mac !== TsModelDefs.LOCAL_MAC) {
      new VooApi().auditScrutin(this.state.scrutin.mac, this.state.scrutin.scrutinId).then(
        audit => {
          getLogger().info(AdminView.LOGTAG, "Audit response from Server: %o", audit);
          this.setState({ loadingAudit: false, audit });
        }
      ).catch(err => {
        getLogger().warn(AdminView.LOGTAG, "Error Audit from Server: %o", err);
        this.setState({ loadingAudit: false, errAudit: errMessage(err) });
      });
    }
  }

  public render() {
    if (this.state.errMessage) {
      return <ErrorBlock msg={this.state.errMessage} backAction={this.vooRoot} backText={i18next.t('adminview.back_on_error')} />
    }
    if (this.state.loading) {
      return <LoadingBlock msg={i18next.t('adminview.loading')} />;
    }

    const appBean: TsCreateUpdateScrutin = this.state.scrutin as TsCreateUpdateScrutin;
    // Handle direct URL changes
    if (appBean.mac !== this.props.match.params.mac || appBean.scrutinId !== this.props.match.params.scrutinId) {
      this.triggerRefresh();
      return <LoadingBlock msg={i18next.t('adminview.loading')} />;
    }

    if (appBean.sStatus === 'ON_CLIENT') {
      // Should not happen, not saved on the server
      getLogger().warn(AdminView.LOGTAG, "Admin page not accessible - scrutin not successfully saved.");
      return <Redirect to={"/edit/" + this.props.match.params.mac + '/' + this.props.match.params.scrutinId} />;
    }

    if (appBean.sStatus === 'TESTING') {
      // VoteURL = .../vote/scrutinId or .../vote/scrutinId/mac 
      return <TestView sDisplayName={appBean.sDisplayName} lang={this.state.lang} voteUrl={appBean.tsVoteUrl as string} resultsUrl={this.testResultsUrl()} openDateMillis={appBean.openDateMillis}
        closeDateMillis={appBean.closeDateMillis} scrutinId={appBean.scrutinId}
        modify={this.modify} validate={this.doGoReady} />;
    } else if (appBean.sStatus === 'READY') {
      return <ReadyView sDisplayName={appBean.sDisplayName} voteUrl={appBean.tsVoteUrl as string} openDateMillis={appBean.openDateMillis}
        closeDateMillis={appBean.closeDateMillis} scrutinId={appBean.scrutinId} refresh={this.doRefresh}
        view={this.modify} backtotest={this.doBackTesting} lang={this.state.lang} />;
    } else if (appBean.sStatus === 'ACTIVE') {
      return <ActiveView scrutinId={appBean.scrutinId} sDisplayName={appBean.sDisplayName} voteUrl={appBean.tsVoteUrl as string}
        getAudit={this.triggerAudit} audit={this.state.audit} loadingAudit={this.state.loadingAudit}
        view={this.modify} closeDateMillis={appBean.closeDateMillis} lang={this.state.lang} />;
    } else if (appBean.sStatus === 'FINISHED') {
      return <FinishedView sDisplayName={appBean.sDisplayName} lang={this.state.lang}
        view={this.modify} result={this.result} getAudit={this.triggerAudit} audit={this.state.audit} loadingAudit={this.state.loadingAudit}
        openDateMillis={appBean.openDateMillis} closeDateMillis={appBean.closeDateMillis} closeOnAllVoted={appBean.closeOnAllVoted} />;
    }
    getLogger().error(AdminView.LOGTAG, "Unexpected State for AdminView: %o", appBean);
    return <Redirect to="/" />;
  }

}