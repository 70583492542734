/**
 * Permet de coller / entrer une liste brute de contacts.
 * <p/>
 * Sauve le texte en cours de frappe (avec save), pour retrouver l'état si on change de Tab.
 * Quand le textarea est vide, le bouton Suivant est disabled.
 * Quand le textarea n'est pas vide, le bouton Suivant est enabled
 * Un clic sur "Suivant" fait la validation en local: No Errs + numOk > 0 => submit invoqué.
 * Toute entrée de texte efface le résultat du Parsing.
 */
import i18next from 'i18next';
import * as React from 'react';
import { Form, Button } from 'react-bootstrap';
import { ParsedContactsList } from '../workers/ParsedContactsList';
import { parseContacts } from '../workers/LineParser';
import { getLogger } from '../common/util/pmlogger';
import { TsParsedContact } from '../common/tsmodel/TsParsedContact';

interface VVProps {
  visible: boolean,
  voters: TsParsedContact[],
  editable: boolean,
  dirty: () => void,
  submit: (contacts: TsParsedContact[]) => void
}

export interface VVState {
  textlines: string,
  errs: string[],
  numOk: number,   // -1 : unknown ( list has not been validated yet )
  help: boolean
}

export class VotersView extends React.PureComponent<VVProps, VVState> {

  private static LOGTAG = "voters";

  constructor(props: VVProps) {
    super(props);
    const textlines = props.voters.map(pc => VotersView.displayString(pc)).join('\n');
    this.state = {
      textlines,
      errs: [],
      numOk: -1,
      help: false
    };
  }

  private static displayString = (pc: TsParsedContact): string => {
    const dn = (pc.displayName === '' ? "" : pc.displayName + ' ');
    return dn + pc.emails.map(eml => "<" + eml + ">").join(', ');
  }

  public render() {
    if (!this.props.visible) {
      return null;
    }
    getLogger().info(VotersView.LOGTAG, "Rendering Voters with: %o", this.props);
    const hasErr = this.state.errs.length !== 0 || this.state.numOk < 1;
    const editable = this.props.editable;

    return (
      <div className='container-fluid'>
        <form onSubmit={this.submit}>
          <Form.Group controlId="textlines" id="textlinesGroup">
            <Form.Label>{i18next.t('voters.textlines')}{' '}
              <span className='help-inline text-muted'>
                <em><small>{i18next.t('voters.textlines_sub')}</small></em>
              </span></Form.Label>
            <Form.Control value={this.state.textlines} as="textarea" rows={10} name='textlines'
              onChange={this.onChange} disabled={!editable}
              isValid={!hasErr}
              isInvalid={this.state.errs.length !== 0 || this.state.numOk === 0}
              placeholder={i18next.t('voters.textlines_ph')} />
            {
              this.state.numOk >= 0 ?
                <div className="valid-feedback">
                  {i18next.t('voters.found_contacts')}: {this.state.numOk}
                </div>
                : null
            }
            {
              this.state.errs.length !== 0 ?
                <div className="invalid-feedback">
                  <div>{i18next.t('voters.errored_lines')}:</div>
                  <ul>
                    {this.state.errs.map(err => (<li key={err} className='errline'>{err}</li>))}
                  </ul>
                </div>
                : null
            }
            {
              this.state.errs.length === 0 && this.state.numOk === 0 ?
                <div className="invalid-feedback">
                  {i18next.t('voters.no_contact')}
                </div>
                : null
            }
          </Form.Group>

          {editable ?
            <div className='ed-controls'>
              <Button variant='primary' type='submit' className='ed-button'>{i18next.t('voters.save')}</Button>
            </div>
            : null}
        </form>

        {editable ?
          <div style={{ padding: '10px', margin: '15px 0px', backgroundColor: '#EEEECC' }}>
            <h3>{i18next.t('voters.help_title_how')}:</h3>
            <div>{i18next.t('voters.help_how_1')}</div>
            <div>{i18next.t('voters.help_how_2')}</div>
            <div>{i18next.t('voters.help_how_3')}</div>
            <div style={{ marginBottom: '10px' }}>
              {i18next.t('voters.help_how_4')}
              <ul>
                <li>{i18next.t('voters.help_how_5')}</li>
                <li>{i18next.t('voters.help_how_6')}</li>
              </ul>
              {i18next.t('voters.help_how_7')}
            </div>

            <h3>{i18next.t('voters.help_title_use')}:</h3>
            <div style={{ padding: '10px', fontStyle: 'plain' }}>
              <div>{i18next.t('voters.help_use_1')}</div>
              <div>
                {i18next.t('voters.help_use_2')}<br />
                {i18next.t('voters.help_use_3')}<br />
              </div>
            </div>
          </div>
          : null}

      </div >
    );
  }

  private onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    const txt = e.currentTarget.value;
    this.props.dirty();
    this.setState({ textlines: txt, numOk: -1 });
  }

  private submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const parsed: ParsedContactsList = parseContacts(this.state.textlines);
    if (parsed.validated) {
      const cleanLines = parsed.validContacts.map(pc => pc.displayString()).join('\n');
      this.setState({ textlines: cleanLines, errs: parsed.invalidContacts, numOk: parsed.validContacts.length });
      this.props.submit(parsed.validContacts);
    } else {
      this.setState({ errs: parsed.invalidContacts, numOk: parsed.validContacts.length });
    }
  }
}