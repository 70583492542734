/**
 * Contenu de AdminView en mode READY.
 */
import i18next from 'i18next';
import * as React from 'react';
import { Alert, Button } from 'react-bootstrap';
import { DateTime } from 'luxon';
import { DatesManager } from '../common/util/DatesManager';
import { getLogger } from '../common/util/pmlogger';
import { AppConfig } from '../app/AppConfig';
import { resultsUrl } from '../voteui/VoteUtils';

interface RVProps {
  scrutinId: string,
  sDisplayName: string,
  voteUrl: string,
  openDateMillis: number,
  closeDateMillis: number,
  lang: string,        // unused but dunno how to force update when it changes
  view: () => void,
  backtotest: () => void,
  refresh: () => void
}

export class ReadyView extends React.PureComponent<RVProps> {

  private static LOGTAG = "readyview";

  private backtotest = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    this.props.backtotest();
  }

  private view = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    this.props.view();
  }

  private refresh = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    this.props.refresh();
  }

  public render() {
    const props = this.props;
    getLogger().info(ReadyView.LOGTAG, "Rendering ReadyView for scrutin: %s", this.props.sDisplayName);
    const canGoTesting = (new Date().getTime() <= props.openDateMillis - AppConfig.MIN_TESTING_TO_OPEN_MS);
    const imminent = props.openDateMillis < new Date().getTime();
    const starter = (imminent ?
      i18next.t('readyview.imminent_start1') :
      i18next.t('readyview.future_start', { min: DatesManager.get().formatShortNumericDateTime(DateTime.fromMillis(props.openDateMillis)), max: DatesManager.get().formatShortNumericDateTime(DateTime.fromMillis(props.closeDateMillis)) }));
    const ender = (imminent ?
      i18next.t('readyview.imminent_start2', { max: DatesManager.get().formatShortNumericDateTime(DateTime.fromMillis(props.closeDateMillis)) }) :
      "");
    const realResultsLink = resultsUrl(props.scrutinId);
    return (
      <div className='container-fluid'>
        <div className='container'>
          <div className='div-section'>
            <h2><span className='strong-value'>{props.sDisplayName}</span></h2>
          </div>
          <Alert variant='primary'>
            <p style={{ fontWeight: 'bold' }}>{i18next.t('readyview.ready_1')}</p>
            <p>
              {starter}
              {imminent ? <>&nbsp;[<a href="/" onClick={this.refresh}>{i18next.t('readyview.refresh')}</a>]</> : null}
              {ender}
            </p>
            {canGoTesting ?
              <p>{i18next.t('readyview.back_to_test')}
                <a href="/" onClick={this.backtotest}>{i18next.t('readyview.back_to_test_link')}</a>
              </p>
              : null}
            <div className='test-modify'>
              <Button variant='primary' type='button' className='ed-button' onClick={this.view}>
                {i18next.t('readyview.view_scrutin')}
              </Button>
            </div>
          </Alert>
          <div className='div-section'>
            <div className='div-subsection'>
              {i18next.t('readyview.access_link')}:
              <div className='boxed'>
                <a href={props.voteUrl} target='_blank' rel='noopener noreferrer'>{props.voteUrl}</a>
              </div>
            </div>
            <div className='div-subsection'>
              {i18next.t('readyview.results_link')}:
              <div className='boxed'>
                <a href={realResultsLink} target='_blank' rel='noopener noreferrer'>{realResultsLink}</a>
              </div>
            </div>
          </div>

          <div style={{ padding: '10px', margin: '15px 0px', backgroundColor: '#EEEECC' }}>
            <h3>{i18next.t('summary.vote_info_title')}:</h3>
            <div>{i18next.t('readyview.vote_link')}</div>
            <div>{i18next.t('summary.vote_info1')}</div>
            <div>{i18next.t('summary.vote_info2')}</div>
            <div>{i18next.t('summary.vote_info4')}</div>
            <div>{i18next.t('summary.vote_info5')}</div>
          </div>

        </div>
      </div>
    );
  }

}