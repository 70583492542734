/**
 * Blocs éléments utilitaires de vues.
 */
import React from "react";
import { Spinner, Alert, Button } from "react-bootstrap";
import i18next from "i18next";



/** Affiche un Spinner, avec éventuellement un texte. */
export const LoadingBlock = (props: { msg?: string }) => (
  <div style={{ textAlign: 'center', width: '100%', height: '100%', padding: '20px' }}>
    <div style={{ padding: '30px' }}>
      <Spinner animation='border' role='status' variant='info' />
    </div>
    <div>
      {props.msg ? props.msg : null}
    </div>
  </div>
);

/** Affiche un bloc d'erreur, avec éventuellement un bouton de retour. */
export const ErrorBlock = (props: { msg: string, backAction?: () => void, backText?: string }) => (
  <Alert variant='danger'>
    <Alert.Heading>{i18next.t('utilviews.error')}</Alert.Heading>
    <p>{props.msg}</p>
    <div className='unsync-back'>
      {props.backAction ? <Button variant='danger' type='button' className='ed-button' onClick={props.backAction}>{props.backText || i18next.t('utilviews.back')}</Button> : null}
    </div>
  </Alert>
);
