/** Accueil - Point where one can Create a new Scrutin. */
import * as React from 'react';
import Button from 'react-bootstrap/Button';
import { RouteComponentProps } from 'react-router';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Card, CardDeck } from 'react-bootstrap';
import i18next from 'i18next';


export class Accueil extends React.Component<RouteComponentProps> {
  public render() {
    return (
      <Container>
        <h1>{i18next.t('accueil.title')}</h1>
        <p>{i18next.t('accueil.subtitle')}</p>
        <p> <small>{i18next.t('accueil.accroche')}</small></p>
        <Container>
          <Row>
            <Col>
              <Button variant='primary' size='lg' onClick={this.handleCreate} > {i18next.t('accueil.create_btn')} </Button>
              &nbsp;&nbsp;&nbsp;
              <Button variant='link' onClick={this.handleAbout}> {i18next.t('accueil.know_more')} </Button>
            </Col>
          </Row>
          <Row style={{ marginTop: '20px', marginBottom: '20px' }}>
            <Col>
              <CardDeck>
                <Card bg='light' border='primary'>
                  <Card.Body>
                    <Card.Title>{i18next.t('accueil.card_title1')}</Card.Title>
                    <Card.Text>{i18next.t('accueil.card_text1')}</Card.Text>
                  </Card.Body>
                </Card>
                <Card bg='light' border='primary'>
                  <Card.Body>
                    <Card.Title>{i18next.t('accueil.card_title2')}</Card.Title>
                    <Card.Text>{i18next.t('accueil.card_text2')}<br />{i18next.t('accueil.card_text2B')}</Card.Text>
                  </Card.Body>
                </Card>
                <Card bg='light' border='primary'>
                  <Card.Body>
                    <Card.Title>{i18next.t('accueil.card_title3')}</Card.Title>
                    <Card.Text>{i18next.t('accueil.card_text3')}</Card.Text>
                  </Card.Body>
                </Card>
              </CardDeck>
            </Col>
          </Row>
        </Container>
      </Container>
    );
  }

  private handleCreate = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    this.props.history.push('create');
  }
  private handleAbout = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    this.props.history.push('about');
  }
};

