/**
 * Autonomously manages the Mail Input field and the Validate Mail button.
 * Invokes as needed the VoteUiStore, that will submit the email for validation and update its internal VoterStatuses (emitting change events).
 */
import React from "react";
import { ErrCodes } from "../common/backend/ErrCodes";
import { Formatter } from "../common/util/Formatter";
import { MailBk, MailBkProps } from "./MailBk";
import './vote.css';
import { getVoteUiStore, VUIS_CHANGE } from "./VoteUiStore";


export interface MailBlockProps {
  /** Initial value of email, presets the email field. */
  initialMail: string;
}
interface MailBlockState {
  email: string;
  // From VoteUiStore:
  submittingMail: boolean;
  submittedEmail: string;
  isMailValidated: boolean;
  mailError: ErrCodes;
}

/**
 * Behaviour and Query Logic for the Mail Input and Validation block.
 */
export class MailBlock extends React.Component<MailBlockProps, MailBlockState> {

  constructor(props: MailBlockProps) {
    super(props);
    const vstore = getVoteUiStore();
    this.state = {
      email: props.initialMail,
      submittingMail: vstore.isSubmittingMail(),
      submittedEmail: vstore.getSubmittedEmail(),
      isMailValidated: vstore.isMailValidated(),
      mailError: vstore.getMailError()
    }
  }

  public componentDidMount() {
    getVoteUiStore().on(VUIS_CHANGE, this.updateState);
  }

  private updateState = () => {
    const vstore = getVoteUiStore();
    this.setState({
      submittingMail: vstore.isSubmittingMail(),
      submittedEmail: vstore.getSubmittedEmail(),
      isMailValidated: vstore.isMailValidated(),
      mailError: vstore.getMailError()
    });
  }

  public componentWillUnmount() {
    getVoteUiStore().off(VUIS_CHANGE, this.updateState);
  }

  private onMailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const val = e.currentTarget.value as string;
    this.setState({ email: val });
  }

  private onClear = () => {
    getVoteUiStore().clearEmail();
    this.setState({ email: "" });
  }

  private onValidate = () => {
    // console.log("OnValidate, guis: %o", getVoteUiStore());
    getVoteUiStore().submitEmail(this.state.email);
  }

  public render() {
    const s = this.state;
    const mbProps: MailBkProps = {
      email: this.state.email,
      isMail: Formatter.get().isValidEmail(this.state.email),
      submittingMail: s.submittingMail,
      isMailValidated: s.isMailValidated,
      unknownVoterMail: (s.submittedEmail === this.state.email && s.mailError === ErrCodes.UNKNOWN_VOTER_MAIL),
      onMailChange: this.onMailChange,
      onValidate: this.onValidate,
      onClear: this.onClear
    };
    return <MailBk {...mbProps} />
  }
}
