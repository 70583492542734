import React from "react";
import { SyncObject, SyncStatus } from "../common/backend/SyncObject";
import { Spinner, Alert, Button } from "react-bootstrap";
import { errMessage } from "../common/tsmodel/Converters";
import i18next from "i18next";
import { getLogger } from "../common/util/pmlogger";

/**
 * Affiche Spinner ou Message d'erreur pour les cas où le SyncObject n'est pas chargé.
 */

interface UsProps {
  sync: SyncObject<any>,
  back?: () => void,
  backButton?: string
}

const LOGTAG = "unsync";

export const UnSync = (props: UsProps) => {
  if (props.sync.sync === SyncStatus.SYNCHRONIZING) {
    getLogger().debug(LOGTAG, "Unsync shows for Synchronizing state.");
    return (
      <div style={{ textAlign: 'center', width: '100%', height: '100%', padding: '20px' }}>
        <div style={{ padding: '30px' }}>
          <Spinner animation='border' role='status' variant='info' />
        </div>
        <div>
          {i18next.t('unsync.loading')}
        </div>
      </div>);
  } else if (props.sync.sync === SyncStatus.SYNC_ERROR) {
    getLogger().debug(LOGTAG, "Unsync shows for Error state.");
    const msg = errMessage(props.sync.getErrCode());
    const backButton = props.backButton || i18next.t('unsync.go_back');
    const button = props.back ? <Button variant='danger' type='button' className='ed-button' onClick={props.back}>{backButton}</Button>
      : <Button variant='danger' type='button' className='ed-button' href="/">{backButton}</Button>
    return (
      <Alert variant='danger'>
        <Alert.Heading>{i18next.t('unsync.error')}</Alert.Heading>
        <p>{msg}</p>
        <div className='unsync-back'>
          {button}
        </div>
      </Alert>
    )
  }
  getLogger().warn(LOGTAG, "UnSync is not expected to be used in this state: %o", props.sync);
  return null;
}