import { FromTsBody } from "../../common/tsmodel/TsQueries";
import { SStatus, TsModelDefs, VotersVisibility } from "../../common/tsmodel/TsModelDefs";
import { TsParsedContact } from "../../common/tsmodel/TsParsedContact";
import { TsVooScrutin } from "../../common/tsmodel/TsVooScrutin";
import { TsScrutinDataResponse } from "../../common/tsmodel/TsResponses";

/**
 * Bean exchanged when Creating or Updating a Scrutin. The changes in Status are
 * ignored, and handled by a specific API.
 * 
 * Equivalent definition in Java. Keep them synchronized.
 */
export class TsCreateUpdateScrutin extends FromTsBody implements TsVooScrutin, ScrutinBean, CandidatesBean {
  /** MAC for Scrutin Admin (main id for a Scrutin in ON_CLIENT | TESTING | READY) -- allocated by server.  */
  mac: string = TsModelDefs.LOCAL_MAC;
  /** ScrutinId for users (main id for a Scrutin in ACTIVE | FINISHED) -- allocated by server. */
  scrutinId: string = "";
  /** Editable sauf si ACTIVE | FINISHED */
  sStatus: SStatus = 'ON_CLIENT';
  /** Display Name of the Scrutin. */
  sDisplayName: string = "";
  /**
   * Display Name of the Organizer of the Scrutin (used in Templates and mails).
   */
  sOrganizerName: string = "";
  /** Email of the Organizer - Validates the Sending of mails. */
  sOrganizerEmail: string = "";
  /** Si les votants sont visibles durant le scrutin. */
  votersVisibility: VotersVisibility = 'DISPLAY_NAME';
  /** Nombre de Candidats sélectionnables par Electeur. */
  nVotes: number = 1;
  /** Liste des Electeurs */
  voters: TsParsedContact[] = [];
  /** Liste des Candidats */
  candidates: string[] = [];
  /** Open Date, millis from 1/1/1970 0:00 UTC. 0 = NotSet.*/
  openDateMillis: number = TsModelDefs.defaultOpenDate().getTime();
  /** Close Date, millis from 1/1/1970 0:00 UTC. 0 = NotSet. */
  closeDateMillis: number = TsModelDefs.defaultCloseDate().getTime();
  /** Close earlier, if all Voters have voted. */
  closeOnAllVoted: boolean = true;
  /** Organizer Language , 'EN' or 'FR' */
  supportedLang: 'EN' | 'FR' = 'FR';
  /** Organizer Timezone. */
  orgaTimezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;

  /** Returned in TsScrutinDataResponse, reinjected in TsCreateUpdateScrutin to have a single class to manage in AppStore. */
  tsVoteUrl?: string;
}

export interface ScrutinBean {
  sDisplayName: string,
  sOrganizerName: string,
  sOrganizerEmail: string,
  openDateMillis: number,
  closeDateMillis: number,
  closeOnAllVoted: boolean
}

export interface CandidatesBean {
  candidates: string[],
  nVotes: number
}

/** The returned TsCreateUpdateScrutin deep clones the response, to allow keeping the response. */
export const updateCusWithResponse = (resp: TsScrutinDataResponse, cus?: TsCreateUpdateScrutin): TsCreateUpdateScrutin => {
  cus = cus || new TsCreateUpdateScrutin();
  cus.mac = resp.mac;
  cus.scrutinId = resp.scrutinId;
  cus.sStatus = resp.sStatus;
  cus.sDisplayName = resp.sDisplayName;
  cus.sOrganizerName = resp.sOrganizerName;
  cus.sOrganizerEmail = resp.sOrganizerEmail;
  cus.votersVisibility = resp.votersVisibility;
  cus.nVotes = resp.nVotes;
  cus.voters = resp.voters.map(cloneParsedContact);
  cus.candidates = [...resp.candidates];
  cus.openDateMillis = resp.openDateMillis;
  cus.closeDateMillis = resp.closeDateMillis;
  cus.closeOnAllVoted = resp.closeOnAllVoted;
  cus.tsVoteUrl = resp.tsVoteUrl;
  return cus;
}

const cloneParsedContact = (pc: TsParsedContact): TsParsedContact => {
  const clone = new TsParsedContact();
  clone.displayName = pc.displayName;
  clone.emails = [...pc.emails];
  return clone;
}

/** @returns true if an attribute changed betwen the Reference and the current TsCreateUpdateScrutin. */
export const hasChanged = (cus: TsCreateUpdateScrutin, serverState?: TsScrutinDataResponse) => {
  // If no serverState, compare with a clean default TsCreateUpdateScrutin
  const ref = serverState ? serverState : new TsCreateUpdateScrutin();
  if (cus.mac !== ref.mac ||
    cus.scrutinId !== ref.scrutinId ||
    cus.sStatus !== ref.sStatus ||
    cus.sDisplayName !== ref.sDisplayName ||
    cus.sOrganizerName !== ref.sOrganizerName ||
    cus.sOrganizerEmail !== ref.sOrganizerEmail ||
    cus.votersVisibility !== ref.votersVisibility ||
    cus.nVotes !== ref.nVotes ||
    cus.openDateMillis !== ref.openDateMillis ||
    cus.closeDateMillis !== ref.closeDateMillis ||
    cus.closeOnAllVoted !== ref.closeOnAllVoted ||
    cus.tsVoteUrl !== ref.tsVoteUrl ||
    cus.voters.length !== ref.voters.length ||
    cus.candidates.length !== ref.candidates.length) {
    return true;
  }
  for (let i = 0; i < cus.voters.length; i++) {
    if (cus.voters[i].displayName !== ref.voters[i].displayName || cus.voters[i].emails.length !== ref.voters[i].emails.length) {
      return true;
    }
    for (let j = 0; j < cus.voters[i].emails.length; j++) {
      if (cus.voters[i].emails[j] !== ref.voters[i].emails[j]) {
        return true;
      }
    }
  }
  for (let i = 0; i < cus.candidates.length; i++) {
    if (cus.candidates[i] !== ref.candidates[i]) {
      return true;
    }
  }
  return false;
}
