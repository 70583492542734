/**
 * Contenu de AdminView en mode ACTIVE.
 */
import i18next from 'i18next';
import * as React from 'react';
import { DatesManager } from '../common/util/DatesManager';
import { getLogger } from '../common/util/pmlogger';
import { Alert, Button } from 'react-bootstrap';
import { DateTime } from 'luxon';
import { resultsUrl } from '../voteui/VoteUtils';
import { TsAuditResponse } from '../common/tsmodel/TsResponses';
import { AuditView } from './AuditView';
import { LoadingBlock } from './UtilViews';

interface RVProps {
  scrutinId: string,
  sDisplayName: string,
  voteUrl: string,
  closeDateMillis: number,
  loadingAudit: boolean,
  audit?: TsAuditResponse,
  lang: string,        // unused but dunno how to force update when it changes
  view: () => void,
  getAudit: () => void,
}

export class ActiveView extends React.PureComponent<RVProps> {

  private static LOGTAG = "activeview";

  private view = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    this.props.view();
  }

  private getAudit = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    this.props.getAudit();
  }

  public render() {
    const props = this.props;
    getLogger().info(ActiveView.LOGTAG, "Rendering ActiveView for scrutin: %s", this.props.sDisplayName);
    const endDateText = i18next.t('activeview.end_date', { max: DatesManager.get().formatShortNumericDateTime(DateTime.fromMillis(props.closeDateMillis)) });
    const realResultsLink = resultsUrl(props.scrutinId);
    return (
      <div className='container-fluid'>
        <div className='container'>
          <div className='div-section'>
            <h2><span className='strong-value'>{props.sDisplayName}</span></h2>
          </div>
          <Alert variant='success'>
            <p style={{ fontWeight: 'bold' }}>{i18next.t('activeview.active_1')}</p>
            <p>{endDateText}</p>
            <p>{i18next.t('activeview.no_modif')}</p>
            <div className='test-modify'>
              <Button variant='success' type='button' className='ed-button' onClick={this.view}>
                {i18next.t('activeview.view_scrutin')}
              </Button>
            </div>
          </Alert>
          <div className='div-section'>
            <div className='div-subsection'>
              {i18next.t('activeview.access_link')}
              <div className='boxed'>
                <a href={props.voteUrl} target='_blank' rel='noopener noreferrer'>{props.voteUrl}</a>
              </div>
            </div>
            <div className='div-subsection'>
              {i18next.t('activeview.results_link')}
              <div className='boxed'>
                <a href={realResultsLink} target='_blank' rel='noopener noreferrer'>{realResultsLink}</a>
              </div>
            </div>
            <div className='div-subsection'>
              {i18next.t('activeview.audit_link')}
              <Button variant='link' type='button' style={{ verticalAlign: 'baseline' }} onClick={this.getAudit}>
                {i18next.t('activeview.get_audit')}
              </Button>
            </div>
            {this.props.loadingAudit ?
              <LoadingBlock msg={i18next.t('finishedview.loading_audit')} /> : null}
            {((!this.props.loadingAudit) && this.props.audit) ?
              <AuditView audit={this.props.audit} /> : null
            }

            <div className='div-subsection'>
              {i18next.t('activeview.support_link')}: support@vootation.com
            </div>
          </div>

          <div style={{ padding: '10px', margin: '15px 0px', backgroundColor: '#EEEECC' }}>
            <h3>{i18next.t('summary.vote_info_title')}:</h3>
            <div>{i18next.t('activeview.vote_link')}</div>
            <div>{i18next.t('summary.vote_info1')}</div>
            <div>{i18next.t('summary.vote_info2')}</div>
            <div>{i18next.t('summary.vote_info4')}</div>
            <div>{i18next.t('summary.vote_info5')}</div>
          </div>

        </div>
      </div >
    );
  }

}