/**
 * Manages the TsScrutinViewResponse and the TsVoterStatus, with auto-refresh especially for the Sent Message part.
 */

import { EventEmitter } from "events";
import { ErrCodes } from "../common/backend/ErrCodes";
import { TsCodesResponse, TsOneVoter, TsScrutinViewResponse, TsVoteResponse, TsVoterStatuses } from "../common/tsmodel/TsResponses";
import { getVoteApi } from "../backend/VoteApi";
import { getLogger } from "../common/util/pmlogger";
import { isOneMailStatusPending, isPollingEnd, updateVoterMessageStatus, voterById, voterCodeById } from "./VoteUtils";
import i18next from "i18next";


export interface VoteUiStore extends EventEmitter {
  getMac: () => string | undefined;
  // loadScrutin action - Loading Scrutin with scrutinId/[mac]
  isLoadingScrutin: () => boolean;
  getScrutin: () => TsScrutinViewResponse | undefined;
  getScrutinError: () => ErrCodes;
  // submitEmail action - Loading VoterStatuses with Voter Mail
  getSubmittedEmail: () => string;
  isSubmittingMail: () => boolean;
  getVoterStatuses: () => TsVoterStatuses | undefined;
  isMailValidated: () => boolean;
  getMailError: () => ErrCodes;
  // sendSecret action - Sending code by email
  getSendSecretStatus: () => SendSecretStatus;
  // validateSecret action - Checking the mail/code
  getValidateSecretStatus: () => ValidateSecretStatus;
  getVoteStatuses: () => VoteStatuses;

  isCommError: () => boolean;
  // Actions
  loadScrutin: (scrutinId?: string, mac?: string) => void;
  submitEmail: (email: string) => void;
  clearEmail: () => void;
  sendSecret: () => void;
  validateSecret: (secret: string) => void;
  doVote: (voterId: string, choices: string[]) => void;
}

/** Events sent by this EventEmitter */
export const VUIS_CHANGE = "VUISChange";

/** SendSecret action */
export interface SendSecretStatus {
  email: string;
  voter?: TsOneVoter;
  sendingSecret: boolean;
  sendingSecretError: ErrCodes;
}

/** ValidateSecret action */
export interface ValidateSecretStatus {
  submittedSecret: string;
  submittingSecret: boolean;
  secretValidated: boolean;
  secretError: ErrCodes;
}

export interface VoteStatus {
  voting: boolean;
  voteError: ErrCodes;
}
export interface VoteStatuses { [voterId: string]: VoteStatus };

/** Exported for test and stories */
export class VoteUiStoreImpl extends EventEmitter implements VoteUiStore {

  private static LOGTAG = "vsstore";

  /** If true, the Code is sent by email immediately after validation of the email. */
  private static SEND_CODE_AT_MAIL_VALIDATED = true;

  private scrutinId: string = "";
  private mac?: string;

  // Loading Scrutin:
  private loadingScrutin = false;
  private scrutin?: TsScrutinViewResponse;
  /** Errors while loading Scrutin. All is stopped in case of a Server or Comm Error */
  private scrutinError = ErrCodes.OK;

  /** Mail submitted for Validation, or Validated. */
  private submittedMail: string = "";
  private submittingMail = false;
  /** Errors while Submitting Mail. */
  private mailError = ErrCodes.OK;
  private voterStatuses?: TsVoterStatuses;

  /** Status of Sending Secret */
  private static EmptySendSecretStatus = {
    email: "",
    sendingSecret: false,
    sendingSecretError: ErrCodes.OK
  };
  private sendSecretStatus: SendSecretStatus = VoteUiStoreImpl.EmptySendSecretStatus;

  /** Status of Validating Secret */
  private static EmptyValidateSecretStatus = {
    submittedSecret: "",
    submittingSecret: false,
    secretValidated: false,
    secretError: ErrCodes.OK
  };
  private validateSecretStatus: ValidateSecretStatus = VoteUiStoreImpl.EmptyValidateSecretStatus;
  private codeResponses?: TsCodesResponse;

  /** Status of Vote Actions */
  private voteStatuses: VoteStatuses = {};

  /** Time interval for Polling sent message status (20s) */
  private static POLL_INTERVAL_MS = 10000;
  /** After this delay, stop polling even if the message status is not final. */
  private static MAX_POLL_DATE = 60000;    // 1 mn
  private timer: any;
  private polling = false;

  public getMac = () => (this.mac);
  public isLoadingScrutin = () => (this.loadingScrutin);
  public getScrutin = () => (this.scrutin);
  public getScrutinError = () => (this.scrutinError);
  public getSubmittedEmail = () => (this.submittedMail);
  public isSubmittingMail = () => (this.submittingMail);
  public getVoterStatuses = () => (this.voterStatuses);
  public isMailValidated = () => (this.voterStatuses !== undefined);
  public getMailError = () => (this.mailError);
  public getSendSecretStatus = () => (this.sendSecretStatus);
  public getValidateSecretStatus = () => (this.validateSecretStatus);
  public getVoteStatuses = () => (this.voteStatuses);

  /** Non utilisé - code inclus dans InfoBandeau. */
  public isCommError = () => {
    if (this.scrutinError in [ErrCodes.HTTP_UNKNOWN, ErrCodes.HTTP_TIMEOUT, ErrCodes.HTTP_SERVER_ERROR, ErrCodes.HTTP_CLIENT_ERROR]) {
      return true;
    }
    return false;
  }

  public loadScrutin = (scrutinId?: string, mac?: string): void => {
    getLogger().info(VoteUiStoreImpl.LOGTAG, "Loading Scrutin %s %s", scrutinId, mac || "");
    if ((!scrutinId) || scrutinId === "") {
      getLogger().warn(VoteUiStoreImpl.LOGTAG, "Missing scrutinId in URL - cannot load scrutin.");
      this.loadingScrutin = false;
      this.scrutin = undefined;
      this.scrutinError = ErrCodes.SCRUTIN_NOT_FOUND;
      this.submittedMail = "";
      this.submittingMail = false;
      this.voterStatuses = undefined;
      this.mailError = ErrCodes.OK;
      this.sendSecretStatus = VoteUiStoreImpl.EmptySendSecretStatus;
      this.validateSecretStatus = VoteUiStoreImpl.EmptyValidateSecretStatus;
      this.emit(VUIS_CHANGE);
      return;
    }
    if (scrutinId !== this.scrutinId || mac !== this.mac) {
      // Reset all
      this.stopPolling();
      this.scrutinId = scrutinId;
      this.mac = mac;
      this.loadingScrutin = true;
      this.scrutin = undefined;
      this.scrutinError = ErrCodes.OK;
      this.submittedMail = "";
      this.submittingMail = false;
      this.voterStatuses = undefined;
      this.mailError = ErrCodes.OK;
      this.sendSecretStatus = VoteUiStoreImpl.EmptySendSecretStatus;
      this.validateSecretStatus = VoteUiStoreImpl.EmptyValidateSecretStatus;
      this.emit(VUIS_CHANGE);
      getLogger().info(VoteUiStoreImpl.LOGTAG, "Fetching Scrutin for %s (%s)", scrutinId, mac);
      getVoteApi().viewScrutin(scrutinId, mac).then(tsvr => {
        // TsScrutinViewResponse
        this.loadingScrutin = false;
        this.scrutin = tsvr;
        this.scrutinError = ErrCodes.OK;
        this.emit(VUIS_CHANGE);
      }).catch(errcode => {
        this.loadingScrutin = false;
        this.scrutin = undefined;
        this.scrutinError = errcode;
        this.emit(VUIS_CHANGE);
      })
    }
  }

  /** Used by MailBlock. The final result will be the VoterStatuses. */
  public submitEmail = (email: string): void => {
    getLogger().debug(VoteUiStoreImpl.LOGTAG, "Submitting Email: %s, scrutin:%o", email, this.scrutin);
    if ((this.scrutin) && email !== this.submittedMail) {
      this.stopPolling();
      this.submittedMail = email;
      this.submittingMail = true;
      this.emit(VUIS_CHANGE);
      getVoteApi().voterStatuses(this.scrutinId, this.submittedMail, this.mac).then(tsvStatuses => {
        if (tsvStatuses.scrutinId !== this.scrutinId || tsvStatuses.email !== this.submittedMail) {
          // Ignore the response, as Scrutin or Voter Mail changed
          getLogger().info(VoteUiStoreImpl.LOGTAG, "Ignoring voterStatuses response: changed values sid %s vs %s, mail %s vs %s", tsvStatuses.scrutinId, this.scrutinId, tsvStatuses.email, this.submittedMail);
          return;
        }
        // TsScrutinViewResponse
        this.submittingMail = false;
        const firstTimeSendSecret: boolean = (this.voterStatuses == undefined) && (tsvStatuses != undefined);
        this.voterStatuses = tsvStatuses;
        this.mailError = ErrCodes.OK;
        this.emit(VUIS_CHANGE);
        if (VoteUiStoreImpl.SEND_CODE_AT_MAIL_VALIDATED && firstTimeSendSecret) {
          setTimeout(this.sendSecret, 10);
        } else {
          if (isOneMailStatusPending(tsvStatuses)) {
            // Refresh periodically the Send Message Status.
            this.startPolling();
          }
        }
      }).catch(errcode => {
        this.submittingMail = false;
        this.mailError = errcode;
        this.emit(VUIS_CHANGE);
      })
    }
  }

  public clearEmail = (): void => {
    getLogger().debug(VoteUiStoreImpl.LOGTAG, "Clearing Email and stopping polling.");
    this.stopPolling();
    this.submittingMail = false;
    this.submittedMail = "";
    this.mailError = ErrCodes.OK;
    this.voterStatuses = undefined;
    this.sendSecretStatus = VoteUiStoreImpl.EmptySendSecretStatus;
    this.validateSecretStatus = VoteUiStoreImpl.EmptyValidateSecretStatus;
    this.emit(VUIS_CHANGE);
  }

  /** No more per VoterId. To avoid server-side change, use the first Voter. */
  public sendSecret = (): void => {
    if (this.scrutin && this.submittedMail !== "" && this.voterStatuses) {
      this.stopPolling();
      const voter = this.voterStatuses.voters[0];
      const voterId = this.voterStatuses.voters[0].voterId;
      this.sendSecretStatus = {
        email: this.submittedMail,
        voter,
        sendingSecret: true,
        sendingSecretError: ErrCodes.OK
      };
      this.emit(VUIS_CHANGE);
      getLogger().debug(VoteUiStoreImpl.LOGTAG, "Sending Secret for %s in %s (%s) - %s", voterId, this.scrutinId, this.mac, this.submittedMail);
      const lang = i18next.language.toLowerCase().startsWith("fr") ? 'FR' : 'EN';
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      getVoteApi().sendSecret(this.scrutinId, voterId, this.submittedMail, lang, timezone, this.mac).then(voterStatus => {
        if (voterStatus.scrutinId !== this.scrutinId || voterStatus.email !== this.submittedMail) {
          // Ignore the response, as Scrutin or Voter Mail changed
          getLogger().info(VoteUiStoreImpl.LOGTAG, "Ignoring sendSecret response: changed values sid %s vs %s, mail %s vs %s", voterStatus.scrutinId, this.scrutinId, voterStatus.email, this.submittedMail);
          return;
        }
        this.sendSecretStatus = { email: this.submittedMail, voter, sendingSecret: false, sendingSecretError: ErrCodes.OK };
        // Update the corresponding TsOneVoter in the global VoterStatuses.
        updateVoterMessageStatus(voter, voterStatus);
        this.emit(VUIS_CHANGE);
        if (voterStatus.sentMessage && (!voterStatus.sentMessage.statusFinal)) {
          // Refresh periodically the Send Message Status.
          this.startPolling();
        }
      }).catch(errcode => {
        this.sendSecretStatus = { email: this.submittedMail, voter, sendingSecret: false, sendingSecretError: errcode };
        this.emit(VUIS_CHANGE);
        if (isOneMailStatusPending(this.voterStatuses)) {
          // Refresh periodically the Send Message Status.
          this.startPolling();
        }
      })
    }
  }

  /** Verify the Secret. Results in update of ValidateSecretStatus and codesResponses */
  public validateSecret = (secret: string): void => {
    if (this.scrutin && this.submittedMail !== "" && this.mailError === ErrCodes.OK) {
      this.stopPolling();
      this.validateSecretStatus = {
        submittedSecret: secret,
        submittingSecret: true,
        secretValidated: false,
        secretError: ErrCodes.OK
      };
      this.codeResponses = undefined;
      this.emit(VUIS_CHANGE);
      getLogger().debug(VoteUiStoreImpl.LOGTAG, "Validating Secret for %s (%s) - %s", this.scrutinId, this.mac, this.submittedMail);
      getVoteApi().validateSecret(this.scrutinId, this.submittedMail, secret, this.mac).then(codesResponses => {
        if (codesResponses.scrutinId !== this.scrutinId || codesResponses.email !== this.submittedMail) {
          // Ignore the response, as Scrutin or Voter Mail changed
          getLogger().info(VoteUiStoreImpl.LOGTAG, "Ignoring validateSecret response: changed values sid %s vs %s, mail %s vs %s", codesResponses.scrutinId, this.scrutinId, codesResponses.email, this.submittedMail);
          return;
        }
        this.validateSecretStatus = {
          submittedSecret: secret,
          submittingSecret: false,
          secretValidated: true,
          secretError: ErrCodes.OK
        };
        this.codeResponses = codesResponses;
        this.emit(VUIS_CHANGE);
        // No need to restart polling
      }).catch(errcode => {
        this.validateSecretStatus = {
          submittedSecret: secret,
          submittingSecret: false,
          secretValidated: false,
          secretError: errcode
        };
        this.emit(VUIS_CHANGE);
        if (isOneMailStatusPending(this.voterStatuses)) {
          // Refresh periodically the Send Message Status.
          this.startPolling();
        }
      })
    }
  }

  public doVote = (voterId: string, choices: string[]): void => {
    if (this.scrutin && choices.length <= this.scrutin.nVotes && this.voterStatuses && this.voterStatuses.voters && this.codeResponses) {
      const voter = voterById(this.voterStatuses.voters, voterId);
      const voterCode = voterCodeById(this.codeResponses, voterId);
      if ((!voter) || (!voterCode)) {
        getLogger().error(VoteUiStoreImpl.LOGTAG, "Not found Voter or VoterCode by Id %s in %o, %o", voterId, this.voterStatuses.voters, this.codeResponses);
        return;
      }
      this.stopPolling();
      this.voteStatuses[voterId] = {
        voting: true,
        voteError: ErrCodes.OK
      }
      this.emit(VUIS_CHANGE);
      getLogger().debug(VoteUiStoreImpl.LOGTAG, "Voting for %s (%s) - %s", this.scrutinId, this.mac, voterId);
      const ret: Promise<TsVoteResponse> = this.scrutin.sStatus === 'ACTIVE' ?
        getVoteApi().realVote(this.scrutin.scrutinId, voter.voterId, this.submittedMail, voterCode, choices) :
        getVoteApi().testVote(this.scrutin.scrutinId, voter.voterId, this.submittedMail, voterCode, choices, getVoteUiStore().getMac() as string);
      ret.then(vresp => {
        if (vresp.scrutinId !== this.scrutinId || vresp.voterMail !== this.submittedMail || vresp.voterId !== voterId) {
          // Ignore the response, as Scrutin or Voter Mail changed
          getLogger().info(VoteUiStoreImpl.LOGTAG, "Ignoring Vote response: changed values sid %s vs %s, mail %s vs %s, vid %s", vresp.scrutinId, this.scrutinId, vresp.voterMail, this.submittedMail, vresp.voterId);
          return;
        }
        this.voteStatuses[voterId] = {
          voting: false,
          voteError: ErrCodes.OK
        }
        // Update the Voter
        voter.hasVoted = true;
        voter.votedTime = vresp.dateMillis;
        this.emit(VUIS_CHANGE);
        // No need to restart Polling
      }).catch(errcode => {
        getLogger().warn(VoteUiStoreImpl.LOGTAG, "Error Vote: %o", errcode);
        this.voteStatuses[voterId] = {
          voting: false,
          voteError: errcode
        }
        this.emit(VUIS_CHANGE);
        // No need to restart Polling
      })
    }
  }

  private pollStatus = (): void => {
    getLogger().debug(VoteUiStoreImpl.LOGTAG, "Polling Status for %s (%s) - %s", this.scrutinId, this.mac, this.submittedMail);
    getVoteApi().voterStatuses(this.scrutinId, this.submittedMail, this.mac).then(tsvStatuses => {
      if (tsvStatuses.scrutinId !== this.scrutinId || tsvStatuses.email !== this.submittedMail) {
        // Ignore the response, as Scrutin or Voter Mail changed
        getLogger().info(VoteUiStoreImpl.LOGTAG, "Ignoring voterStatuses response: changed values sid %s vs %s, mail %s vs %s", tsvStatuses.scrutinId, this.scrutinId, tsvStatuses.email, this.submittedMail);
        return;
      }
      this.voterStatuses = tsvStatuses;
      this.emit(VUIS_CHANGE);
      if (isPollingEnd(VoteUiStoreImpl.MAX_POLL_DATE, tsvStatuses)) {
        // Stop polling if HasVoted, or StatusFinal, or more than 10mn since message sent.
        getLogger().info(VoteUiStoreImpl.LOGTAG, "Stopping voterStatus polling: end conditions are met.");
        this.stopPolling();
      }
    }).catch(errcode => {
      // do nothing on error
      getLogger().warn(VoteUiStoreImpl.LOGTAG, "VoterStatus error: %o", errcode);
    }).finally(this.nextPolling);
  }

  /** 
   * The Polling will have a fixed delay of POLL_INTERVAL_MS between the processing of a response and the sending
   * of the next query, hence this implementation based on setTimeout rather than setInterval.
   */
  private startPolling = () => {
    this.stopPolling();
    this.polling = true;
    this.timer = setTimeout(this.pollStatus, VoteUiStoreImpl.POLL_INTERVAL_MS);
  }
  private nextPolling = () => {
    if (this.polling) {
      this.timer = setTimeout(this.pollStatus, VoteUiStoreImpl.POLL_INTERVAL_MS);
    }
  }
  private stopPolling = () => {
    if (this.polling) {
      clearTimeout(this.timer);
      this.polling = false;
    }
  }

  /** Methods for Testing & Stories, not exposed on VoteUiStore interface */
  public setScrutin = (scrutin: TsScrutinViewResponse, mac?: string) => {
    this.scrutinId = scrutin.scrutinId;
    this.mac = mac;
    this.loadingScrutin = false;
    this.scrutin = scrutin;
    this.scrutinError = ErrCodes.OK;
    this.submittedMail = "";
    this.submittingMail = false;
    this.voterStatuses = undefined;
    this.sendSecretStatus = VoteUiStoreImpl.EmptySendSecretStatus;
    this.validateSecretStatus = VoteUiStoreImpl.EmptyValidateSecretStatus;
  }
}

let voteUiStore: VoteUiStore = new VoteUiStoreImpl();

export const getVoteUiStore = () => (voteUiStore);
export const setVoteUiStore = (newStore: VoteUiStore) => { voteUiStore = newStore };
