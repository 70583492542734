/**
 * Modal for Candidates selection. Vote Button.
 * The Vote process itself is managed internally, as its results are transient 
 * and not aimed at being available after the vote execution.
 * Other state data is managed by the {@link VoteUiStore}.
 */
import i18next from "i18next";
import React from "react";
import { ErrCodes } from "../common/backend/ErrCodes";
import { TsCandidate, TsScrutinViewResponse } from "../common/tsmodel/TsResponses";
import './vote.css';
import { CandidatesBk } from "./CandidatesBk";
import { getVoteUiStore } from "./VoteUiStore";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { DatesManager } from "../common/util/DatesManager";
import { DateTime } from "luxon";
import { errMessage } from "../common/tsmodel/Converters";

export interface VoteBlockProps {
  scrutin: TsScrutinViewResponse;
  // Voter attributes
  voterId: string;
  voterMail: string;
  displayName: string;
  hasVoted: boolean;
  votedTime?: number;

  voting: boolean;
  voteError: ErrCodes;
}
interface VoteBlockState {
  showModal: boolean;
  choices: string[];
}

/**
 * Behaviour and Query Logic for the Vote itself (candidate selection and vote / vote response)
 */
export class VoteBlock extends React.Component<VoteBlockProps, VoteBlockState> {

  constructor(props: VoteBlockProps) {
    super(props);
    this.state = {
      showModal: false,
      choices: []
    }
  }

  private onShowModal = () => {
    this.setState({ showModal: true });
  }
  private onHideModal = () => {
    this.setState({ showModal: false });
  }

  private onCandSelected = (choices: string[]): void => {
    this.setState({ choices });
  }

  private onDoVote = (): void => {
    this.setState({ showModal: false });
    getVoteUiStore().doVote(this.props.voterId, this.state.choices);
  }

  public render() {
    const s: TsScrutinViewResponse = this.props.scrutin;
    // const isLenOk = Formatter.get().isGoodLength(this.state.code, s.secretCodeLen);
    // const choicesOk = this.state.choices.length <= s.nVotes;
    return <>
      <VoteControl {...this.props} onShowModal={this.onShowModal} />
      <CandidateSelector show={this.state.showModal} onHideModal={this.onHideModal}
        candidates={s.candidates} choices={this.state.choices} nVotes={s.nVotes}
        onCandSelected={this.onCandSelected} onDoVote={this.onDoVote} />
    </>
  }
}

interface VCProps {
  voterMail: string;
  displayName: string;
  hasVoted: boolean;
  votedTime?: number;
  voting: boolean;
  voteError: ErrCodes;
  onShowModal: () => void;
}
function VoteControl(props: VCProps) {
  if (props.hasVoted) {
    return (<Row className='voterrow'>
      <Col>
        <span style={{ fontWeight: 'bold' }}>{props.displayName}</span> &lt;{props.voterMail}&gt; : {i18next.t('votepage.hasvotedat') + " " + DatesManager.get().formatMedNumericDateTime(DateTime.fromMillis(props.votedTime as number))}
      </Col>
    </Row>);
  }
  const errfeedback = props.voteError !== ErrCodes.OK ? errMessage(props.voteError) : undefined;
  return (<>
    <Row className='voterrow'>
      <Col>
        {props.displayName} &lt;{props.voterMail}&gt;
      </Col>
      <Col>
        <Button variant="primary" block onClick={props.onShowModal} style={{ marginRight: '20px' }} >
          {i18next.t('votepage.candselect.show_btn')}
        </Button>
      </Col>
    </Row>
    {errfeedback ?
      <div className='errfeedback'>
        {errfeedback}
      </div> : null}
  </>);
}

interface CSProps {
  show: boolean;
  onHideModal: () => void;
  candidates: TsCandidate[];
  nVotes: number;
  choices: string[];
  onCandSelected: (choices: string[]) => void;
  onDoVote: () => void;
}
/** Modal for selecting one (or several) candidates to express a Vote. */
function CandidateSelector(props: CSProps) {

  return (
    <Modal show={props.show} onHide={props.onHideModal}>
      <Modal.Header closeButton>
        <Modal.Title>{i18next.t('votepage.candselect.title', { count: props.nVotes })}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CandidatesBk {...props} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHideModal}>
          {i18next.t('votepage.candselect.cancel_btn')}
        </Button>
        <Button variant="primary" onClick={props.onDoVote} disabled={props.choices.length > props.nVotes}>
          {i18next.t('votepage.candselect.vote_btn')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

