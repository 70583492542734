/**
 * Low Level API to the Backend microservice API (new Reactive mode).
 */

import { getHttpLayer } from "../common/backend/HttpLayer";
import { TsCreateUpdateScrutin } from "./tsmodel/TsCreateUpdateScrutin";
import { TsMacSid, TsPingBody, TsSentMailId } from "../common/tsmodel/TsQueries";
import { TsAuditResponse, TsPingResponse, TsScrutinDataResponse, TsScrutinViewResponse, TsSentMessageResponse } from "../common/tsmodel/TsResponses";

export class VooApi {

  /** Allows to check that some data volume passes back and forth. */
  public vooPingPost = (data: string): Promise<TsPingResponse> => {
    const body = new TsPingBody(data);
    return getHttpLayer().HttpPostPromise<TsPingBody, TsPingResponse>("/voopingpost", body);
  }

  /** Creates a new Scrutin, that will be in TESTING state. */
  public createScrutin = (scrutin: TsCreateUpdateScrutin): Promise<TsScrutinDataResponse> => {
    return getHttpLayer().HttpPostPromise<TsCreateUpdateScrutin, TsScrutinDataResponse>("/screate", scrutin);
  }

  /** Updates an existing Scrutin in TESTING state; clears Test Votes. */
  public updateScrutin = (scrutin: TsCreateUpdateScrutin): Promise<TsScrutinDataResponse> => {
    return getHttpLayer().HttpPostPromise<TsCreateUpdateScrutin, TsScrutinDataResponse>("/supdate", scrutin);
  }

  /** Retrieves a Scrutin in any state, given its mac and scrutinId. */
  public getScrutin = (mac: string, scrutinId: string): Promise<TsScrutinDataResponse> => {
    return getHttpLayer().HttpGetPromise<TsScrutinDataResponse>("/sget/" + scrutinId + "/" + mac);
  }

  /** 
   * Sets a scrutin previously in TESTING state to the READY state.
   * It cannot be updated anymore and will be made ACTIVE at its openDate.
   */
  public goReady = (mac: string, scrutinId: string): Promise<TsScrutinDataResponse> => {
    const macSid = new TsMacSid(mac, scrutinId);
    return getHttpLayer().HttpPostPromise<TsMacSid, TsScrutinDataResponse>("/sgoready", macSid);
  }

  /** Resets a READY scrutin to the TESTING state. It will be updatable and testable again. */
  public backTesting = (mac: string, scrutinId: string): Promise<TsScrutinDataResponse> => {
    const macSid = new TsMacSid(mac, scrutinId);
    return getHttpLayer().HttpPostPromise<TsMacSid, TsScrutinDataResponse>("/sretest", macSid);
  }

  /** 
   * Returns all public info for a Scrutin, including the VoteURL and the vote results.
   * Does not disclose organizer info (mac, sOrganizerEmail, ...) and follows VotersVisibility.
   * Can be exploited by a independent page.
   */
  public viewScrutin = (scrutinId: string, mac?: string): Promise<TsScrutinViewResponse> => {
    return getHttpLayer().HttpGetPromise<TsScrutinViewResponse>("/vview/" + scrutinId + (mac ? "/" + mac : ""));
  }

  /** Retrieves the full Scrutin Audit data, given its mac and scrutinId. */
  public auditScrutin = (mac: string, scrutinId: string): Promise<TsAuditResponse> => {
    return getHttpLayer().HttpGetPromise<TsAuditResponse>("/saudit/" + scrutinId + "/" + mac);
  }

  /** Updates the Status of a Sent Code Mail. */
  public updateMailResult = (scrutinId: string, voterId: string, voterMail: string, transportId: string, mac?: string): Promise<TsSentMessageResponse> => {
    const sentMailId = new TsSentMailId(scrutinId, voterId, voterMail, transportId, mac);
    return getHttpLayer().HttpPostPromise<TsSentMailId, TsSentMessageResponse>("/smailres", sentMailId);
  }
}