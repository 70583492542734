/**
 * Page pour Electeur.
 * Affiche les infos de base du scrutin, et permet d'entrer email/secret (et d'envoyer le mail de secret).
 * Permet aussi de choisir le/les Candidat(s) pour voter.
 * La page est self-contained.
 * <h3>Eléments de la Page:</h3>
 * <ul>
 *    <li>Infos scrutin (& Link ViewScrutin => Scrutin page, avec back)</li>
 *    <li>Champ Email</li>
 *    <li>Champ Secret</li>
 *    <li>Btn SendSecret + selecteur Lang  -------- ORDRE champ/btn dépend de déjà envoyé</li>
 *    <li>Voter has Voted: a voté et date</li>
 *    <li>Erreur sur Vote: Dialog en overlay</li>
 *    <li>Liste des Candidats, avec Checkbox</li>
 *    <li>Bouton Vote</li>
 *    <li>Statut du dernier Mail Secret envoyé</li>
 *    <li>Tout en bas (après le vote ?) lien vers site principal</li>
 * </ul>
 * 
 * Eléments définissant le State de l'UI:
 * <ul>
 *   <li>Operation in progress</li>
 *   <li>ScrutinId available</li>
 *   <li>ScrutinData available</li>
 *   <li>Email available</li>
 *   <li>Code Available</li>
 *   <li>Voter available</li>
 *   <li>Voter has/hasn't voted</li>
 *   <li>LastMail sent (délai avant renvoi possible)</li>
 * </ul>
 * 
 * <table>
 *   <tr>
 *     <td>Toujours avec ScrutinID</td>
 *     <td>infos scrutin, inputs email/code; btn Send Secret ssi pas de VStatus</td>
 *   </tr>
 *   <tr>
 *     <td>Si pas de VoterStatus</td>
 *     <td>Btn Send Secret; Last Message Status (if any)</td>
 *   </tr>
 *   <tr>
 *     <td>Avec VoterStatus, si n'a pas voté</td>
 *     <td>Liste des candidats avec CB et Vote Btn</td>
 *   </tr>
 *   <tr>
 *     <td>Avec VoterStatus, si a pas voté</td>
 *     <td>Info sur a voté</td>
 *   </tr>
 * </table>
 */
import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import { TsScrutinViewResponse } from '../common/tsmodel/TsResponses';
import './vote.css';
import { parseVoteUrl, resultsUrl } from './VoteUtils';
import { getVoteUiStore, VUIS_CHANGE } from './VoteUiStore';
import { MailBlock } from './MailBlock';
import { ScrutinBlock } from './ScrutinBlock';
import { InfoBandeau } from './InfoBandeau';
import { ErrCodes } from '../common/backend/ErrCodes';
import { CodeBlock } from './CodeBlock';
import { VoteBlock } from './VoteBlock';
import i18next from 'i18next';
import { LangSignal } from '../app/Root';

export enum VPPhase {
  LOADING_SCRUTIN,
  GOT_SCRUTIN,
  /** Global error, cant find scrutin or comm error */
  ERR_SCRUTIN,
}

interface VPState {
  /** mac is only present for Test Vote. */
  mac?: string;
  scrutinId?: string;
  /** Mail being entered. */
  inEmail: string;
  /** Secret being entered. */
  inSecret: string;
  /** Language for the current page. */
  lang: string;
}

//-- il faut qq part un affi du Scrutin Status global (vote en cours...), evt sur autre page
//-- si le Scrutin n'est pas TESTING ou ACTIVE, ne pas afficher le mail/code/vstatus


export class VotePage extends React.PureComponent<{}, VPState> {

  // private static LOGTAG = "votepg";

  constructor(props: {}) {
    super(props);
    const [scrutinId, mac] = parseVoteUrl(document.URL);
    this.state = {
      mac,
      scrutinId,
      inEmail: "",    // May be passed (later) through the url
      inSecret: "",
      lang: i18next.language
    }
  }

  public componentDidMount() {
    getVoteUiStore().on(VUIS_CHANGE, this.updateState);
    LangSignal.on('setlang', () => {
      this.setState({ lang: i18next.language });
    });
  }

  public componentWillUnmount() {
    getVoteUiStore().off(VUIS_CHANGE, this.updateState);
  }

  private updateState = () => {
    this.forceUpdate();
  }


  public render() {
    // State from VoteUiStore
    const scrutinView = getVoteUiStore().getScrutin();
    const voterStatuses = getVoteUiStore().getVoterStatuses();
    const secretValidated = getVoteUiStore().getValidateSecretStatus().secretValidated;
    const voterMail = getVoteUiStore().getSubmittedEmail();
    // Vote statuses
    const voteStatuses = getVoteUiStore().getVoteStatuses();

    const st = this.state;
    // Indicate if cannot vote due to Scrutin-level data
    let cantVoteErr = ErrCodes.OK;
    if (scrutinView && scrutinView.sStatus !== 'TESTING' && scrutinView.openDateMillis > new Date().getTime()) {
      cantVoteErr = ErrCodes.BEFORE_OPENTIME;
    } else if (scrutinView && scrutinView.closeDateMillis < new Date().getTime()) {
      cantVoteErr = ErrCodes.AFTER_CLOSETIME;
    } else if (scrutinView && scrutinView.sStatus !== 'TESTING' && scrutinView.sStatus !== 'ACTIVE') {
      cantVoteErr = ErrCodes.BAD_STATE_FOR_VOTE;
    }
    const showMailInput = scrutinView && cantVoteErr === ErrCodes.OK;
    const showCodeInput = showMailInput && voterStatuses && (voterMail !== undefined);
    const showCandSelect = showCodeInput && secretValidated;
    const atLeastAVote = showCandSelect && voterStatuses && voterStatuses.voters && (voterStatuses.voters.findIndex(voter => voter.hasVoted) !== -1);
    const realResultsLink = scrutinView && atLeastAVote ? resultsUrl(scrutinView.scrutinId) : "";

    return (
      <Container>
        <ScrutinBlock scrutinId={st.scrutinId} mac={st.mac} />
        {scrutinView && cantVoteErr !== ErrCodes.OK ?
          <InfoBandeau errcode={cantVoteErr} /> : null}
        {showMailInput ?
          <Row>
            <Col>
              <MailBlock initialMail={st.inEmail} />
            </Col>
          </Row>
          : null}
        {showCodeInput ?
          <Row style={{ marginBottom: '10px' }}>
            <Col>
              <CodeBlock initialSecret={st.inSecret} />
            </Col>
          </Row>
          : null}
        {showCandSelect && voterStatuses && voterStatuses.voters ?
          voterStatuses.voters.map(voter => <VoteBlock key={voter.voterId} scrutin={scrutinView as TsScrutinViewResponse}
            {...voter} voterMail={voterMail} voting={voteStatuses[voter.voterId] ? voteStatuses[voter.voterId].voting : false}
            voteError={voteStatuses[voter.voterId] ? voteStatuses[voter.voterId].voteError : ErrCodes.OK} />)
          : null}
        {atLeastAVote ?
          <Row>
            <Col>
              {i18next.t('activeview.results_link')}
              <div className='boxed'>
                <a href={realResultsLink} target='_blank' rel='noopener noreferrer'>{realResultsLink}</a>
              </div>
            </Col>
          </Row>
          : null}
      </Container>
    )
  }
}