/**
 * Object with a Status.
 * Can be: 
 * - Local only
 * - On Server, with a synchronization state.
 */
import { ErrCodes } from "./ErrCodes";

export enum SyncStatus {
  NOT_INITIALIZED,// No contained Bean
  LOCAL,          // local state, default values and no server interaction
  SYNCHRONIZING,  // Waiting for the Server response (initial download or following an update)
  SYNCHRONIZED,   // Version synchronized with the Server
  SYNC_ERROR      // Error encountered while synchronizing with the Server
}

export class SyncObject<T> {
  public id: string = '';
  public sync: SyncStatus = SyncStatus.NOT_INITIALIZED;
  private value?: T;
  private errCode?: ErrCodes;

  public hasValue = (): boolean => (this.sync === SyncStatus.LOCAL || this.sync === SyncStatus.SYNCHRONIZED);

  /** getValue throws an Exception if invoked in SYNCHRONIZING or SYNC_ERROR states. */
  public getValue = (): T => {
    if (this.sync === SyncStatus.LOCAL || this.sync === SyncStatus.SYNCHRONIZED) {
      return this.value as T;
    }
    throw new Error("MUST not invoke getValue in SYNCHRONIZING or SYNC_ERROR state: " + this.sync);
  }
  /** getErrCode throws an Exception if invoked in non-SYNC_ERROR states. */
  public getErrCode = (): ErrCodes => {
    if (this.sync === SyncStatus.SYNC_ERROR) {
      return this.errCode as ErrCodes;
    }
    throw new Error("MUST not invoke getErrCode in non-SYNC_ERROR state: " + this.sync);
  }

  /** To create a new Local object: const lo = new SyncObject<T>().makeLocal(); */
  public makeLocal = (id: string, value: T) => {
    this.id = id;
    this.value = value;
    this.sync = SyncStatus.LOCAL;
    this.errCode = undefined;
    return this;
  }
  /** Provide the ID if loading the object value for a new T. */
  public makeSynchronizing = (id?: string) => {
    if (id) {
      this.id = id;
    }
    this.sync = SyncStatus.SYNCHRONIZING;
    return this;
  }
  public makeSynchronized = (id: string, value: T) => {
    this.id = id;
    this.value = value;
    this.sync = SyncStatus.SYNCHRONIZED;
    this.errCode = undefined;
    return this;
  }
  public makeError = (errCode: ErrCodes) => {
    this.errCode = errCode;
    this.sync = SyncStatus.SYNC_ERROR;
    return this;
  }
  public restoreSync(prevStatus: SyncStatus) {
    this.sync = prevStatus;
    return this;
  }

  /** Forcibly remove an error condition, to allow retrying an operation */
  public forceToSyncStatus = (forcedStatus: SyncStatus) => {
    this.sync = forcedStatus;
    this.errCode = undefined;
  }
  /** Access value without checks. */
  public forceGetValue = () => (this.value);
}
