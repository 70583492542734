/**
 * Component for the Vote Page: Code input block.
 */
import i18next from "i18next";
import React from "react";
import { Button, InputGroup } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import Form from 'react-bootstrap/Form';
import './vote.css';
import { ErrCodes } from "../common/backend/ErrCodes";
import { errMessage } from "../common/tsmodel/Converters";

export interface SecretBkProps {
  secret: string;
  submittingSecret: boolean,
  secretValidated: boolean,
  secretError: ErrCodes,
  onSecretChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onValidate?: () => void;
}

/**
 * Code Input Block, with Code field and validate button.
 * @param props 
 */
export function SecretBk(props: SecretBkProps) {
  const canValidate = (!props.secretValidated) && (!props.submittingSecret) && (props.secret !== "");
  const onEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (props.onValidate && canValidate && e.code === 'Enter') {
      props.onValidate();
    }
  };
  return <div>
    <SecretInput {...secretInputStatus(props, onEnter)} onSecretChange={props.onSecretChange} />
    <ValidateButton {...props} />
  </div>
}

export interface SecretInputStatus {
  secret: string;
  mode: 'NEUTRAL' | 'VALID_OK' | 'VALID_FAIL';
  feedback?: string;
  onSecretChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onEnter?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

/** Computes the Secret input field status. */
function secretInputStatus(props: SecretBkProps, onEnter?: (e: React.KeyboardEvent<HTMLInputElement>) => void): SecretInputStatus {
  if (props.secretError !== ErrCodes.OK) {
    return { secret: props.secret, mode: 'VALID_FAIL', feedback: errMessage(props.secretError), onEnter };
  }
  if ((!props.secretValidated) && props.secretError === ErrCodes.OK) {
    return { secret: props.secret, mode: 'NEUTRAL', feedback: "", onEnter };
  }
  const feedback = i18next.t('votepage.secret_valid');
  return { secret: props.secret, mode: 'VALID_OK', feedback };
}

/** Email input field. */
function SecretInput(props: SecretInputStatus) {
  return (
    <Form.Group controlId="formSecretInput">
      <Form.Label>{i18next.t('votepage.codefield')}</Form.Label>
      <InputGroup>
        <Form.Control type="text" placeholder="code" onChange={props.onSecretChange} onKeyPress={props.onEnter}
          value={props.secret} isInvalid={props.mode === 'VALID_FAIL'} isValid={props.mode === 'VALID_OK'}
          disabled={props.mode === 'VALID_OK'} />
        <Form.Control.Feedback type='invalid'>
          {props.feedback}
        </Form.Control.Feedback>
        <Form.Control.Feedback type='valid'>
          {props.feedback}
        </Form.Control.Feedback>
      </InputGroup>
    </Form.Group >);
}

/**
* Component for the VotePage: Validate Mail button.
*/
function ValidateButton(props: SecretBkProps) {
  if (props.secretValidated) {
    return null;
  }
  const btnText = i18next.t('votepage.validate_secret');
  if (props.submittingSecret) {
    return <Button variant="primary" block disabled>
      <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
      <span className="sr-only">{btnText}</span>
    </Button>
  }
  if (props.secret === "") {
    return <Button variant='primary' block disabled>{btnText}</Button>
  }
  return <Button variant='primary' block onClick={props.onValidate}>{btnText}</Button>
}


