import { ErrCodes } from "./ErrCodes";

export interface HttpLayer {
  setHttpApiUrl?: (apiUrl: string) => void;
  getHttpApiUrl?: () => string;
  setHttpTimeouts: (response: number, deadline: number) => void;
  getHttpTimeouts: () => { response: number, deadline: number };

  HttpGetPromise: <T>(url: string) => Promise<T>;
  HttpPostPromise: <B extends object, T> (url: string, body: B) => Promise<T>;

  /**
   * Executes a GET operation on the URL relative to API_URL and retrieves the server response,
   * whose body is expected to include a 'ret' number and, if successful, a 'data' object of type T.
   * <p/>
   * If a POST operation was in progress on the same URL, this GET returns ErrCodes.IGNORE - the idea is that the POST
   * result will contain the same data (i.e. the Server returns the updated state for each operation, as it would
   * respond to a GET).
   * <br/>
   * If a GET operation was in progress on the same URL, this GET aborts it, to make sure the latest data is acquired.
   * 
   * @param url - relative to API_URL
   * @param onData - Callback invoked with the 'data' body, of type T
   * @param onError - Parsed Error Code (ErrCodes).
   * @param onComplete - Invoked whatever the result, AFTER the other callbacks.
   */
  HttpGet: <T> (url: string, onData: (data: T) => void, onError?: (err: ErrCodes) => void) => void;


  /**
   * Executes a POST operation on the URL relative to API_URL, send the Body (B type) and retrieves the server response,
   * whose body is expected to include a 'ret' number and, if successful, a 'data' object of type T.
   * <p/>
   * If a POST operation was in progress on the same URL, this POST is also executed.
   * <br/>
   * If a GET operation was in progress on the same URL, this POST aborts it, to make sure the latest data is acquired.
   * The idea is that the POST result will contain the same data (i.e. the Server returns the updated state for each
   * operation, as it would respond to a GET).
   * 
   * @param url - relative to API_URL
   * @param body - body to send, of type B
   * @param onData - Callback invoked with the 'data' body, of type T
   * @param onError - Parsed Error Code (ErrCodes).
   */
  HttpPost: <B extends object, T> (url: string, body: B, onData: (data: T) => void, onError?: (err: ErrCodes) => void) => void;
}

class DummyHttpLayer implements HttpLayer {
  setHttpApiUrl?: ((apiUrl: string) => void) | undefined; getHttpApiUrl?: (() => string) | undefined;
  public setHttpTimeouts(response: number, deadline: number) { }
  public getHttpTimeouts() { return { response: 1000, deadline: 10000 } }
  public HttpGetPromise<T>(url: string) { return Promise.resolve({} as T); }
  public HttpPostPromise<B extends object, T>(url: string, body: B) { return Promise.resolve({} as T) }
  public HttpGet<T>(url: string, onData: (data: T) => void, onError?: ((err: ErrCodes) => void) | undefined): void {
    if (onError) { onError(ErrCodes.UNKNOWN); }
  }
  public HttpPost(url: string, body: any, onData: (data: any) => void, onError?: ((err: ErrCodes) => void) | undefined): void {
    if (onError) { onError(ErrCodes.UNKNOWN); }
  }
}

let currentHttpLayer: HttpLayer = new DummyHttpLayer();
export const getHttpLayer = () => (currentHttpLayer);
export const setHttpLayer = (hl: HttpLayer) => { currentHttpLayer = hl; };
