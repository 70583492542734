/**
 * Permet de coller / entrer une liste de Candidats.
 */
import i18next from 'i18next';
import * as React from 'react';
import { Form, Button } from 'react-bootstrap';
import { CandidatesBean } from '../backend/tsmodel/TsCreateUpdateScrutin';
import { getLogger } from '../common/util/pmlogger';
import { parseCandidates } from '../workers/LineParser';

interface RCProps {
  visible: boolean,
  candidates: CandidatesBean,
  editable: boolean,
  dirty: () => void,
  submit: (candidates: CandidatesBean) => void
}
interface RCState {
  nVotes: number,
  areaedit: string,
  candidates: string[],
  help: boolean,
  errNVotes?: string,
  errCandidates?: string
}

export class CandidatesView extends React.PureComponent<RCProps, RCState> {

  private static LOGTAG = "candidatesview";

  constructor(props: any) {
    super(props);
    const areaedit = this.props.candidates.candidates.join('\n');
    this.state = {
      nVotes: this.props.candidates.nVotes,
      areaedit,
      candidates: this.props.candidates.candidates,
      help: false
    }
  }

  public render() {
    if (!this.props.visible) {
      return null;
    }
    const editable = this.props.editable;
    const numOk = this.state.candidates.length;
    const count = { count: this.state.nVotes };
    const nVotesStr = this.state.nVotes === -1 ? "" : this.state.nVotes.toString();
    return (
      <div className='container-fluid'>
        <div className='container'>
          <form onSubmit={this.submit}>
            <Form.Group controlId="textlines" id="textlinesGroup">
              <Form.Label>{i18next.t('candidates.textlines')}{' '}
                <span className='help-inline text-muted'>
                  <em><small>{i18next.t('candidates.textlines_sub')}</small></em>
                </span></Form.Label>
              <Form.Control value={this.state.areaedit} as="textarea" rows={10} name='textlines'
                onChange={this.onCandidates} disabled={!editable}
                isValid={this.state.errCandidates === undefined && numOk > 0}
                isInvalid={this.state.errCandidates !== undefined}
                placeholder={i18next.t('candidates.textlines_ph')} />
              {
                numOk >= 0 ?
                  <div className="valid-feedback">
                    {i18next.t('candidates.found_contacts')}: {numOk}
                  </div>
                  : null
              }
              {
                this.state.errCandidates !== undefined ?
                  <div className="invalid-feedback">
                    {this.state.errCandidates}
                  </div>
                  : null
              }
            </Form.Group>
            <Form.Group controlId="nCandidates">
              <div className='form-label'>
                {i18next.t('candidates.num_votes')}{' '}
                <span className='help-inline text-muted'>
                  <em><small>{i18next.t('candidates.num_votes_sub')}</small></em>
                </span>
              </div>
              <div>
                <input type="text" value={nVotesStr} onChange={this.onNVotes}
                  disabled={!editable}
                  className='form-control' style={{ width: '50px', display: 'inline' }}
                /> {this.state.errNVotes ?
                  <span style={{ color: 'red' }}>&nbsp;{this.state.errNVotes}</span>
                  : <span>&nbsp;{i18next.t('candidates.per_voter', count)}</span>
                }
              </div>


            </Form.Group>
            {editable ?
              <div className='ed-controls'>
                <Button variant='primary' type='submit' className='ed-button' disabled={numOk < 1 || this.state.errCandidates !== undefined || this.state.errNVotes !== undefined}>
                  {i18next.t('candidates.save')}
                </Button>
              </div>
              : null}
          </form>
        </div>

        {editable ?
          <div style={{ padding: '10px', margin: '15px 0px', backgroundColor: '#EEEECC' }}>
            <h3>{i18next.t('candidates.help_title_how')}:</h3>
            <div>{i18next.t('candidates.help_how_1')}</div>
            <div>{i18next.t('candidates.help_how_2')}</div>
            <div>{i18next.t('candidates.help_how_3')}</div>
          </div>
          : null}

      </div >
    );
  }

  private onNVotes = (e: React.ChangeEvent<HTMLInputElement>) => {
    const empty = (e.currentTarget.value === "" || e.currentTarget.value === "0");
    const nVotes = empty ? -1 : parseInt(e.currentTarget.value);
    if ((!empty) && isNaN(nVotes)) {
      // ignore - on ne peut donc pas mettre une valeur non numérique sauf empty string
      return;
    }
    this.props.dirty();
    if (empty) {
      this.setState({ nVotes, errNVotes: i18next.t('candidates.err_missingvalue') });
    } else if (nVotes > this.state.candidates.length) {
      this.setState({ nVotes, errNVotes: i18next.t('candidates.err_maxvotes') });
    } else {
      this.setState({ nVotes, errNVotes: undefined });
    }
  }

  private onCandidates = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    const val = e.currentTarget.value;
    this.props.dirty();
    const [candidates, errCandidates, errNVotes] = CandidatesView.computeCandidates(val, this.state.nVotes);
    this.setState({ areaedit: val, candidates, errCandidates, errNVotes })
  }
  /** Returns candidates[], errCandidates, errNVotes */
  private static computeCandidates = (candLines: string, nVotes: number): [string[], string?, string?] => {
    if (candLines === '') {
      return [[], i18next.t('candidates.no_contact'), undefined];
    }
    const candidates: string[] = parseCandidates(candLines);
    if (candidates.length === 0) {
      return [candidates, i18next.t('candidates.no_contact'), undefined];
    }
    const errNVotes = nVotes === -1 ? i18next.t('candidates.err_missingvalue') :
      (nVotes > candidates.length ? i18next.t('candidates.err_maxvotes') : undefined);
    return [candidates, undefined, errNVotes];
  }

  private submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const cbean: CandidatesBean = {
      candidates: this.state.candidates,
      nVotes: this.state.nVotes
    }
    getLogger().info(CandidatesView.LOGTAG, "Submitting Candidates with State: %o", this.state);
    this.props.submit(cbean);
  }
}