/**
 * Component for the Vote Page: Candidates choice block.
 */
import i18next from "i18next";
import React from "react";
import Form from 'react-bootstrap/Form';
import { TsCandidate } from "../common/tsmodel/TsResponses";
import './vote.css';

export interface CandidatesBkProps {
  candidates: TsCandidate[];
  nVotes: number;
  choices: string[];
  onCandSelected?: (choices: string[]) => void;
}

/**
 * Candidates Block, with Radios or Checkboxes.
 * @param props 
 */
export function CandidatesBk(props: CandidatesBkProps) {
  const type = props.nVotes === 1 ? 'radio' : 'checkbox'
  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (props.onCandSelected) {
      const candId = e.currentTarget.id;
      const selected = e.currentTarget.checked;
      let choices = props.choices;
      if (props.nVotes === 1 && candId === 'blank') {
        choices = [];
      } else if (props.nVotes === 1) {  // radio
        choices = [candId];
      } else if (!selected) {
        choices = props.choices.filter(v => v !== candId);
      } else {
        choices = props.choices.concat([candId]);
      }
      props.onCandSelected(choices);
    }
  }

  return (<div>
    <Form.Group controlId="formCandidates">
      <Form.Label>{i18next.t('votepage.candidates', { count: props.nVotes })} :</Form.Label>

      {props.candidates.map(cand => (
        <Form.Check id={cand.id} key={cand.id} label={cand.displayName} type={type} onChange={onChange}
          checked={props.choices.find(v => v === cand.id) ? true : false} />
      ))}

      {props.nVotes === 1 ?
        <Form.Check id='blank' label={i18next.t('votepage.blank')} type={type} onChange={onChange}
          checked={props.choices.length === 0} style={{ marginTop: '10px' }} />
        : null
      }
      {props.choices.length > props.nVotes ?
        <div className='invalid-feedback' style={{ display: 'block' }}>
          {i18next.t('votepage.maxcandidates', { count: props.nVotes })}
        </div>
        :
        <div className='' style={{ display: 'block', marginTop: '10px', fontStyle: 'italic', fontSize: '90%' }}>
          {i18next.t('votepage.voteisfinal')}
        </div>
      }
    </Form.Group>
  </div>);
}
