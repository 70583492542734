/**
 * Component for the VotePage: Large Information Alert.
 */
import i18next from "i18next";
import Alert from "react-bootstrap/Alert";
import { ErrCodes } from "../common/backend/ErrCodes";
import { errMessage } from "../common/tsmodel/Converters";
import { getLogger } from "../common/util/pmlogger";


export interface InfoBandeauProps {
  opInProgress?: string;
  errcode: ErrCodes;
}

export function InfoBandeau(props: InfoBandeauProps) {
  if (props.opInProgress) {
    return <Alert variant='info'>{props.opInProgress}</Alert>;
  }
  if (props.errcode !== ErrCodes.OK) {
    let errText = errMessage(props.errcode);
    getLogger().warn("erroralert", "Error Getting Scrutin: %o: %s", props.errcode, errText);
    let addText = "";
    if (props.errcode === ErrCodes.SCRUTIN_NOT_FOUND) {
      errText = i18next.t('votepage.scrutin_not_found1');
      addText = i18next.t('votepage.scrutin_not_found2');
    } else if (props.errcode === ErrCodes.HTTP_UNKNOWN || props.errcode === ErrCodes.HTTP_TIMEOUT || props.errcode === ErrCodes.HTTP_SERVER_ERROR || props.errcode === ErrCodes.HTTP_CLIENT_ERROR) {
      addText = i18next.t('votepage.comm_error');
    }
    return (
      <Alert variant="danger" style={{ margin: '10px' }}>
        <Alert.Heading>{errText}</Alert.Heading>
        <p>
          {addText}
        </p>
      </Alert>
    );
  }
  return null;
}