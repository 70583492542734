
import { setHttpLayer } from '../common/backend/HttpLayer';
import { HttpLayerImpl } from '../common/backend/HttpLayerImpl';
import { ErrCodes } from '../common/backend/ErrCodes';
import { SupportedLang } from '../common/tsmodel/TsModelDefs';
import { TsScrutinViewResponse, TsVoterStatus, TsVoteResponse, TsPingResponse, TsVoterStatuses, TsCodesResponse } from '../common/tsmodel/TsResponses';
import { setVoteApi, VoteApi } from '../backend/VoteApi';
import { getVoteUiStore, VoteUiStoreImpl } from '../voteui/VoteUiStore';
import { AppConfig } from './AppConfig';

export function AppInit() {
  // setHttpLayer(new ServerSimulator());
  setHttpLayer(new HttpLayerImpl(AppConfig.API_URL));
  // new VooApi().syncVooPing();
}

export function GuiTestAppInit() {
  const api = new GuiTestVoteApi();
  setVoteApi(api);
  (getVoteUiStore() as VoteUiStoreImpl).setScrutin(api.scrutinResp);
}

class GuiTestVoteApi implements VoteApi {
  /** Whether to delay responses. */
  public delay = false;
  /** These values can be modified for each story */
  public vsRet: ErrCodes = ErrCodes.OK;
  //////////// --- Check whether returns emails[] or not the field
  public scrutinResp: TsScrutinViewResponse = {
    ret: 0, mid: "123", scrutinId: "01-123", sStatus: 'ACTIVE', sDisplayName: "Le scrutin de la mort", sOrganizerName: "Philippe Martinou",
    openDateMillis: new Date().getTime() - 3600000, closeDateMillis: Date.now() + 3600000, closeOnAllVoted: true,
    numVoters: 3,
    votersVisibility: 'DISPLAY_NAME', nVotes: 1, secretCodeLen: 6,
    candidates: [{ id: "c1", displayName: "Albert Héhé" }, { id: "c2", displayName: "Bobo 2" }, { id: "c3", displayName: "Candide" }, { id: "c4", displayName: "Domus" }],
  }

  public vStatus: TsVoterStatus = {
    ret: 0, mid: "123", scrutinId: "01-123", email: "john@doe.com", voterId: "111", displayName: "électeur n°1",
    candidates: [{ id: "c1", displayName: "Albert Héhé" }, { id: "c2", displayName: "Bobo 2" }, { id: "c3", displayName: "Candide" }, { id: "c4", displayName: "Domus" }],
    hasVoted: false, votedTime: 0, sentMessage: undefined
  };
  public secretResp: TsVoterStatus = {
    ret: 0, mid: "123", scrutinId: "01-123", email: "john@doe.com", voterId: "111", displayName: "électeur n°1",
    candidates: [{ id: "c1", displayName: "Albert Héhé" }, { id: "c2", displayName: "Bobo 2" }, { id: "c3", displayName: "Candide" }, { id: "c4", displayName: "Domus" }],
    hasVoted: false, votedTime: 0, sentMessage: {
      type: 'EMAIL', destination: "john@doe.com", subject: "votre secret", sentTime: new Date().getTime() - 1000, status: 'UNKNOWN', statusFinal: false
    }
  };
  public statusesResp: TsVoterStatuses = {
    ret: 0, mid: "123", scrutinId: "01-123", email: "john@doe.com",
    candidates: [{ id: "c1", displayName: "Albert Héhé" }, { id: "c2", displayName: "Bobo 2" }, { id: "c3", displayName: "Candide" }, { id: "c4", displayName: "Domus" }],
    voters: [
      {
        voterId: "111", displayName: "électeur n°1", hasVoted: false, votedTime: 0, sentMessage: {
          type: 'EMAIL', destination: "john@doe.com", subject: "votre secret", sentTime: new Date().getTime() - 1000, status: 'UNKNOWN', statusFinal: false
        }
      }
    ]
  };
  public codesResp: TsCodesResponse = {
    ret: 0, mid: "123", scrutinId: "01-123", email: "john@doe.com", voterIds: ["111"], voteCodes: ["ABC123"]
  }
  public voteResp: TsVoteResponse = {
    ret: 0, mid: "123", scrutinId: "01-123", voterMail: "john@doe.com", voterId: "111", chkcode: "ABC123", dateMillis: new Date().getTime()
  }

  votePingPost = (data: string): Promise<TsPingResponse> => {
    return new Promise<TsPingResponse>((resolve, reject) => {
      setTimeout(() => {
        if (this.vsRet !== ErrCodes.OK) {
          reject(this.vsRet);
        } else {
          resolve({ ret: 0, mid: "123", data });
        }
      }, 1000);
    });
  }

  voterStatus = (scrutinId: string, email: string, mac?: string | undefined): Promise<TsVoterStatus> => {
    return new Promise<TsVoterStatus>((resolve, reject) => {
      setTimeout(() => {
        if (this.vsRet !== ErrCodes.OK) {
          reject(this.vsRet);
        } else {
          resolve(this.vStatus);
        }
      }, 1000);
    });
  }

  voterStatuses = (scrutinId: string, email: string, mac?: string): Promise<TsVoterStatuses> => {
    return new Promise<TsVoterStatuses>((resolve, reject) => {
      setTimeout(() => {
        if (this.vsRet !== ErrCodes.OK) {
          console.log("Rejecting VoterStatuses: %o", this.vsRet);
          reject(this.vsRet);
        } else {
          console.log("Resolving VoterStatuses: %o", this.secretResp);
          resolve(this.statusesResp);
        }
      }, 1000);
    });
  }

  sendSecret = (scrutinId: string, voterId: string, voterMail: string, lang: SupportedLang, mac?: string | undefined): Promise<TsVoterStatus> => {
    return new Promise<TsVoterStatus>((resolve, reject) => {
      setTimeout(() => {
        if (this.vsRet !== ErrCodes.OK) {
          console.log("Rejecting SendSecret: %o", this.vsRet);
          reject(this.vsRet);
        } else {
          console.log("Resolving SendSecret: %o", this.secretResp);
          resolve(this.secretResp);
        }
      }, 1000);
    });
  }

  validateSecret = (scrutinId: string, email: string, secret: string, mac?: string): Promise<TsCodesResponse> => {
    return new Promise<TsCodesResponse>((resolve, reject) => {
      setTimeout(() => {
        if (this.vsRet !== ErrCodes.OK) {
          console.log("Rejecting validateSecret: %o", this.vsRet);
          reject(this.vsRet);
        } else {
          console.log("Resolving validateSecret: %o", this.secretResp);
          resolve(this.codesResp);
        }
      }, 1000);
    });
  }

  testVote = (scrutinId: string, voterId: string, voterMail: string, voterSecret: string, choices: string[], mac: string): Promise<TsVoteResponse> => {
    return new Promise<TsVoteResponse>((resolve, reject) => {
      setTimeout(() => {
        if (this.vsRet !== ErrCodes.OK) {
          reject(this.vsRet);
        } else {
          resolve(this.voteResp);
        }
      }, 1000);
    });
  }

  realVote = (scrutinId: string, voterId: string, voterMail: string, voterSecret: string, choices: string[]): Promise<TsVoteResponse> => {
    return new Promise<TsVoteResponse>((resolve, reject) => {
      setTimeout(() => {
        if (this.vsRet !== ErrCodes.OK) {
          reject(this.vsRet);
        } else {
          resolve(this.voteResp);
        }
      }, 1000);
    });
  }

  viewScrutin = (scrutinId: string, mac?: string | undefined): Promise<TsScrutinViewResponse> => {
    return new Promise<TsScrutinViewResponse>((resolve, reject) => {
      setTimeout(() => {
        if (this.vsRet !== ErrCodes.OK) {
          reject(this.vsRet);
        } else {
          resolve(this.scrutinResp);
        }
      }, 0);
    });
  }
}
