/**
 * See the Java class PastedMailsParser.Result
 */
export class ParsedContact {
  public displayName: string = '';
  public emails: string[] = [];

  static build = (displayName: string, emails: string[]) => {
    const pc = new ParsedContact();
    pc.emails = emails;
    pc.displayName = displayName;
    return pc;
  }

  appendToDisplayName(item: string) {
    if (item === undefined || item === null || item === "") {
      return;
    }
    let addedItem = item.trim();
    if (addedItem !== "") {
      if (this.displayName === '') {
        this.displayName = addedItem;
      } else {
        this.displayName += " " + addedItem;
      }
    }
  }

  displayString = () => {
    const dn = (this.displayName === '' ? "" : this.displayName + ' ');
    return dn + this.emails.map(eml => "<" + eml + ">").join(', ');
  }
}

export class ParsedContactsList {
  public listId: number;
  public validContacts: ParsedContact[];
  public invalidContacts: string[];
  public ignoredLines: string[];
  public validated: boolean;
  public readOnly: boolean;

  constructor() {
    this.listId = 0;
    this.validContacts = [];
    this.invalidContacts = [];
    this.ignoredLines = [];
    this.validated = false;
    this.readOnly = false;
  }
};

