/**
 * Scrutin - Create a new Scrutin or Edit General Scrutin info.
 * Validation au moment où on Submit. Les dates sont manipulées pour rester toujours valides. Le submit n'est remonté au parent que si valide.
 * <p/>
 * Sélection des dates: sans les heures, les plages de dates sélectionnées sont incluses (1/1/20 - 2/1/20 = 1/1/20 0:00 - 2/1/20 23:59)
 * <br/>
 * Avec les heures aussi: 1/1/20 14:00 - 1/1/20 14:59
 * <p/>
 * Non-Contrôlé: les changements ne sont envoyés au save Callback que sur Enregistrer.
 * Notifie le changement de status global pour mise à jour du Tab correspondant.
 * <p/>
 * Echange avec son Container et le store: ScrutinBean.
 */
import i18next from 'i18next';
import * as React from 'react';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { Form, Button } from 'react-bootstrap';
import '../App.css';
import { ValidationState, Validators } from '../workers/Validators';
import { CalendarIcon } from '@primer/octicons-react'
import { DateTime } from 'luxon';
import { InlineStartHourSelector, InlineEndHourSelector } from '../scrutin/InlineHourSelector';
import { DatesManager } from '../common/util/DatesManager';
import { getLogger } from '../common/util/pmlogger';
import { ScrutinBean } from '../backend/tsmodel/TsCreateUpdateScrutin';
import { TsModelDefs } from '../common/tsmodel/TsModelDefs';

interface ScrutinProps {
  visible: boolean,
  scrutin: ScrutinBean,
  editable: boolean,
  dirty: () => void,
  submit: (scrutin: ScrutinBean) => void
}

interface ScrutinState {
  sDisplayName: string,
  sOrganizerName: string,
  sOrganizerEmail: string,
  openDateMillis: number,
  closeDateMillis: number,
  closeOnAllVoted: boolean,
  valid?: ValidationState
}

export class ScrutinView extends React.PureComponent<ScrutinProps, ScrutinState> {

  private static LOGTAG = "scrutinview";

  // Between today 0h and + 4 months
  private minDate = TsModelDefs.defaultOpenDate().getTime();
  private maxDate = new Date(this.minDate + 4 * 31 * 24 * 3600000).getTime();

  constructor(props: ScrutinProps) {
    super(props);
    this.state = {
      sDisplayName: props.scrutin.sDisplayName,
      sOrganizerName: props.scrutin.sOrganizerName,
      sOrganizerEmail: props.scrutin.sOrganizerEmail,
      openDateMillis: props.scrutin.openDateMillis,
      closeDateMillis: props.scrutin.closeDateMillis,
      closeOnAllVoted: props.scrutin.closeOnAllVoted
    }
  }

  public render() {
    if (!this.props.visible) {
      return null;
    }
    getLogger().debug(ScrutinView.LOGTAG, "Rendering Scrutin View with State %o", this.state);
    // Selon EDITABLE - Si non editable, garder les temps de props.
    const startMoment = DateTime.fromMillis(this.state.openDateMillis);
    const endMoment = DateTime.fromMillis(this.state.closeDateMillis);
    const startHour = startMoment.hour;
    const endHour = endMoment.hour;

    const valid = this.state.valid;
    const editable = this.props.editable;
    const startDateStr = DatesManager.get().formatShortNumericDate(startMoment);
    const endDateStr = DatesManager.get().formatShortNumericDate(endMoment);
    const minEndHour = DatesManager.get().isSameDate(startMoment, endMoment) ? startHour : 0;
    const withHours = true;
    return (
      <div className='container-fluid'>
        <div className='container'>
          <form onSubmit={this.submit}>
            <Form.Group controlId="sDisplayName">
              <Form.Label>{i18next.t('scrutin.displayname')}{' '}
                <span className='help-inline text-muted'>
                  <em><small>{i18next.t('scrutin.displayname_sub')}</small></em>
                </span></Form.Label>
              <Form.Control value={this.state.sDisplayName} type='text' name='sDisplayName'
                onChange={this.onDisplayNameChange} disabled={!editable}
                isInvalid={valid && valid.fieldErrors.sDisplayName !== undefined}
                isValid={valid && valid.fieldErrors.sDisplayName === undefined}
                placeholder={i18next.t('scrutin.displayname_ph')} />
              {valid ?
                <Form.Control.Feedback type="invalid">
                  {valid.fieldErrors.sDisplayName}
                </Form.Control.Feedback> : null
              }
            </Form.Group>

            <Form.Group controlId="sOrganizerName">
              <Form.Label>{i18next.t('scrutin.organame')}{' '}
                <span className='help-inline text-muted'>
                  <em><small>{i18next.t('scrutin.organame_sub')}</small></em>
                </span></Form.Label>
              <Form.Control value={this.state.sOrganizerName} type='text' name='sOrganizerName'
                onChange={this.onOrganizerNameChange} disabled={!editable}
                isInvalid={valid && valid.fieldErrors.sOrganizerName !== undefined}
                isValid={valid && valid.fieldErrors.sOrganizerName === undefined}
                placeholder={i18next.t('scrutin.organame_ph')} />
              {valid ?
                <Form.Control.Feedback type="invalid">
                  {valid.fieldErrors.sOrganizerName}
                </Form.Control.Feedback> : null
              }
            </Form.Group>

            <Form.Group controlId="sOrganizerEmail">
              <Form.Label>{i18next.t('scrutin.orgamail')}{' '}
                <span className='help-inline text-muted'>
                  <em><small>{i18next.t('scrutin.orgamail_sub')}</small></em>
                </span></Form.Label>
              <Form.Control value={this.state.sOrganizerEmail} type='text' name='sOrganizerEmail'
                onChange={this.onOrganizerEmailChange} disabled={!editable}
                isInvalid={valid && valid.fieldErrors.sOrganizerEmail !== undefined}
                isValid={valid && valid.fieldErrors.sOrganizerEmail === undefined}
                placeholder={i18next.t('scrutin.orgamail_ph')} />
              {valid ?
                <Form.Control.Feedback type="invalid">
                  {valid.fieldErrors.sOrganizerEmail}
                </Form.Control.Feedback> : null
              }
            </Form.Group>

            <Form.Group controlId="sDates">
              <div style={{ display: 'inline-flex', marginBottom: '0.5rem', width: '100%', justifyContent: 'space-between' }}>
                <div>
                  {i18next.t('scrutin.datetitle')}{' '}
                  <span className='help-inline text-muted'>
                    <em><small>{i18next.t('scrutin.datetitle_sub')}</small></em>
                  </span>
                </div>
                <div style={{ display: 'none' }}>
                  {i18next.t('scrutin.datetitle_hours')}&nbsp;<input type='checkbox' style={{ verticalAlign: 'baseline' }} checked={withHours} onChange={this.granuChange} />
                </div>
              </div>
              <div className='form-control' style={{ padding: 0 }}>
                <DateRangePicker value={[startMoment.toJSDate(), endMoment.toJSDate()]} onChange={this.onDpChange}
                  minDate={new Date(this.minDate)} maxDate={new Date(this.maxDate)} rangeDivider={" " + i18next.t('scrutin.au') + " "} showLeadingZeros={true}
                  calendarIcon={<CalendarIcon />} clearIcon={null} disabled={!editable}
                  className='drp' name='hello'
                />
              </div>
              {withHours ?
                <div className='hourpicker-div'>
                  {i18next.t('scrutin.du')} {startDateStr} &nbsp;<InlineStartHourSelector value={startHour} onChange={this.onStartHourChange} disabled={!editable} />
                  &nbsp;&nbsp;&nbsp;
                  {i18next.t('scrutin.au')} {endDateStr} &nbsp;<InlineEndHourSelector value={endHour} minHour={minEndHour} onChange={this.onEndHourChange} disabled={!editable} />
                </div>
                : null}
              <Form.Group controlId='sCloseOnAllVoted' style={{ marginTop: '0.8em' }}>
                <Form.Check type='checkbox' label={i18next.t('scrutin.close_on_allvoted')} onChange={this.onCloseOnAllVotedChange} checked={this.state.closeOnAllVoted} disabled={!editable} />
              </Form.Group>
              {valid && valid.fieldErrors.openDateMillis !== '' ?
                <div style={{ display: 'block', width: '100%', marginTop: '0.25rem', fontSize: '80%', color: '#dc3545' }}>
                  {valid.fieldErrors.openDateMillis}
                </div>
                :
                null
              }
            </Form.Group>

            {editable ?
              <div className='ed-controls'>
                <Button variant='primary' type='submit' className='ed-button'>{i18next.t('scrutin.save')}</Button>
              </div>
              : null}
          </form>
        </div>

      </div>
    )
  }

  private onDisplayNameChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    evt.preventDefault();
    this.props.dirty();
    this.setState({ sDisplayName: evt.currentTarget.value as string });
  }
  private onOrganizerNameChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    evt.preventDefault();
    this.props.dirty();
    this.setState({ sOrganizerName: evt.currentTarget.value as string });
  }
  private onOrganizerEmailChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    evt.preventDefault();
    this.props.dirty();
    this.setState({ sOrganizerEmail: evt.currentTarget.value as string });
  }

  private granuChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  }

  private onDpChange = (val: [Date, Date]) => {
    this.props.dirty();
    const [openDateMillis, closeDateMillis] = DatesManager.get().applyDates(this.state.openDateMillis, this.state.closeDateMillis, val[0], val[1]);
    this.setState({ openDateMillis, closeDateMillis });
  }

  private onStartHourChange = (newHour: number) => {
    this.props.dirty();
    const [openDateMillis, closeDateMillis] = DatesManager.get().applyStartHour(this.state.openDateMillis, this.state.closeDateMillis, newHour);
    this.setState({ openDateMillis, closeDateMillis });
  }
  private onEndHourChange = (newHour: number) => {
    this.props.dirty();
    const [openDateMillis, closeDateMillis] = DatesManager.get().applyEndHour(this.state.openDateMillis, this.state.closeDateMillis, newHour);
    this.setState({ openDateMillis, closeDateMillis });
  }
  private onCloseOnAllVotedChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.dirty();
    this.setState({ closeOnAllVoted: !this.state.closeOnAllVoted });
  }

  private stateAsScrutinBean = (): ScrutinBean => {
    const st = this.state;
    const sc: ScrutinBean = {
      sDisplayName: st.sDisplayName,
      sOrganizerName: st.sOrganizerName,
      sOrganizerEmail: st.sOrganizerEmail,
      openDateMillis: st.openDateMillis,
      closeDateMillis: st.closeDateMillis,
      closeOnAllVoted: st.closeOnAllVoted
    };
    return sc;
  }

  private submit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    const sc = this.stateAsScrutinBean()
    const valid = Validators.scrutin(sc);
    this.setState({ valid });
    if (valid.ok) {
      this.props.submit(sc);
    }
  }
}

