/**
 * Vote Page component: Code Input field and Send Secret button.
 */
import i18next from "i18next";
import React from "react";
import { Button, Spinner } from "react-bootstrap";
import { TsOneVoter, TsSentMessage } from "../common/tsmodel/TsResponses";
import { Envelope, EnvelopeFill } from 'react-bootstrap-icons';
import './vote.css';
import { ErrCodes } from "../common/backend/ErrCodes";
import { MessageStatus } from "./MessageStatus";

export interface SendSecretBkProps {
  sendingSecret: boolean;
  sendingSecretError: ErrCodes;
  voter?: TsOneVoter; // Reference Voter (contains the sentMessage)
  secretValidated: boolean;
  onSendSecret?: (e: React.MouseEvent) => void;
}


/**
 * Component for the VotePage: Send Secret Mail button.
 */
export function SendSecretBk(props: SendSecretBkProps) {
  if (props.secretValidated) {
    return null;
  }
  const sent = (props.voter ? props.voter.sentMessage : undefined);
  const btnText = i18next.t('votepage.sendbtn.' + (sent ? 'RESEND' : 'SEND'));
  if (props.sendingSecret) {
    return <div className='btnlinkdiv'><Button variant="link" disabled>
      <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
      &nbsp;&nbsp;{i18next.t('votepage.sending_code')}
    </Button></div>
  }
  const recentSend = (sent && sent.sentTime > new Date().getTime() - 10000);

  if (recentSend) {
    return (<>
      <div className='btnlinkdiv'>
        <Button variant='link' disabled><EnvelopeFill className='btnicon' />&nbsp;&nbsp;{i18next.t('votepage.sendbtn.INPROGRESS')}</Button>
      </div>
      <div className='btnlinkdiv'>
        <MessageStatus sentMessage={sent as TsSentMessage} />
      </div></>);
  }
  return <div className='btnlinkdiv'><Button variant='primary' block onClick={props.onSendSecret}><Envelope className='btnicon' />&nbsp;&nbsp;{btnText}</Button></div >;
}


