/**
 * Contenu de AdminView en mode FINISHED.
 */
import i18next from 'i18next';
import * as React from 'react';
import { Alert, Button } from 'react-bootstrap';
import { DateTime } from 'luxon';
import { DatesManager } from '../common/util/DatesManager';
import { getLogger } from '../common/util/pmlogger';
import { TsAuditResponse } from '../common/tsmodel/TsResponses';
import { LoadingBlock } from './UtilViews';
import { AuditView } from './AuditView';

interface FVProps {
  sDisplayName: string,
  openDateMillis: number,
  closeDateMillis: number,
  closeOnAllVoted: boolean,
  loadingAudit: boolean,
  audit?: TsAuditResponse,
  lang: string,        // unused but dunno how to force update when it changes
  view: () => void,
  result: () => void,
  getAudit: () => void,
}

export class FinishedView extends React.PureComponent<FVProps> {

  private static LOGTAG = "finishedview";

  private view = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    this.props.view();
  }

  private result = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    this.props.result();
  }

  private getAudit = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    this.props.getAudit();
  }

  public render() {
    const props = this.props;
    getLogger().info(FinishedView.LOGTAG, "Rendering FinishedView for scrutin: %s", this.props.sDisplayName);
    const span = i18next.t('finishedview.dates_span', { min: DatesManager.get().formatShortNumericDateTime(DateTime.fromMillis(props.openDateMillis)), max: DatesManager.get().formatShortNumericDateTime(DateTime.fromMillis(props.closeDateMillis)) });
    return (
      <div className='container-fluid'>
        <div className='container'>
          <div className='div-section'>
            <h2><span className='strong-value'>{props.sDisplayName}</span></h2>
          </div>
          <Alert variant='primary'>
            <p style={{ fontWeight: 'bold' }}>{i18next.t('finishedview.finished_1')}</p>
            <p>{span}</p>
            {props.closeOnAllVoted ? <p>{i18next.t('finishedview.finished_1')}</p> : null}
            <div className='test-modify'>
              <Button variant='primary' type='button' className='ed-button' onClick={this.view}>
                {i18next.t('finishedview.view_scrutin')}
              </Button>
            </div>
          </Alert>
          <div className='div-section'>
            <div className='div-subsection'>
              <Button variant='primary' type='button' className='ed-button' block onClick={this.result}>
                {i18next.t('finishedview.results_link')}
              </Button>
            </div>
          </div>
          <div className='audit-div'>
            <Button variant='secondary' type='button' className='ed-button' onClick={this.getAudit}>
              {i18next.t('finishedview.get_audit')}
            </Button>
          </div>
          {this.props.loadingAudit ?
            <LoadingBlock msg={i18next.t('finishedview.loading_audit')} /> : null}
          {((!this.props.loadingAudit) && this.props.audit) ?
            <AuditView audit={this.props.audit} /> : null
          }
        </div>
      </div >
    );
  }

}