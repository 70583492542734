import i18next from "i18next";
import { TsSentMessage } from "../common/tsmodel/TsResponses";

export interface MessageStatusProcs {
  sentMessage: TsSentMessage
}
/**
 * Feedback sur status mail envoyé.
 * Ne l'afficher que si le Mail est Validé.
 */
export function MessageStatus(props: MessageStatusProcs) {
  // Message sent less than 15 mn before now
  const oldSent = (props.sentMessage.sentTime < new Date().getTime() - 900000);
  const msg = i18next.t(oldSent ? 'votepage.oldmessagesent' : 'votepage.recentmessagesent');
  const msgtatus = i18next.t(oldSent ? 'votepage.oldmessagestatus' : 'global.messagestatus.' + props.sentMessage.status);
  return (
    <div className="btnlinkdiv">
      <div className="msgsent">{msg}&nbsp;{msgtatus}</div>
    </div>);
}
