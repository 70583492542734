/**
 * Page autonome de vue des résultats (si l'état le permet)
 */
/**
 * Page autonome - vue d'un Scrutin par son Admin, quelque soit son état.
 * <br/>/admin/:mac/:scrutinId
 */
import i18next from 'i18next';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { getLogger } from '../common/util/pmlogger';
import { TsCandidateResult, TsScrutinViewResponse } from '../common/tsmodel/TsResponses';
import { TsModelDefs } from '../common/tsmodel/TsModelDefs';
import { VooApi } from '../backend/VooApi';
import { errMessage } from '../common/tsmodel/Converters';
import { ErrorBlock, LoadingBlock } from './UtilViews';
import { DateTime } from 'luxon';
import { Alert, Badge } from 'react-bootstrap';
import { DatesManager } from '../common/util/DatesManager';
import { parseResultsUrl, numVotesIfAvailable, resultsIfAvailable } from '../voteui/VoteUtils';
import { ErrCodes } from '../common/backend/ErrCodes';


interface TState {
  /** mac is only present for Test Vote. */
  mac?: string;
  scrutinId?: string;
  loading: boolean;
  errMessage?: string;
  scrutinView?: TsScrutinViewResponse;
  showCandidates: boolean;
}

export class ResultsView extends React.PureComponent<RouteComponentProps<{}>, TState> {

  private static LOGTAG = "resultsview";

  constructor(props: any) {
    super(props);
    const [scrutinId, mac] = parseResultsUrl(document.URL);
    this.state = {
      mac,
      scrutinId,
      loading: true,
      showCandidates: false
    };
  }

  componentDidMount() {
    if ((!this.state.scrutinId) || (this.state.scrutinId === TsModelDefs.LOCAL_SID)) {
      getLogger().warn(ResultsView.LOGTAG, "ResultsView should not be accessed without at least SID.");
      this.setState({ errMessage: i18next.t('results.missing_urlparams') });
    }
    this.triggerLoadFromServer();
  }

  private triggerLoadFromServer = () => {
    this.setState({ loading: true, errMessage: undefined, scrutinView: undefined });
    setTimeout(this.loadFromServer, 0);
  }

  private loadFromServer = () => {
    new VooApi().viewScrutin(this.state.scrutinId as string, this.state.mac).then(
      scrutinView => {
        getLogger().info(ResultsView.LOGTAG, "SView response from Server: %o", scrutinView);
        this.setState({ loading: false, scrutinView });
      }
    ).catch(err => {
      getLogger().warn(ResultsView.LOGTAG, "Error Sview from Server: %o", err);
      const errMsg = (err === ErrCodes.SCRUTIN_NOT_FOUND ? i18next.t('results.scrutin_not_found') : errMessage(err));
      this.setState({ loading: false, errMessage: errMsg });
    });
  }

  private vooRoot = () => {
    this.props.history.push('/');
  }

  private admin = () => {
    if (this.state.mac) {
      this.props.history.push('/admin/' + this.state.mac + '/' + this.state.scrutinId);
    }
  }

  private toggleShowCandidates = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    this.setState({ showCandidates: !this.state.showCandidates });
  }

  public render() {
    if (this.state.errMessage) {
      return <ErrorBlock msg={this.state.errMessage} backAction={this.vooRoot} backText={i18next.t('results.back_on_error')} />
    }
    if (this.state.loading) {
      return <LoadingBlock msg={i18next.t('results.loading')} />;
    }
    const sview = this.state.scrutinView as TsScrutinViewResponse;
    const inprogress = (sview.sStatus === 'ACTIVE');
    const numVotes = numVotesIfAvailable(sview);
    const participation = (sview.numVoters === 0 ? '-' : Math.round(100 * numVotes / sview.numVoters).toFixed(2) + '%');
    const results = resultsIfAvailable(sview);
    return (
      <div className='container-fluid'>
        <div className='page-header'>
          <h1>{i18next.t('results.head')} <small>{i18next.t('results.head_sub')}</small></h1>
        </div>
        <div className='container'>
          {inprogress ?
            <Alert variant='info'>
              <p>{i18next.t('results.err_inprogress')} </p>
            </Alert>
            : null}

          <div className='div-section'>
            <div className='div-subsection'>
              {i18next.t('results.scrutin_dn')}: <span className='strong-value'>{sview.sDisplayName}</span>
            </div>
            <div className='div-subsection'>
              {i18next.t('results.orga_dn')}: <span className='strong-value'>{sview.sOrganizerName}</span>
            </div>
            <div className='div-subsection'>
              {i18next.t('results.fromdate')} <span className='strong-value'>{DatesManager.get().formatMedNumericDateTime(DateTime.fromMillis(sview.openDateMillis))}</span>&nbsp;
              {i18next.t('results.todate')} <span className='strong-value'>{DatesManager.get().formatMedNumericDateTime(DateTime.fromMillis(sview.closeDateMillis))}</span>&nbsp;
            </div>
            {sview.closeOnAllVoted ?
              <div className='smaller-subsection'>
                {i18next.t('summary.close_on_all_voted')}
              </div>
              : null}
            <div className='div-subsection'>
              {i18next.t('results.vote_nbvotes', { count: sview.nVotes })}&nbsp;&nbsp;
              <a href='#/' onClick={this.toggleShowCandidates}>[{i18next.t('results.liste')}]</a>
            </div>
          </div>
          {this.state.showCandidates ?
            <div className='div-subsection'>
              {i18next.t('results.candidates_list')}: <Badge variant='info'>{sview.candidates.length}</Badge>
              <div className='div-subsection'>
                <ul className='smaller'>
                  {sview.candidates.map(cand => (<li key={cand.id}>{cand.displayName}</li>))}
                </ul>
              </div>
            </div> : null}
          <div className='div-section'>
            <h3>{i18next.t('results.results')}</h3>
            <div className='div-subsection'>
              {i18next.t('results.participation')}: <span className='strong-value'>{participation}</span>
            </div>
            <div className='div-subsection'>
              {i18next.t('results.nbVotes')}: <span className='strong-value'>{numVotes}</span>
              <br />
              {i18next.t('results.nbVoters')}: <span className='strong-value'>{sview.numVoters}</span>
            </div>
          </div>
          {results ?
            <div className='div-section'>
              <table className='tabcell'>
                <thead>
                  <th className='tabcell'>{i18next.t('results.candidate')}</th>
                  <th className='tabcell'>{i18next.t('results.nbvotes')}</th>
                </thead>
                <tbody>
                  {(results as TsCandidateResult[]).map(candresult => (
                    <tr key={candresult.id}>
                      <td className='tabcell' key='cand'>{candresult.blankOrNul ? i18next.t('results.blankornul') : candresult.displayName}</td>
                      <td className='tabcell' key='num'>{candresult.nVotes} </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            : <div>{i18next.t('results.no_results')}</div>}
        </div>

        <div style={{ padding: '10px', margin: '15px 0px', backgroundColor: '#EEEECC' }}>
          <h3>{i18next.t('results.info_title')}</h3>
          <div>{i18next.t('results.info1')}</div>
        </div>
      </div >
    );

  }

}
