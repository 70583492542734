import i18next from "i18next";
import { Formatter } from "../common/util/Formatter";
import { getLogger } from "../common/util/pmlogger";
import { DatesManager } from "../common/util/DatesManager";
import { TsVooScrutin } from "../common/tsmodel/TsVooScrutin";
import { TsParsedContact } from "../common/tsmodel/TsParsedContact";
import { ScrutinBean } from "../backend/tsmodel/TsCreateUpdateScrutin";
import { AppTabs } from "../uicomponents/editor/EditorContainer";
import { TsModelDefs } from "../common/tsmodel/TsModelDefs";

/**
 * Fonctions et définitions de Validation.
 * 
 * @author Philippe (vovotes)
 */

export class Validators {

  private static LOGTAG = "validators";

  /** 
   * Validates the Scrutin fields and returns an error object, empty if no error.
   * In case of errors, the error messages are associated with the keys:
   * sDisplayName, sOrganizerEmail, sOrganizerName, dateRange (both dates share the validation).
   */
  static scrutin = (scrutinBean: ScrutinBean): ValidationState => {
    const { sDisplayName, sOrganizerName, sOrganizerEmail, openDateMillis, closeDateMillis } = scrutinBean;
    const ret: ValidationState = { ok: true, fieldErrors: {} };
    if (sOrganizerEmail === '') {
      ret.ok = false;
      ret.fieldErrors.sOrganizerEmail = i18next.t('global.err_empty');
    } else if (!Formatter.get().isValidEmail(sOrganizerEmail)) {
      ret.ok = false;
      ret.fieldErrors.sOrganizerEmail = i18next.t('global.err_invalid_mail');
    }
    if (sDisplayName === '') {
      ret.ok = false;
      ret.fieldErrors.sDisplayName = i18next.t('global.err_empty');
    }
    if (sOrganizerName === '') {
      ret.ok = false;
      ret.fieldErrors.sOrganizerName = i18next.t('global.err_empty');
    }

    // Between today 0h and + 4 months
    const minDate = TsModelDefs.defaultOpenDate().getTime();
    const maxDate = new Date(minDate + 4 * 31 * 24 * 3600000).getTime();
    const errDates: string = DatesManager.get().validateDateRange(openDateMillis, closeDateMillis, minDate, maxDate);
    if (errDates !== '') {
      ret.ok = false;
      ret.fieldErrors.openDateMillis = errDates;
    }
    return ret;
  }

  static voters = (contacts: TsParsedContact[]): ValidationState => {
    const ret: ValidationState = { ok: true, fieldErrors: {} };
    if (contacts.length === 0) {
      ret.ok = false;
      ret.fieldErrors.contacts = i18next.t('voters.no_contact');
    }
    return ret;
  }

  static candidates = (candidates: string[], nVotes: number): ValidationState => {
    const ret: ValidationState = { ok: true, fieldErrors: {} };
    if (candidates.length === 0) {
      ret.ok = false;
      ret.fieldErrors.contacts = i18next.t('candidates.no_contact');
    } else if (isNaN(nVotes) || nVotes <= 0) {
      ret.ok = false;
      ret.fieldErrors.nVotes = i18next.t('candidates.bad_nvotes');
    } else if (nVotes > candidates.length) {
      ret.ok = false;
      ret.fieldErrors.nVotes = i18next.t('candidates.err_maxvotes');
    }
    return ret;
  }


  /**
   * Validates the current Tab.
   */
  static check = (appBean: TsVooScrutin, tab: AppTabs): ValidationState => {
    switch (tab) {
      case AppTabs.SCRUTIN:
        return Validators.scrutin(appBean);
      case AppTabs.VOTERS:
        return Validators.voters(appBean.voters);
      case AppTabs.CANDIDATES:
        return Validators.candidates(appBean.candidates, appBean.nVotes);
    }
    getLogger().error(Validators.LOGTAG, "Invalid Tab for Validation Check: %o", tab);
    throw new Error("invalid tab");
  }

  static undefinedState = (): ValidationState => ({ ok: false, fieldErrors: {} });
  static initialStates = () => {
    const valids: ValidationState[] = [];
    valids[AppTabs.SCRUTIN] = Validators.undefinedState();
    valids[AppTabs.VOTERS] = Validators.undefinedState();
    valids[AppTabs.CANDIDATES] = Validators.undefinedState();
    return valids;
  }
}

/** For displaying Validation errors. */
interface FieldStates {
  [fieldName: string]: string
}

export interface ValidationState {
  ok: boolean,
  fieldErrors: FieldStates
}
