/** Application Header. */
import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import '../App.css';
import { RouteComponentProps } from 'react-router';
import i18next from 'i18next';
import { LangSignal } from '../app/Root';

export class AppHeader extends React.PureComponent<RouteComponentProps> {
  private handleAbout = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    this.props.history.push('/about');
  }

  private handleLang = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const nextLang = i18next.language === 'fr' ? 'en' : 'fr';
    i18next.changeLanguage(nextLang, (err) => { LangSignal.emit('setlang'); });
  }

  public render() {
    const otherLang = (i18next.language === 'en' ? i18next.t('header.lang_fr') : i18next.t('header.lang_en'));
    return (
      <Navbar expand='lg' className='app-header'>
        <Navbar.Brand style={{ fontFamily: 'Lobster, cursive', fontSize: '30px', padding: '0', color: 'black' }} href='/'>{i18next.t('header.global_title')}</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" style={{ justifyContent: 'flex-end' }}>
          <Nav>
            <Nav.Item>
              <Nav.Link onClick={this.handleAbout}>{i18next.t('header.know_more')}</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link onClick={this.handleLang} style={{ backgroundColor: '#049aa5' }}>{otherLang}</Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}
