/**
 * Autonomously manages the Scrutin display for the Vote IO.
 * Invokes as needed the VoteUiStore, that will load the Scrutin and emit change events.
 */
import i18next from "i18next";
import { DateTime } from "luxon";
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { ErrCodes } from "../common/backend/ErrCodes";
import { TsScrutinViewResponse } from "../common/tsmodel/TsResponses";
import { DatesManager } from "../common/util/DatesManager";
import { InfoBandeau } from "./InfoBandeau";
import './vote.css';
import { getVoteUiStore, VUIS_CHANGE } from "./VoteUiStore";

export interface ScrutinBlockProps {
  scrutinId?: string;
  mac?: string;
}
/** States from VoteUiStore that are needed for this view. */
export interface ScrutinBlockState {
  error: ErrCodes;
  isLoadingScrutin: boolean;
  scrutin?: TsScrutinViewResponse;
}

/**
 * Behaviour Logic for the Scrutin loading.
 */
export class ScrutinBlock extends React.Component<ScrutinBlockProps, ScrutinBlockState> {

  constructor(props: ScrutinBlockProps) {
    super(props);
    const vstore = getVoteUiStore();
    this.state = {
      error: vstore.getScrutinError(),
      isLoadingScrutin: vstore.isLoadingScrutin(),
      scrutin: vstore.getScrutin()
    }
  }

  public componentDidMount() {
    getVoteUiStore().on(VUIS_CHANGE, this.updateState);
    // Triggers retrieval of Scrutin
    setTimeout(this.triggerLoadScrutin, 3000);
  }

  private triggerLoadScrutin = () => {
    getVoteUiStore().loadScrutin(this.props.scrutinId, this.props.mac);
  }

  public componentWillUnmount() {
    getVoteUiStore().off(VUIS_CHANGE, this.updateState);
  }

  private updateState = () => {
    const vstore = getVoteUiStore();
    this.setState({
      error: vstore.getScrutinError(),
      isLoadingScrutin: vstore.isLoadingScrutin(),
      scrutin: vstore.getScrutin()
    });
  }

  public render() {
    if (this.state.error !== ErrCodes.OK) {
      return <InfoBandeau errcode={this.state.error} />
    }
    if (this.state.isLoadingScrutin) {
      return <InfoBandeau errcode={ErrCodes.OK} opInProgress={i18next.t('votepage.initialloading')} />
    }
    if (this.state.scrutin) {
      const sv: TsScrutinViewResponse = this.state.scrutin;
      // Si closed ou pas open, de ... à ...; si dans jusqu'à...
      const inPeriod = sv.openDateMillis < new Date().getTime() && sv.closeDateMillis > new Date().getTime();
      const openLabel = i18next.t(inPeriod ? 'votepage.opento' : 'votepage.openfromto') + ":";
      const openDate = inPeriod ? DatesManager.get().formatShortNumericDateTime(DateTime.fromMillis(sv.closeDateMillis)) :
        DatesManager.get().formatShortNumericDateTime(DateTime.fromMillis(sv.openDateMillis)) + " - " +
        DatesManager.get().formatShortNumericDateTime(DateTime.fromMillis(sv.closeDateMillis));

      return (
        <Container className='scrutin_border'>
          <Row>
            <Col>
              <h2>{sv.sDisplayName}</h2>
            </Col>
          </Row>
          <Row>
            <Col className='sorga'>
              <span style={{ fontStyle: 'italic' }}>{i18next.t('votepage.organizer')}: </span>
              <span style={{ fontWeight: 'bold' }}>{sv.sOrganizerName}</span>
            </Col>
            <Col className='sorga'>
              <span style={{ fontStyle: 'italic' }}>{openLabel} </span>
              <span style={{ fontWeight: 'bold' }}>{openDate}</span>
            </Col>
          </Row>
        </Container>);
    }
    return null;
  }
}
