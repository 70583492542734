/**
 * Definitions for objects sent to the backend.
 */

import { SupportedLang } from "./TsModelDefs";


let nextMid = 10;
const newMid = () => (nextMid++);

/**
 * Common superclass for all messages sent to the Server.
 */
export class FromTsBody {
  /** Message ID. */
  mid: string;
  constructor() {
    this.mid = newMid().toString();
  }
}

export class TsPingBody extends FromTsBody {
  /** Data String that will be looped back. */
  data: string;

  constructor(data: string) {
    super();
    this.data = data;
  }
}

export class TsMacSid extends FromTsBody {
  mac: string;
  scrutinId: string;

  constructor(mac: string, scrutinId: string) {
    super();
    this.mac = mac;
    this.scrutinId = scrutinId;
  }
}

export class TsSidEmail extends FromTsBody {
  scrutinId: string;
  email: string;
  mac?: string;

  constructor(scrutinId: string, email: string, mac?: string) {
    super();
    this.scrutinId = scrutinId;
    this.email = email;
    this.mac = mac;
  }
}

export class TsVoterId extends FromTsBody {
  scrutinId: string;
  voterId: string;
  voterMail: string;
  mac?: string;

  constructor(scrutinId: string, voterId: string, voterMail: string, mac?: string) {
    super();
    this.scrutinId = scrutinId;
    this.voterId = voterId;
    this.voterMail = voterMail;
    this.mac = mac;
  }
}

export class TsSentMailId extends TsVoterId {
  // The MessageId. */
  transportId: string;

  constructor(scrutinId: string, voterId: string, voterMail: string, transportId: string, mac?: string) {
    super(scrutinId, voterId, voterMail, mac);
    this.transportId = transportId;
  }
}

/**
 * For TestVote, mac must be present. For real Vote, no.
 */
export class TsVote extends FromTsBody {
  mac?: string;
  scrutinId: string;
  voterId: string;
  voterMail: string;
  voterSecret: string;
  choices: string[];

  constructor(scrutinId: string, voterId: string, voterMail: string, voterSecret: string, choices: string[], mac?: string) {
    super();
    this.scrutinId = scrutinId;
    this.voterId = voterId;
    this.voterMail = voterMail;
    this.voterSecret = voterSecret;
    this.choices = choices;
    this.mac = mac;
  }
}

export class TsSendSecret extends FromTsBody {
  scrutinId: string;
  voterId: string;
  email: string;
  supportedLang: SupportedLang;
  timezone: string;
  iffirst: boolean;
  mac?: string;

  constructor(scrutinId: string, voterId: string, email: string, lang: SupportedLang, timezone: string, iffirst: boolean, mac?: string) {
    super();
    this.scrutinId = scrutinId;
    this.voterId = voterId;
    this.email = email;
    this.supportedLang = lang;
    this.timezone = timezone;
    this.iffirst = iffirst;
    this.mac = mac;
  }
}

export class TsMailSecret extends FromTsBody {
  scrutinId: string;
  email: string;
  secret: string;
  mac?: string;

  constructor(scrutinId: string, email: string, secret: string, mac?: string) {
    super();
    this.scrutinId = scrutinId;
    this.email = email;
    this.secret = secret;
    this.mac = mac;
  }
}
