import React from 'react';
import { AppInit, GuiTestAppInit } from './app/AppInit';
import './App.css';
import './custom.scss';
import { Root } from './app/Root';
import { Container } from 'react-bootstrap';

const GUI_TEST = false;

// undefined if not Gui Test mode.
GUI_TEST ? GuiTestAppInit() : AppInit();

const App: React.FC = () => {
  return (
    <Container>
      <Root />
    </Container>
  );
}

export default App;
