/* eslint-disable max-len */
export const XLations = {
  fr: {
    translation: {
      test: {
        testval: "true"
      },
      global: {
        // eslint-disable-next-line
        notAlphanumRegexp: "[^A-Za-z0-9 àéèêëîïôöùç\-]",
        errcodes: {
          unknown: "Erreur de communication",
          timeout: "Pas de réponse du serveur",
          unauthorized: "Droits insuffisants",
          malformed: "Requête malformée",
          info: "Avertissement du serveur",
          'client-error': "Erreur client",
          'svr-error': "Erreur serveur",
          HTTP_UNKNOWN: "Erreur de communication",
          HTTP_TIMEOUT: "Pas de réponse du serveur",
          HTTP_UNAUTHORIZED: "Droits insuffisants",
          HTTP_MALFORMED: "Requête malformée",
          HTTP_CLIENT_ERROR: "Erreur locale",
          HTTP_SERVER_ERROR: "Erreur serveur",
          OK: "ok",
          UNKNOWN: "Erreur",
          MISSING_FIELD: "Tous les champs ne sont pas remplis",
          /** Create or Update scrutin. CloseDate is too far (4 month from now). */
          CLOSEDATE_TOO_FAR: "La date de fin doit être dans moins de 4 mois",
          /** Db Failure or error response. */
          DBFAIL: "Erreur interne (stockage)",
          /** Invalid data provided to the API. */
          ERR_INVALID_INPUT: "Erreur interne (format)",
          /** MAC or SID allocation error. */
          CANT_ALLOCATE_ID: "Erreur interne (allocation)",
          /** Mac and/or ScrutinId does not match a known Scrutin. */
          SCRUTIN_NOT_FOUND: "Le scrutin demandé n'existe pas",
          /** Server-side error: Scrutin found in several States. */
          ERR_INVALID_STATES: "Erreur interne (états)",
          /** Attempt to Update or GoReady a Scrutin in the READY state. */
          CANT_MODIFY_READY: "Un scrutin Validé ne peut plus être modifié",
          /** Attempt to Update, GoReady or BackTesting a Scrutin in the ACTIVE state.  */
          CANT_MODIFY_ACTIVE: "Ce scrutin est en cours - il ne peut plus être modifié.",
          /** Attempt to Update, GoReady or BackTesting a Scrutin in the FINISHED state.  */
          CANT_MODIFY_FINISHED: "Ce scrutin est en terminé - il ne peut plus être modifié.",
          /** Attempt to Update a Scrutin with a CloseDate less than 15-min in the Future. */
          CLOSEDATE_TOO_CLOSE: "La date de fin doit être dans plus de 15 minutes",
          /** Attempt to BackToTesting a Scrutin to be opened in less than 5 minutes.  */
          OPENDATE_TOO_CLOSE: "Ce scrutin va commencer - il ne peut plus être modifié.",
          /** Attempt to cast a TestVote on a Scrutin not TESTING, or SendSecret if neither TESTING nor ACTIVE */
          BAD_STATE_FOR_VOTE: "Le scrutin n'est pas actuellement ouvert",
          /** The VoterId does not match any Voter on this Scrutin.  */
          UNKNOWN_VOTERID: "Numéro d'électeur inconnu",
          /** The Voter Secret does not match the Secret of this Voter, identified by ID. */
          BAD_VOTER_SECRET: "Code secret erroné",
          /** The Email does not match any of the emails associated with this Voter, identified by ID & Secret. */
          BAD_VOTER_MAIL: "Le numéro d'électeur et l'email ne correspondent pas",
          /** In a TestVote or RealVote, the supplied Candidate is not associated with a Candidate. */
          UNKNOWN_CANDIDATE: "Candidat inconnu",
          /** In a TestVote or RealVote, the supplied Voter has already expressed a vote. */
          ALREADY_VOTED: "Vote déjà enregistré pour cet électeur",
          /** In a TestVote or RealVote, more Candidates than nVotes. */
          TOO_MANY_CHOICES: "Trop de candidats sélectionnés",
          /** Attempt to express a RealVote before the scrutin OpenDate. */
          BEFORE_OPENTIME: "Le scrutin n'est pas encore ouvert, recommencez ultérieurement",
          /** Attempt to express a RealVote after the scrutin CloseDate. */
          AFTER_CLOSETIME: "Le scrutin est maintenant fermé, il n'est plus possible de voter",
          /** For a VoterStatus or SendSecret request, the Email does not match any Voter. */
          UNKNOWN_VOTER_MAIL: "Aucun électeur avec cet email",
          /** For an Admin command, wrong login and/or password. */
          BAD_AUTHENTICATION: "Identification erronée",
          /** The User identified by login/password has an insufficient AuthLevel (VIEWER vs ADMIN). */
          INSUFFICIENT_PERMISSION: "Permissions insuffisantes",
          default: ""
        },
        error: "Erreur",
        opfailed: "Echec de ",
        err_empty: "Champ requis",
        err_invalid_mail: "Adresse mail incomplète ou non reconnue",
        messagestatus: {
          /** Could not execute the Query, or determine the error, or temp condition. */
          UNKNOWN: "",
          /** Mail Sent, no error returned. */
          SENT: "message envoyé",
          /** Mail ready, not yet sent (queued) */
          QUEUED: "message en cours d'envoi",
          /** Too many messages sent to this Target in a period of time. Retry later. */
          MAX_MESSAGES_TO_TARGET: "trop de messages envoyés récemment à cette adresse - essayez plus tard",
          /** Error in SendMail operation (could not send the email). */
          SENDING_ERROR: "échec de l'envoi du message - retentez plus tard",
          /** 
           * Problem with the Recipient: USER_UNKNOWN, MAILBOX_INACTIVE, QUOTA_EXCEEDED, INVALID_DOMAIN, NO_MAIL_HOST, 
           * RELAY_ACCESS_DENIED, DOMAIN_TYPOFIX, RECIPIENT_BLACKLISTED. QueryStatus operation.
           */
          RECIPIENT_ISSUE: "le message n'est pas arrivé (adresse erronée, boite pleine, ...)",
          /**
           * Mail was labelled as Spam:
           * SPAM_SENDER_BLOCKED(7),SPAM_CONTENT_BLOCKED(8),SPAM_POLICY_ISSUE(9),DOMAIN_GREYLISTED(13),MAILJET_PREBLOCKED(14),MAILJET_SPAM_PREBLOCKED(16),
           * RECIPIENT_SPAM_REPORTER(21). QueryStatus operation.
           */
          LABELLED_SPAM: "le message a été marqué comme spam et bloqué par votre fournisseur de mail",
          /** Problem with mail content or provider error. */
          OTHER_ERROR: "message non transmis - retentez ultérieurement"
        }
      },
      indexjs: {
        'i18n-inited': "i18n resources loaded."
      },
      basics: {
        LinkCollapsiblePanel: {
          defaultLink: "plus d'infos"
        },
        DayHourRangeSelector: {
          'title-single': "Date",
          'title-range': "Plage de dates",
          'du': "Du",
          'au': "Au",
          'hours': "heures",
          'de': "De",
          'a': "A",
          'inclus': "inclus",
          'err-unset-from': "Sélectionnez une date",
          'err-unset-to': "Sélectionnez une date de fin",
          'err-from-to-order': "La date de fin doit être postérieure au début",
          'err-from-belowmin': "La date de début doit être postérieure au: {{- min}}",
          'err-to-abovemax': "La date de fin doit être antérieure au: {{- max}}",
        },
        DatesManager: {
          maintenant: "maintenant"
        }
      },
      header: {
        global_title: "Vootation",
        know_more: "en savoir plus",
        lang_fr: "français",
        lang_en: "english"
      },
      wrapper: {
        loading: "chargement en cours",
        'err-timeout': "pas de réponse",
        'err-unauthorized': "droits insuffisants",
        'err-server': "erreur serveur",
        'err-other': "erreur de chargement"
      },
      unsync: {
        loading: "Chargement en cours",
        error: "Erreur",
        go_back: "Retour à l'accueil"
      },
      utilviews: {
        error: "Erreur",
        back: "Retour"
      },
      scrutin: {
        badparam: "Les valeurs sont manquantes ou incorrectes",
        mismatch: "Incohérence entre paramètres",
        title: "Vovotes - Créer un Scrutin",
        organame: "Organisé par",
        organame_sub: "Votre nom ou celui de votre organisation",
        organame_ph: "Association xxx",
        orgamail: "Votre email",
        orgamail_sub: "Vous y recevrez le lien pour administrer le scrutin",
        orgamail_ph: "",
        displayname: "Titre du scrutin",
        displayname_sub: "Décrivez l'objet du scrutin en 80 caractères",
        displayname_ph: "Election du conseil d'administration",
        datetitle: "Ouverture du scrutin",
        datetitle_sub: "",
        datetitle_hours: "heures",
        du: "du",
        au: "au",
        close_on_allvoted: "Fermer le scrutin quand tous les électeurs ont voté",
        cancel: "Annuler",
        save: "Suivant",
        delete: "Tout supprimer",
      },
      voters: {
        textlines: "Liste des électeurs",
        textlines_sub: "1 ligne / électeur: une ou plusieurs adresses email, et éventuellement le nom de l'électeur",
        textlines_ph: "John Doe john@doe.com johndoe@gmail.com\njack@outlook.com\nJean <jean@protonmail.com>",
        help_title_how: "Comment entrer la liste des électeurs",
        help_how_1: "Vous pouvez ici composer ou coller la liste des électeurs: un par ligne.",
        help_how_2: "Chaque électeur doit avoir au moins une adresse email; vous pouvez lui associer plusieurs adresses mail: il aura toujours droit à un seul vote.",
        help_how_3: "Les mots autres sont interprêtés comme le nom de l'électeur, et les caractères spéciaux sont ignorés.",
        help_how_4: "Par exemple: 'Monsieur john@doe.com John Doe' est interprêté comme:",
        help_how_5: "Adresses email: 'john@doe.com'",
        help_how_6: "Nom: 'Monsieur John Doe'",
        help_how_7: "La ligne: 'Monsieur John Doe <john@doe.com>' donnera le même résultat.",
        help_title_use: "Usage des adresses email",
        help_use_1: "Les adresses emails sont utilisées EXCLUSIVEMENT pour ce scrutin.",
        help_use_2: "Nous n'enverrons aucun mail promotionnel ou autre à ces participants.",
        help_use_3: "Nous ne partageons pas ces adresses.",
        save: "Suivant",
        verify: "Vérifier",
        found_contacts: "Nombre d'électeurs'",
        errored_lines: "Lignes erronées",
        no_contact: "Il faut au moins un électeur"
      },
      candidates: {
        textlines: "Liste des candidats ",
        textlines_sub: "1 ligne / candidat: sera affiché tel quel aux participants",
        textlines_ph: "Jean\nJack",
        help_title_how: "Comment entrer la liste des candidats",
        help_how_1: "Vous pouvez ici composer ou coller la liste des candidats: un par ligne.",
        help_how_2: "Chaque électeur pourra voter une seule fois, il a le droit de sélectionner un ou plusieurs candidats",
        help_how_3: "(selon votre choix dans la case ci-dessus)",
        num_votes: "Nombre de candidats par électeur",
        num_votes_sub: "L'électeur pourra choisir un ou plusieurs candidats",
        per_voter: "seul candidat sélectionnable pour chaque électeur",
        per_voter_plural: "candidats sélectionnables pour chaque électeur",
        err_missingvalue: "valeur numérique attendue",
        err_maxvotes: "ne peut être supérieur au nombre de candidats",

        save: "Suivant",
        found_contacts: "Nombre de candidats distincts",
        errored_lines: "Lignes erronées",
        no_contact: "Il faut au moins un candidat",
        bad_nvotes: "Valeur numérique attendue"
      },
      summary: {
        head: "Résumé",
        head_sub: "du scrutin",
        test_mode: "Le scrutin est prêt à être testé.",
        has_errors: "Les informations du scrutin sont incomplètes: compléter et enregistrer tous les onglets.",
        scrutin_dn: "Scrutin",
        orga_dn: "Organisé par",
        orga_mail: "Email de l'organisateur (non communiqué aux électeurs)",
        fromdate: "Ouvert de",
        todate: "à",
        close_on_all_voted: "Clôture anticipée quand tous les électeurs ont voté.",
        vote_nbvotes: "Chaque électeur peut voter une seule fois, en sélectionnant au plus 1 candidat.",
        vote_nbvotes_plural: "Chaque électeur peut voter une seule fois, en sélectionnant au plus {{count}} candidats.",
        candidates: "Liste des candidats",
        voters: "Liste des électeurs",
        gotest: "Enregistrer et Tester",
        vote_info_title: "Déroulement du scrutin",
        vote_link: "Le lien pour voter va vous être communiqué: vous l'envoyez vous-même à tous les électeurs.",
        vote_info1: "Ce lien ouvre une page permettant à un électeur d'entrer son adresse email.",
        vote_info2: "S'il est bien inscrit comme électeur, il peut déclencher l'envoi du code confidentiel de vote à cette adresse mail.",
        vote_info3: "En cliquant sur ce lien, l'électeur voit les {{ncands}} candidats et peut en sélectionner au plus {{nvotes}}.",
        vote_info4: "Le vote est définitif: une fois validé, il ne peut plus être modifié.",
        vote_info5: "Les résultats ne sont pas accessibles pendant la durée d'ouverture du scrutin. Ils indiqueront le nombre de voix par candidat et le taux de participation."
      },
      adminview: {
        missing_urlparams: "Cette page ne correspond à aucun scrutin (URL incomplète)",
        back_on_error: "Retour à l'accueil",
        loading: "Chargement en cours"
      },
      testview: {
        test_1: "Ce scrutin est actuellement en mode test.",
        test_2: "Vous pouvez modifier le scrutin.",
        test_3: "Vous pouvez voter à la place de tout électeur: tous les emails vous sont envoyés, le code est toujours 123456.",
        test_4: " (les votes de test seront supprimés lors de la Validation)",
        test_5: "Vous pouvez voir l'évolution des résultats en temps réel.",
        test_6: " (lors du vote réel, seul le taux de participation est visible)",
        test_update: "Modifier le scrutin",
        testvote_text: "Pour tester le processus de vote",
        testvote_link: "faire des votes de test",
        testresults_text: "Pour voir les résultats de vos votes de test",
        testresults_link: "ouvrir la page de résultats",
        test_refresh: "mettre à jour",
        candidate: "Candidat",
        nbvotes: "Nombre de voix",
        maintenant: "maintenant",
        validate_info1: "Pour valider le scrutin et quitter le mode test, cliquez sur le bouton 'Valider' :",
        validate_info2: "Vous pourrez revenir en mode test ou modifier le scrutin avant le {{- lastmodif}}.",
        validate_info2_irreversible: "L'heure d'ouverture du scrutin est proche ou passée: cette action est irréversible, le scrutin sera ouvert sans délai.",
        validate_info3: "",
        validate_info4: "Les votes seront acceptés du {{- min}} au {{- max}} inclus. Les résultats seront ensuite accessibles à vous et à tous les électeurs.",
        validate_info4_irreversible: "Les votes seront acceptés jusqu'au {{- max}} inclus. Les résultats seront ensuite accessibles à vous et à tous les électeurs.",
        validate_info5: "En cours de scrutin, la seule information disponible est le taux de participation (et les résultats d'Audit).",
        validate: "Valider le scrutin",
        validate_err_enddate: "Le scrutin ne peut pas être validé: date de fin trop proche.",
        willhavemail: "Vous allez recevoir un mail avec le lien d'administration"
      },
      readyview: {
        ready_1: "Ce scrutin est validé.",
        future_start: "Il sera ouvert au vote du {{-min}} au {{-max}}",
        imminent_start1: "Il va être ouvert au vote dans les prochaines minutes",
        imminent_start2: ", et fermera le {{-max}}",
        refresh: "mettre à jour",
        access_link: "Communiquez vous-même à tous les électeurs le lien ci-dessous pour voter: ",
        results_link: "Page de résultats du scrutin: ",
        back_to_test: "Jusqu'à 5 minutes avant l'ouverture, il est possible de ",
        back_to_test_link: "revenir à l'étape précédente",
        view_scrutin: "Détails du scrutin",
        vote_link: "Envoyez vous-même le lien d'accès à tous les électeurs.",
      },
      activeview: {
        active_1: "Ce scrutin est en cours.",
        end_date: "Il fermera le {{-max}}",
        access_link: "Les électeurs doivent utiliser le lien ci-dessous pour voter: ",
        results_link: "Les résultats seront accessibles après la fermeture du scrutin, à cette adresse: ",
        audit_link: "Pour voir qui a déjà voté et les mails envoyés, demandez un ",
        get_audit: "Audit du scrutin",
        support_link: "En cas de problème, envoyez un mail à",
        no_modif: "Pour respecter l'intégrité du vote, aucune modification au scrutin n'est permise",
        view_scrutin: "Détails du scrutin",
        vote_link: "Le lien d'accès doit avoir été transmis à tous les électeurs.",
      },
      finishedview: {
        finished_1: "Ce scrutin est clos.",
        dates_span: "Dates d'ouverture définies du {{-min}} au {{-max}}",
        close_on_all_voted: "Clôture anticipée quand tous les électeurs ont voté.",
        view_scrutin: "Détails du scrutin",
        results_link: "Accéder aux résultats (accessibles à tous).",
        get_audit: "Audit du scrutin",
        loading_audit: "Audit en cours",
      },
      auditview: {
        bad_state: "L'audit n'est disponible que pour les scrutins en cours ou terminés.",
        badge_active: "en cours",
        badge_finished: "terminé",
        head: "Audit",
        head_sub: "du scrutin",
        scrutin_dn: "Scrutin",
        orga_dn: "Organisé par",
        orga_mail: "Email de l'organisateur",
        fromdate: "Ouvert de",
        todate: "à",
        close_on_all_voted: "Clôture anticipée quand tous les électeurs ont voté.",
        vote_nbvotes: "Chaque électeur peut voter une seule fois, en sélectionnant au plus 1 candidat.",
        vote_nbvotes_plural: "Chaque électeur peut voter une seule fois, en sélectionnant au plus {{count}} candidats.",
        candidates: "Liste des candidats",
        voter_statuses: "Electeurs",
        voted: "ont voté",
        badgeno: "n'a pas voté",
        showdetail: "messages",
        sent_messages: "messages envoyés",
        msg_type: "type",
        mtype_CODE_MAIL: "envoi du code confidentiel",
        mtype_FINISHED_MAIL: "notification de clôture du scrutin",
        mtype_ORGA_MAIL: "message à l'organisateur",
        msg_time: "le",
        msg_dest: "destinataire",
        msg_status: "état",
        no_msg: "aucun message envoyé",
        refresh_mail: "vérifier",
      },
      results: {
        missing_urlparams: "Cette page ne correspond à aucun scrutin (URL incomplète)",
        back_on_error: "Retour à l'accueil",
        loading: "Chargement en cours",
        scrutin_not_found: "Ce scrutin n'est pas encore ouvert, ou n'existe pas",
        head: "Résultat",
        head_sub: "du scrutin",
        err_inprogress: "Le scrutin est en cours. Seul le taux de participation est visible.",
        scrutin_dn: "Scrutin",
        orga_dn: "Organisé par",
        orga_mail: "Email de l'organisateur (non communiqué aux électeurs)",
        fromdate: "Ouvert du",
        todate: "au",
        vote_nbvotes: "Chaque électeur peut voter une seule fois, en sélectionnant au plus 1 candidat.",
        vote_nbvotes_plural: "Chaque électeur peut voter une seule fois, en sélectionnant au plus {{count}} candidats.",
        liste: "liste",
        candidates_list: "Liste des candidats",
        results: "Résultats",
        no_results: "Le scrutin doit être clos pour pouvoir accéder aux résultats",
        blankornul: "Blancs ou Nuls",
        participation: "Taux de participation",
        nbVotes: "Nombre d'électeurs ayant exprimé leur voix",
        nbVoters: "Nombre total d'électeurs",
        candidate: "Candidat",
        nbvotes: "Nombre de voix",
        view_scrutin: "Détails du scrutin",
        delete: "Supprimer le scrutin",
        delete_cond: "Un scrutin peut être supprimé soit avant sa date d'ouverture, soit un jour après sa date de clôture.",
        info_title: "Merci !",
        info1: "Merci d'avoir utilisé ce service. Si vous en avez été satisfait, parlez-en autour de vous :)"
      },
      scrutinstatus: {
        local_edit: "Le scrutin est en cours de création.",
        in_test: "Le scrutin est en cours de test.",
        validated: "Le scrutin est validé et sera ouvert aux votes le {{- min}}",
        open: "Le scrutin est actuellement ouvert et les électeurs peuvent voter. Il fermera le {{-max}}.",
        closed: "Le scrutin est terminé.",
        cancelled: "Le scrutin a été annulé.",
        unknown: "Etat inconnu (une erreur est survenue)."
      },
      navbar: {
        scrutin: 'scrutin',
        voters: 'électeurs',
        candidates: 'candidats',
        summary: 'résumé',
        test: 'test',
        results: 'résultats'
      },
      editor: {
        crea_title: "Création d'un nouveau scrutin",
        edit_title: "Edition du scrutin",
        tabname_0: "description",
        tabname_1: "électeurs",
        tabname_2: "candidats",
        tabname_3: "résumé",
        cancel_title: "Informations non sauvegardées",
        cancel_create: "Abandonner la création du scrutin ?",
        cancel_edit: "Abandonner les modifications ?",
        cancel_btn_yes: "Abandonner",
        cancel_btn_no: "Ne pas abandonner",
        error: "Erreur"
      },
      votepage: {
        organizer: "Organisé par",
        openfromto: "Période d'ouverture",
        opento: "Ouvert jusqu'au",
        initialloading: "Chargement en cours...",
        scrutin_not_found1: "Ce scrutin n'est pas ouvert au vote",
        scrutin_not_found2: "ou l'adresse URL est erronée",
        comm_error: "Reessayez ultérieurement",
        emailfield: "Entrez votre email pour recevoir votre code",
        validate_mail: "Valider",
        unknown_voter_mail: "Cet email ne correspond à aucun électeur.",
        oldmessagesent: "Un email avec votre code confidentiel vous avait été envoyé.",
        oldmessagestatus: "si vous ne n'avez pas reçu ou ne le retrouvez pas, demandez son renvoi",
        recentmessagesent: "Envoi d'un email avec votre code confidentiel...",
        mail_valid: "email validé",
        codefield: "Consultez votre mail et entrez le code",
        testmode: "Mode test: tous les emails vous sont envoyés et le code est 123456",
        validate_secret: "Vérifier",
        secret_valid: "code validé",
        sendbtn: {
          SEND: "m'envoyer le code par email",
          RESEND: "renvoyer le code par email",
          INPROGRESS: "récemment envoyé",
        },
        sending_code: "envoi du code",
        sendsecret_tooltip: "cet email doit correspondre à un électeur",
        hasvotedat: "a voté le",
        candidates: "Choisissez un candidat",
        candidates_plural: "Choisissez au plus {{count}} candidats",
        blank: "Vote blanc",
        maxcandidates: "Trop de candidats sélectionnés: maximum {{count}}",
        voteisfinal: "Le choix est définitif",
        candselect: {
          show_btn: "Voter",
          title: "Choisissez un candidat",
          title_plural: "Choisissez au plus {{count}} candidats",
          cancel_btn: "Annuler",
          vote_btn: "Voter"
        }
      },
      accueil: {
        title: "Le vote électronique sûr et gratuit",
        subtitle: "Pour tous - associations, entreprises, particuliers,...",
        accroche: "Créez votre scrutin en quelques clics (pas de création de compte requise, pas de réutilisation de vos données personnelles)",
        create_btn: "Créer un scrutin",
        know_more: "en savoir plus",
        card_title1: "Bulletin secret",
        card_text1: "Même l'organisateur ne peut pas voir les votes. En cours de scrutin, seul le taux de participation est visible (pas de résultats partiels).",
        card_title2: "Testable et Auditable",
        card_text2: "Avant la mise en ligne, l'organisateur peut faire des votes de test et voir l'ensemble de la procédure.",
        card_text2B: "Ensuite, il peut Auditer le scrutin pour voir qui a voté (mais pas pour qui).",
        card_title3: "No spam",
        card_text3: "Aucun email non sollicité: c'est vous qui envoyez le lien pour voter aux électeurs, et leur adresse mail n'est utilisée que pour s'authentifier. Aucune réutilisation de ces adresses, aucun mail promotionnel.",
      },
      about: {
        title: "A propos de Vootation",
        accro1: "Le vote à bulletin secret pour tous (associations, particuliers, groupes, sociétés...).",
        accro2: "Gratuit et sans pub. Aucune réutilisation des données personnelles.",
        progress: "Déroulement",
        progress1: "Les électeurs inscrits sur la liste électorale votent à bulletin secret",
        progress1sub: "--même l'organisateur ne peut pas voir les votes",
        progress2: "La Vootation se déroule pendant une période de temps définie à l'avance.",
        progress2sub: "--aucun vote n'est accepté avant ou après",
        progress3: "En cours de Vootation, seul le taux de participation est affiché",
        progress3sub: "--les résultats partiels ne sont pas accessibles",
        progress4: "Le résultat final est visible seulement à la clôture de la Vootation",
        progress4sub: "--le taux de participation, et le nombre global de voix pour chaque candidat",
        progress5: "L'organisateur peut lancer un Audit du scrutin",
        progress5sub: "--qui a voté, à quel moment (mais pas pour qui)",

        orga: "Organiser une Vootation",
        orga1: "1. Créez gratuitement une Vootation, avec:",
        orga1p1: "la liste électorale (au minimum: 1 mail par électeur)",
        orga1p1sub: "--un électeur peut avoir plusieurs mails, pour faciliter son authentification; mais un seul vote",
        orga1p2: "la liste des candidats",
        orga1p3: "les dates et paramètres du scrutin (choix simple ou multiple, ...)",
        orga2: "2. Testez la Vootation:",
        orga2p1: "Avant l'ouverture du scrutin, vous pouvez faire des votes de test et tester tout le processus",
        orga3: "3. Obtenez Le lien de la Vootation et envoyez le vous même aux électeurs (c'est le même pour tous)",
        orga3p1: "Aucun email non sollicité n'est envoyé par Vootation.",
        orga4: "4. Un électeur clique sur le Lien de la Vootation:",
        orga4p1: "il est invité à entrer son adresse email.",
        orga4p2: "Si elle correspond à un électeur enregistré, un code confidentiel est envoyé sur cet email pour lui permettre de voter.",

        polls: "FAQ - Les scrutins",
        polls1: "1. En cas de problème technique:",
        polls1p1: "Si le problème concerne le scrutin lui-même (votre mail est inconnu, les candidats ne sont pas les bons, etc.) contactez l'organisateur du scrutin.",
        polls1p2: "S'il s'agit d'un 'plantage' du service, envoyez un mail à: support @vootation.com",
        polls1p2sub: "--incluez obligatoirement l'URL de la page dans laquelle vous vous trouvez, le scrutin, la description du problème et si possible une capture d'écran",
        polls2: "2. Qu'est-ce qui est visible pendant la durée de la Vootation ?",
        polls2p1: "Pendant la durée de la Vootation, seul le taux de participation est visible.",
        polls2p2: "L'organisateur peut demander un Audit pour voir qui a déjà voté (mais pas les résultats)",
        polls2p3: "Quand une Vootation est en cours, elle ne peut être modifiée: impossible, donc, d'avancer la date de fin pour voir les résultats.",
        polls3: "3. Quand peut-on voter ?",
        polls3p1: "Les électeurs enregistrés peuvent voter dans la plage d'ouverture définie par l'organisateur.",
        polls3p2: "Si cela a été autorisé par l'organisateur, la Vootation peut être automatiquement fermée dès que tous les électeurs ont voté.",
        polls4: "4. Qu'est-ce qui est visible après la fin de la Vootation ?",
        polls4p1: "Les résultats complets sont disponibles:",
        polls4p1p1: "Taux de participation",
        polls4p1p2: "Nombre de voix reçues par chaque candidat",
        polls4p1p3: "Nombre de votes blancs",
        polls4p2: "Mais qui a voté pour qui n'est jamais dévoilé.",
        polls5: "5. Peut - on sélectionner plusieurs candidats? voter blanc?",
        polls5p1: "Quand il crée la Vootation, l'organisateur choisit le nombre maximal de Candidats sélectionnables par un électeur.",
        polls5p2: "Le vote blanc est autorisé.",
        polls6: "6. Que se passe-t'il si une même adresse mail correspond à plusieurs électeurs ?",
        polls6p1: "Quand il crée la Vootation, l'organisateur entre la liste des électeurs dans un champ 'une ligne = un électeur'",
        polls6p2: "Pour chaque ligne, il doit y avoir au moins un email pour authentifier l'electeur, plus éventuellement son nom/titre/... et des emails alternatifs.",
        polls6p3: "Il est tout à fait possible que plusieurs électeurs correspondent à la même adresse email.",
        polls6p4: "Au moment du vote, après avoir entré le code reçu par email, le système affichera une ligne par 'électeur' correspondant, avec un vote pour chacun.",
        polls6p5: "(si on a deux électeurs 'X1 <x@mail.com>' et 'X2 <x@mail.com>', le destinataire du mail pourra voter deux fois, une pour X1 et une pour X2)",
        polls6p6: "Ceci permet en particulier de gérer les cas où plusieurs électeurs ont une même adresse email (familiale, ...) et les cas où une personne a plusieurs droits de vote.",

        voo: "FAQ - Le service Vootation",
        voo0: "1. Garanties et Responsabilité ?",
        voo0p1: "Le service est fourni 'tel quel', sans garantie d'aucune sorte, explicite ou implicite, notamment sans garantie de qualité marchande, d'adéquation à un usage particulier et d'absence de contrefaçon.",
        voo0p2: "En aucun cas, l'auteur ou les titulaires du droit d'auteur ne seront responsables de tout dommage, réclamation ou autre responsabilité, que ce soit dans le cadre d'un contrat, d'un délit ou autre, en provenance de, consécutif à ou en relation avec le Service ou son utilisation.",
        voo0p3: "Aucune garantie n'est donnée que ce service se comporte comme attendu, continue de fonctionner et soit supporté dans le futur, et qu'il ne comporte pas de bugs ou autres problèmes pouvant impacter les données traitées, la sécurite, ou l'usage qui en est fait.",
        voo1: "2. Usage des données personnelles",
        voo1p1: "Les informations que vous fournissez pour organiser la Vootation (vos coordonnées, le titre, les électeurs et candidats...) sont utilisées exclusivement pour fournir le service.",
        voo1p2: "En particulier, les adresses emails sont utilisées exclusivement pour cette Vootation.",
        voo1p3: "Nous n'enverrons aucun mail promotionnel ou autre, et nous ne partageons ces adresses avec personne.",
        voo2: "3. Ce service est gratuit et sans publicité - pourquoi ?",
        voo2p1: "Je n'en ai pas besoin pour vivre. L'idée est de proposer à tous (associations, etc.) un service de vote à bulletin secret sûr, pratique et gratuit",
        voo3: "4. Ce service est-il destiné aux élections publiques ?",
        voo3p1: "Non, il n'est pas conçu pour cela.",
        voo3p2: "D'une part, il ne traite pas toutes les problématiques liées à l'utilisation du vote à distance dans une élection publique (isoloir, vote forcé ou sous surveillance d'autrui, capacité pour chacun de vérifier la transparence et l'intégrité du système ...).",
        voo3p3: "D'autre part, même si le niveau de sécurité est bon, il ne prétend pas être capable de résister à l'attaque de cyber-criminels de haut niveau (groupes étatiques, organisations criminelles) susceptibles d'être intéressés par l'altération des résultats d'une élection publique.",
        voo4: "5. Technologies ?",
        voo4p1: "Le service de backend suit une architecture de micro-services stateless, implémentés en programmation totalement réactive (asynchronisme jusqu'aux opérations de DB, sans blocage). Sur une base Java, avec seulement Netty côté réseau, on obtient un excellent niveau de performance.",
        voo4p2: "Les front-ends (gestion des Vootation, Vote, Admin, ...) sont, classiquement, en ReactJS (code Typescript) avec un minimum de librairies pour minimiser la taille des applis et avoir une meilleure réactivité.",
        voo4p3: "Le système est conçu pour être fortement scalable, avec un 'sharding' complet des Vootations pour scaler horizontalement.",
        voo4p4: "Tous les services sont conteneurisés. L'ensemble du système est redéployable sur n'importe quel service cloud.",
        voo5: "6. Contact",
        voo5p1: "Auteur: Philippe Martinou, philippe.martinou@vootation.com"
      }
    }
  },
  en: {
    translation: {
      test: {
        testval: "true"
      },
      global: {
        // eslint-disable-next-line
        notAlphanumRegexp: "[^A-Za-z0-9 àéèêëîïôöùç\-]",
        errcodes: {
          unknown: "Communication error",
          timeout: "No response",
          unauthorized: "Not authorized",
          malformed: "Malformed request",
          info: "Server warning",
          'client-error': "Client error",
          'svr-error': "Server error",
          HTTP_UNKNOWN: "Communication error",
          HTTP_TIMEOUT: "No response",
          HTTP_UNAUTHORIZED: "Not authorized",
          HTTP_MALFORMED: "Malformed request",
          HTTP_CLIENT_ERROR: "Local error",
          HTTP_SERVER_ERROR: "Serveur-side error",
          OK: "ok",
          UNKNOWN: "Error",
          MISSING_FIELD: "Missing field(s)",
          /** Create or Update scrutin. CloseDate is too far (4 month from now). */
          CLOSEDATE_TOO_FAR: "Close date is too far (max. 4 month from now)",
          /** Db Failure or error response. */
          DBFAIL: "Internal error (storage)",
          /** Invalid data provided to the API. */
          ERR_INVALID_INPUT: "Internal error (invalid input)",
          /** MAC or SID allocation error. */
          CANT_ALLOCATE_ID: "Internal error (allocation)",
          /** Mac and/or ScrutinId does not match a known Scrutin. */
          SCRUTIN_NOT_FOUND: "The requested election has not been found",
          /** Server-side error: Scrutin found in several States. */
          ERR_INVALID_STATES: "Internal error (wrong state)",
          /** Attempt to Update or GoReady a Scrutin in the READY state. */
          CANT_MODIFY_READY: "The election is Ready to start - it cannot be modified.",
          /** Attempt to Update, GoReady or BackTesting a Scrutin in the ACTIVE state.  */
          CANT_MODIFY_ACTIVE: "The election is in progress - it cannot be modified",
          /** Attempt to Update, GoReady or BackTesting a Scrutin in the FINISHED state.  */
          CANT_MODIFY_FINISHED: "The election is terminated - it cannot be modified.",
          /** Attempt to Update a Scrutin with a CloseDate less than 15-min in the Future. */
          CLOSEDATE_TOO_CLOSE: "The closing date must be in at least 15 minutes",
          /** Attempt to BackToTesting a Scrutin to be opened in less than 5 minutes.  */
          OPENDATE_TOO_CLOSE: "The election is about to begin - it cannot be modified.",
          /** Attempt to cast a TestVote on a Scrutin not TESTING, or SendSecret if neither TESTING nor ACTIVE */
          BAD_STATE_FOR_VOTE: "The polls are not currently open",
          /** The VoterId does not match any Voter on this Scrutin.  */
          UNKNOWN_VOTERID: "Unknown voter id",
          /** The Voter Secret does not match the Secret of this Voter, identified by ID. */
          BAD_VOTER_SECRET: "Bad voter secret",
          /** The Email does not match any of the emails associated with this Voter, identified by ID & Secret. */
          BAD_VOTER_MAIL: "The voter id and the email do not match",
          /** In a TestVote or RealVote, the supplied Candidate is not associated with a Candidate. */
          UNKNOWN_CANDIDATE: "Unknown candidate",
          /** In a TestVote or RealVote, the supplied Voter has already expressed a vote. */
          ALREADY_VOTED: "This Voter has already voted",
          /** In a TestVote or RealVote, more Candidates than nVotes. */
          TOO_MANY_CHOICES: "Too many candidates selected",
          /** Attempt to express a RealVote before the scrutin OpenDate. */
          BEFORE_OPENTIME: "The polls are not yet open, please retry later",
          /** Attempt to express a RealVote after the scrutin CloseDate. */
          AFTER_CLOSETIME: "The polls are now closed, new votes cannot be accepted any more",
          /** For a VoterStatus or SendSecret request, the Email does not match any Voter. */
          UNKNOWN_VOTER_MAIL: "This email is not associated with any Voter",
          /** For an Admin command, wrong login and/or password. */
          BAD_AUTHENTICATION: "Bad authentication",
          /** The User identified by login/password has an insufficient AuthLevel (VIEWER vs ADMIN). */
          INSUFFICIENT_PERMISSION: "Insufficient permissions",
          default: ""
        },
        error: "Error",
        opfailed: "Op. Failed ",
        err_empty: "Required",
        err_invalid_mail: "Invalid email address",
        messagestatus: {
          /** Could not execute the Query, or determine the error, or temp condition. */
          UNKNOWN: "",
          /** Mail Sent, no error returned. */
          SENT: "message sent",
          /** Mail ready, not yet sent (queued) */
          QUEUED: "message queued",
          /** Too many messages sent to this Target in a period of time. Retry later. */
          MAX_MESSAGES_TO_TARGET: "too many messages recently sent to this email - please retry later",
          /** Error in SendMail operation (could not send the email). */
          SENDING_ERROR: "mail sending error - please retry later",
          /** 
           * Problem with the Recipient: USER_UNKNOWN, MAILBOX_INACTIVE, QUOTA_EXCEEDED, INVALID_DOMAIN, NO_MAIL_HOST, 
           * RELAY_ACCESS_DENIED, DOMAIN_TYPOFIX, RECIPIENT_BLACKLISTED. QueryStatus operation.
           */
          RECIPIENT_ISSUE: "message could not be delivered (bad mail address, mailbox overflow, ...)",
          /**
           * Mail was labelled as Spam:
           * SPAM_SENDER_BLOCKED(7),SPAM_CONTENT_BLOCKED(8),SPAM_POLICY_ISSUE(9),DOMAIN_GREYLISTED(13),MAILJET_PREBLOCKED(14),MAILJET_SPAM_PREBLOCKED(16),
           * RECIPIENT_SPAM_REPORTER(21). QueryStatus operation.
           */
          LABELLED_SPAM: "message was labelled spam and blocked by your mail provider",
          /** Problem with mail content or provider error. */
          OTHER_ERROR: "message not sent - please retry later"
        }
      },
      indexjs: {
        'i18n-inited': "i18n resources loaded."
      },
      basics: {
        LinkCollapsiblePanel: {
          defaultLink: "more info"
        },
        DayHourRangeSelector: {
          'title-single': "Date",
          'title-range': "Date range",
          'du': "From",
          'au': "to",
          'hours': "hours",
          'de': "From",
          'a': "To",
          'inclus': "inclusive",
          'err-unset-from': "Choose a date",
          'err-unset-to': "Choose an end date",
          'err-from-to-order': "The end date must be later than the start date",
          'err-from-belowmin': "The start date must be after: {{- min}}",
          'err-to-abovemax': "The end date must be before: {{- max}}",
        },
        DatesManager: {
          maintenant: "now"
        }
      },
      header: {
        global_title: "Vootation",
        know_more: "more info",
        lang_fr: "français",
        lang_en: "english"
      },
      wrapper: {
        loading: "loading",
        'err-timeout': "no response",
        'err-unauthorized': "unauthorized",
        'err-server': "server error",
        'err-other': "loading error"
      },
      unsync: {
        loading: "Loading in progress",
        error: "Error",
        go_back: "Back to home page"
      },
      utilviews: {
        error: "Error",
        back: "Back"
      },
      scrutin: {
        badparam: "Missing or invalid values",
        mismatch: "Parameters mismatch",
        title: "Vovotes - Create an Election",
        organame: "Organized by",
        organame_sub: "Your name or your organization's name",
        organame_ph: "Association, ...",
        orgamail: "Your email",
        orgamail_sub: "Election management links will be sent to this email",
        orgamail_ph: "",
        displayname: "Election title",
        displayname_sub: "Describe the purpose of the Election in 80 chars",
        displayname_ph: "Election of the steering comitee",
        datetitle: "Opening date",
        datetitle_sub: "",
        datetitle_hours: "hours",
        du: "from",
        au: "to",
        cancel: "Cancel",
        save: "Next",
        delete: "Delete all",
      },
      voters: {
        textlines: "Voter's list",
        textlines_sub: "1 line / voter: one or more emails, and possibly the voter's name",
        textlines_ph: "John Doe john@doe.com johndoe@gmail.com\njack@outlook.com\nJean <jean@protonmail.com>",
        help_title_how: "How to compose the Voter's list",
        help_how_1: "The Voter's list can be composed or pasted here: one Voter for each line.",
        help_how_2: "Each Voter must have at least one email address; several emails are allowed on the same line: he will be entitled to only one vote.",
        help_how_3: "Words not part of an email address are interpreted as the Voter's name, and special characters are ignored.",
        help_how_4: "For exemple: 'Sir john@doe.com John Doe' is interpreted as:",
        help_how_5: "Email addresses: 'john@doe.com'",
        help_how_6: "Voter's name: 'Sir John Doe'",
        help_how_7: "The line: 'Sir John Doe <john@doe.com>' will give the same result.",
        help_title_use: "Use of email addresses",
        help_use_1: "The email addresses are used ONLY for this election.",
        help_use_2: "We will not send any promotional or other mail to these participants.",
        help_use_3: "We will not share these addresses.",
        save: "Next",
        verify: "Check",
        found_contacts: "Number of Voters'",
        errored_lines: "Lines with errors",
        no_contact: "Please enter at least one Voter"
      },
      candidates: {
        textlines: "List of candidates ",
        textlines_sub: "1 line / candidate: will be displayed as is to Voters",
        textlines_ph: "Jean\nJack",
        help_title_how: "How to compose the list of candidates",
        help_how_1: "The list of candidates can be composed or pasted here: one per line.",
        help_how_2: "Each voter can vote only once, and has the right to select one or more candidates",
        help_how_3: "(according to your choice in the field above)",
        num_votes: "Number of candidates per Voter",
        num_votes_sub: "Each Voter can choose one or more candidates",
        per_voter: "candidate only can be chosen by each Voter",
        per_voter_plural: "candidates can be chosen by each Voter",
        err_missingvalue: "number expected",
        err_maxvotes: "cannot be greater than the number of candidates",

        save: "Next",
        found_contacts: "Number of candidates",
        errored_lines: "Lines with errors",
        no_contact: "Please enter at least one candidate",
        bad_nvotes: "number expected"
      },
      summary: {
        head: "Summary",
        head_sub: "of the Election",
        test_mode: "The Election is ready for testing.",
        has_errors: "Incomplete Election data: please complete the fields of all tabs.",
        scrutin_dn: "Election",
        orga_dn: "Organized by",
        orga_mail: "Organizer's email (not shown to Voters)",
        fromdate: "Open from",
        todate: "to",
        close_on_all_voted: "Early closing when all Voters have voted.",
        vote_nbvotes: "Each Voter can vote only once, selecting at most 1 candidate.",
        vote_nbvotes_plural: "Each Voter can vote only once, selecting at most {{count}} candidates.",
        candidates: "List of candidates",
        voters: "List of Voters",
        gotest: "Save and start testing",
        vote_info_title: "Voting process",
        vote_link: "The link to vote will be provided to you: You send this Link to all Voters.",
        vote_info1: "This Link opens a page where the Voter enters his email address.",
        vote_info2: "If he is registered as a Voter, he can trigger the sending of the confidential voting code to this email address.",
        vote_info3: "By clicking on this link, the Voter sees the {{ncands}} candidates and can select at most {{nvotes}}.",
        vote_info4: "The vote is final: once validated, it cannot be modified.",
        vote_info5: "The results are not available while the polls are open. They will show the number of votes per candidate and the turnout."
      },
      adminview: {
        missing_urlparams: "This page does not correspond to any Election (incomplete URL)",
        back_on_error: "Back to home page",
        loading: "Loading in progress"
      },
      testview: {
        test_1: "This Election is currently in test mode.",
        test_2: "The Election information can be modified.",
        test_3: "You can vote in place of any Voter: all emails are sent to you, the code is always 123456.",
        test_4: " (all test votes will be discarded upon Validation)",
        test_5: "The (testing) results are available.",
        test_6: " (during the real polls, only the turnout is available)",
        test_update: "Modify Election information",
        testvote_text: "To test the vote process",
        testvote_link: "open the test vote page",
        testresults_text: "To view the results in real time",
        testresults_link: "open the test results page",
        test_refresh: "refresh",
        candidate: "Candidate",
        nbvotes: "Number of votes",
        maintenant: "now",
        validate_info1: "To validate the Election and exit the test mode, click on the 'Validate' button: ",
        validate_info2: "You can revert the Election to test mode before {{- lastmodif}}.",
        validate_info2_irreversible: "The opening time of the Election is near or past: this action is irreversible, the poll will be opened without delay.",
        validate_info3: "",
        validate_info4: "The votes will be accepted from {{- min}} to {{- max}} inclusive. The results will then be available to you and all voters.",
        validate_info4_irreversible: "The votes will be accepted until {{- max}} inclusive. The results will then be available to you and all voters.",
        validate_info5: "While the polls are open, the only information available is the turnout (and who voted, using Audit).",
        validate: "Validate the Election",
        validate_err_enddate: "The Election cannot be validated: end date too close.",
        willhavemail: "You will receive an email with the administration link"
      },
      readyview: {
        ready_1: "This Election is validated.",
        future_start: "The polls will be open from {{-min}} to {{-max}}",
        imminent_start1: "The polls will open in the next few minutes",
        imminent_start2: ", and will close at {{-max}}",
        refresh: "refresh",
        access_link: "Please send to all Voters the Link to vote below: ",
        results_link: "Link to the Results page: ",
        back_to_test: "Until 5 minutes before the opening, it is possible to ",
        back_to_test_link: "return to the previous step",
        view_scrutin: "Details of the Election",
        vote_link: "Send the Link to vote to all Voters.",
      },
      activeview: {
        active_1: "The polls are open.",
        end_date: "They will close at {{-max}}",
        access_link: "The Voters must use the following Link to vote: ",
        results_link: "The results will be available after the polls close, at this URL: ",
        audit_link: "To see who has already voted and the emails sent, request an ",
        get_audit: "Election Audit",
        support_link: "In case of problem, send an email to",
        no_modif: "To respect the integrity of the vote, no changes to the Election are allowed",
        view_scrutin: "Details of the Election",
        vote_link: "The Link to vote must have been sent to all Voters.",
      },
      finishedview: {
        finished_1: "The polls are closed.",
        dates_span: "Configured open dates from {{-min}} to {{-max}}",
        close_on_all_voted: "Early closing when all Voters have voted.",
        view_scrutin: "Details of the Election",
        results_link: "Go to the results page (available to all).",
        get_audit: "Election Audit",
        loading_audit: "Audit in progress",
      },
      auditview: {
        bad_state: "The Election Audit is only available after the opening of the polls.",
        badge_active: "in progress",
        badge_finished: "terminated",
        head: "Audit",
        head_sub: "of the Election",
        scrutin_dn: "Election",
        orga_dn: "Organized by",
        orga_mail: "Organizer email",
        fromdate: "Open from",
        todate: "to",
        close_on_all_voted: "Early closing when all Voters have voted.",
        vote_nbvotes: "Each Voter can vote only once, selecting at most 1 candidate.",
        vote_nbvotes_plural: "Each Voter can vote only once, selecting at most {{count}} candidates.",
        candidates: "List of candidates",
        voter_statuses: "Voters",
        voted: "have voted",
        badgeno: "didn't vote",
        showdetail: "messages",
        sent_messages: "sent messages",
        msg_type: "type",
        mtype_CODE_MAIL: "confidential code",
        mtype_FINISHED_MAIL: "notification of the polls closure",
        mtype_ORGA_MAIL: "administration message to organizer",
        msg_time: "at",
        msg_dest: "recipient",
        msg_status: "status",
        no_msg: "no sent message",
        refresh_mail: "check",
      },
      results: {
        missing_urlparams: "This page does not correspond to any Election (incomplete URL)",
        back_on_error: "Back to home page",
        loading: "Loading in progress",
        scrutin_not_found: "The polls have not opened yet, or the Election does not exist.",
        head: "Polling",
        head_sub: "results",
        err_inprogress: "The polls are still open. Only the turnout is available.",
        scrutin_dn: "Election",
        orga_dn: "Organized by",
        orga_mail: "Organizer's email (not shown to Voters)",
        fromdate: "Open from",
        todate: "to",
        vote_nbvotes: "Each Voter can vote only once, selecting at most 1 candidate.",
        vote_nbvotes_plural: "Each Voter can vote only once, selecting at most {{count}} candidates.",
        liste: "list",
        candidates_list: "List of candidates",
        results: "Results",
        no_results: "The polls must be closed to access the results",
        blankornul: "Blank or Invalid",
        participation: "Turnout",
        nbVotes: "Number of Voters having voted",
        nbVoters: "Total number of Voters",
        candidate: "Candidate",
        nbvotes: "Number of votes",
        view_scrutin: "Details of the Election",
        delete: "Delete the Election",
        delete_cond: "An Election can be deleted either before the opening of the polls, or one day after the closing of the polls.",
        info_title: "Thanks !",
        info1: "Thank you for using this service. If you were satisfied with it, please tell others about it :)"
      },
      scrutinstatus: {
        local_edit: "The Election is being created.",
        in_test: "The Election is being tested.",
        validated: "The Election is validated and the polls will open at {{- min}}",
        open: "The polls are now open and the Voters can vote. The polls will close at {{-max}}.",
        closed: "The polls are closed.",
        cancelled: "The Election has been cancelled.",
        unknown: "Unknown status (an error has occurred)."
      },
      navbar: {
        scrutin: 'election',
        voters: 'voters',
        candidates: 'candidates',
        summary: 'summary',
        test: 'test',
        results: 'results'
      },
      editor: {
        crea_title: "Creation of a new Election",
        edit_title: "Edition of an Election",
        tabname_0: "description",
        tabname_1: "voters",
        tabname_2: "candidates",
        tabname_3: "summary",
        cancel_title: "Data has not been saved",
        cancel_create: "Abandon the creation of the Election ?",
        cancel_edit: "Abandon the changes ?",
        cancel_btn_yes: "Abandon",
        cancel_btn_no: "Do not abandon",
        error: "Error"
      },
      votepage: {
        organizer: "Organized by",
        openfromto: "Opening time",
        opento: "Open until",
        initialloading: "Loading in progress...",
        scrutin_not_found1: "The polls are not open now",
        scrutin_not_found2: "or no Election exists for this URL",
        comm_error: "Please retry later",
        emailfield: "Enter your email to receive your voting code",
        validate_mail: "Confirm",
        unknown_voter_mail: "No Voter is registered with this email.",
        oldmessagesent: "An email with your confidential voting code was sent to you.",
        oldmessagestatus: "If you did not receive it or cannot find it, please request a new mailing",
        recentmessagesent: "Sending an email with your confidential voting code...",
        mail_valid: "voter email validated",
        codefield: "Check your email and enter the code",
        testmode: "Test mode: all emails are sent to you, and the code is 123456",
        validate_secret: "Validate",
        secret_valid: "validated code",
        sendbtn: {
          SEND: "send me the code by email",
          RESEND: "send me again the code by email",
          INPROGRESS: "recently sent",
        },
        sending_code: "sending code",
        sendsecret_tooltip: "the email must match a registered Voter",
        hasvotedat: "voted at",
        candidates: "Select a candidate",
        candidates_plural: "Select at most {{count}} candidates",
        blank: "Blank vote",
        maxcandidates: "Too many candidates selected: maximum {{count}}",
        voteisfinal: "The choice is final",
        candselect: {
          show_btn: "Vote",
          title: "select a candidate",
          title_plural: "select at most {{count}} candidates",
          cancel_btn: "Cancel",
          vote_btn: "Vote"
        }
      },
      accueil: {
        title: "Free and secure electronic voting",
        subtitle: "For all - associations, companies, individuals,...",
        accroche: "Create your Election with a few clicks (no need to create an account, no reuse of your personal information)",
        create_btn: "Create an Election",
        know_more: "more info",
        card_title1: "Secret ballot",
        card_text1: "Even the organizer can't see the votes. While the polls are open, only the turnout is visible (no partial results).",
        card_title2: "Testable and Auditable",
        card_text2: "Before going online, the organizer can make test votes and see the whole process.",
        card_text2B: "Then, he can audit the Election and see who voted (but not for whom).",
        card_title3: "No spam",
        card_text3: "No unsollicited email: you send yourself the Link to Vote to the Voters, and their email address is only used for authentication purposes. No reuse of these email addresses, no promotional email.",
      },
      about: {
        title: "Vootation FAQ",
        accro1: "Free and secure electronic voting for all (associations, companies, groups, individuals...).",
        accro2: "Free of charge yet without ads. No reuse of personal information.",
        progress: "Process",
        progress1: "The Voters registered in the Voter's List vote secretly.",
        progress1sub: "--secret ballot, not even the organizer can know the votes",
        progress2: "The Vootation takes place during a pre-determined period of time.",
        progress2sub: "--no vote is accepted before or after",
        progress3: "During the Vootation, only the turnout is visible.",
        progress3sub: "--no partial results, to protect the confidentiality of the votes",
        progress4: "The final result is known only after the polls close.",
        progress4sub: "--with the turnout and the number of votes cast for each candidate",
        progress5: "The organizer can request an Audit of the Vootation",
        progress5sub: "--who has cast a vote, and when (but not for whom)",

        orga: "Organize a Vootation",
        orga1: "1. Create (free of charge) a Vootation, with:",
        orga1p1: "the Voter's List (at least 1 mail for every voter)",
        orga1p1sub: "--a Voter may have several emails to allow an easier authentication; but a single Vote",
        orga1p2: "the Candidate's List",
        orga1p3: "the opening/closing dates and the parameters of the poll (single or multiple candidates, ...)",
        orga2: "2. Test the Vootation:",
        orga2p1: "Before the opening of the Polls, the organizer can do test votes and test the whole process",
        orga3: "3. Get the Link to Vote and send it yourself to all Voters (same link for all)",
        orga3p1: "No unsollicited mail sent by Vootation.",
        orga4: "4. A Voter clicks on the Link To Vote:",
        orga4p1: "he is invited to enter his email address.",
        orga4p2: "If this email matches a registered Voter, the Confidential Code for voting is sent to this address.",

        polls: "FAQ - The polls",
        polls1: "1. In case of technical problems:",
        polls1p1: "If the problem is related to the election itself (your email is unknown, the list of candidates is incorrect, etc.) please contact the organizer.",
        polls1p2: "If it is a crash or malfunction of the Vootation service, please send a mail to: support@vootation.com",
        polls1p2sub: "--always include the URL of the page, the election and the description of the problem (a screenshot is welcome)",
        polls2: "2. What information is visible during the polls ?",
        polls2p1: "While the polls are open, only the turnout is visible.",
        polls2p2: "The organizer can request an Audit to see who has already voted (but not the current results).",
        polls2p3: "When an election is in progress, it cannot be edited anymore, to prevent frauds (altering the candidates, advancing the end date to view partial results, etc.).",
        polls3: "3. When can you vote ?",
        polls3p1: "The registered Voters can vote during the pre-determined poll opening period (defined by the organizer at election creation time).",
        polls3p2: "If the organizer selected this option, the polls can close as soon as all registered Voters have voted.",
        polls4: "4. What information is visible after the closing of the polls ?",
        polls4p1: "The full results are available, with:",
        polls4p1p1: "Turnout",
        polls4p1p2: "Number of Votes for each candidate",
        polls4p1p3: "Number of blank votes",
        polls4p2: "But who voted for who is never disclosed.",
        polls5: "5. Is it possible to select several candidates ? to vote blank ?",
        polls5p1: "At election creation time, the organizer chooses how many candidates a Voter can select when he votes.",
        polls5p2: "Blank votes are allowed.",
        polls6: "6. What happens when the same email address belongs to several Voters ?",
        polls6p1: "At election creation time, the organizer inputs the Voter's List in a text field where 'one line = one Voter'",
        polls6p2: "For each line, at least an email must be provided (for voter authentication), plus optionnally a display name/title/... and additional emails.",
        polls6p3: "It is then quite possible to get the same email address associated with several Voters.",
        polls6p4: "At Vote time, after validation of the Confidential Code sent by email, the system will display one line per Voter, each having the right to vote.",
        polls6p5: "(for example, with 2 Voters 'X1 <x@mail.com>' and 'X2 <x@mail.com>', the owner of the x@mail.com email address can vote twice, once as X1 and once as X2)",
        polls6p6: "This mechanism allows to cope with cases where several Voters have the same email (family address, ...) and cases where a person has several rights to vote.",

        voo: "FAQ - The Vootation service",
        voo0: "1. Liability ?",
        voo0p1: "The service is provided 'as is', without warranty of any kind, express or implied, including but not limited to the warranties of merchantability, fitness for a particular purpose and noninfringement.",
        voo0p2: "In no event shall the author or copyright holders be liable for any claim, damages or other liability, whether in an action of contract, tort or otherwise, arising from, out of or in connection with the service or the use or other dealings in the Service",
        voo0p3: "No warranty is given that the service will perform as intended or expected, continue to work and be supported in the future, and is free of bugs or issues that may result in loss of data, security issues or other problems",
        voo1: "2. Use of your personal data",
        voo1p1: "The information provided by the organizer (contact information, title, voters, candidates...) are used only to provide the service.",
        voo1p2: "In particular, the mail addresses are used only for this specific Vootation.",
        voo1p3: "We will not send promotional or other mails, and we will not share these addresses with third parties.",
        voo2: "3. This service is free of charge and ad-free - why ?",
        voo2p1: "I don't need it for a living. Its purpose is to provide anyone (associations, etc.) with a secret ballot service, secure, easy to use and free",
        voo3: "4. Is this service intended for use in public elections ?",
        voo3p1: "No, it is not intended for this use.",
        voo3p2: "First, many issues related with remote voting for a public election are not addressed (voting booths, forced voting or voting under the supervision of others, ability for everyone to verify the transparency and integrity of the system ...).",
        voo3p3: "Also, even if the security is fair, it is way below what would be needed to resist to high-level cybercriminals (state-backed groups, criminal organizations) likely to be interested in altering the results of a public election.",
        voo4: "5. Technologies ?",
        voo4p1: "The backend service is architected with stateless micro-services, implemented in fully reactive programming (asynchronicity up to the DB operations, without blocking). Coded in Java, with just Netty on the network-side, this results in very good performance levels.",
        voo4p2: "The front-ends (management of Vootations, Vote, Admin, ...) are, classically, based on the ReactJS framework (Typescript code) and use a minimum of librairies to minimize the app size and keep a good responsiveness.",
        voo4p3: "The system is designed with scalability in mind, with an extensive 'sharding' of Vootations for horizontal scaling.",
        voo4p4: "All the services run in containers. The whole system can be re-deployed on any modern cloud service.",
        voo5: "6. Contact",
        voo5p1: "Author: Philippe Martinou, philippe.martinou@vootation.com"
      }
    }
  }
};