/**
 * Helper and Utility functions for the Vote page.
 */

import { TsActiveScrutinViewResponse, TsCandidateResult, TsCodesResponse, TsFinishedScrutinViewResponse, TsOneVoter, TsScrutinViewResponse, TsTestingScrutinViewResponse, TsVoterStatus, TsVoterStatuses } from "../common/tsmodel/TsResponses";

const VOTEURLEXP = /vote\/([^/]+)(\/.*)?/;
const RESULTSURLEXP = /results\/([^/]+)(\/.*)?/;
/** Chars allowed on mac : sid */
const ALLOWED = /^[abcdefghijkmnopqrstuvwxyz0123456789-]*$/;

/**
 * Parses the Vote URL and returns the extracted params.
 * 
 * @param url : ...vote/[scrutinId] or ...vote/[scrutinId]/[mac]
 * @returns [scrutinId, mac]
 */
export const parseVoteUrl = (url: string): [string?, string?] => {
  const mtc = VOTEURLEXP.exec(url);
  if (mtc == null) {
    return [undefined, undefined];
  }
  return [mtc[1], mtc[2] && mtc[2].length > 1 ? mtc[2].substring(1) : undefined];
}

export const parseResultsUrl = (url: string): [string?, string?] => {
  const mtc = RESULTSURLEXP.exec(url);
  if (mtc == null) {
    return [undefined, undefined];
  }
  return [mtc[1], mtc[2] && mtc[2].length > 1 ? mtc[2].substring(1) : undefined];
}

/** For the Active state. */
export const voteUrl = (scrutinId: string) => {
  const resultsUrl = new URL("/vote/" + scrutinId, new URL(document.URL).origin);
  return resultsUrl.toString();
}
/** For the Active state. */
export const resultsUrl = (scrutinId: string) => {
  const resultsUrl = new URL("/results/" + scrutinId, new URL(document.URL).origin);
  return resultsUrl.toString();
}

export const validateMacSid = (val: string): boolean => {
  return ALLOWED.test(val);
}

/** @returns true if at least one Voter status may evolve. */
export const isOneMailStatusPending = (voterStatuses?: TsVoterStatuses): boolean => {
  if (voterStatuses && voterStatuses.voters) {
    for (const voter of voterStatuses.voters) {
      if ((!voter.hasVoted) && voter.sentMessage && (!voter.sentMessage.statusFinal)) {
        return true;
      }
    }
  }
  return false;
}

/** @returns true if the Polling End conditions are met: no pending message status, or max time */
export const isPollingEnd = (maxPollTime: number, voterStatuses?: TsVoterStatuses): boolean => {
  if (voterStatuses && voterStatuses.voters) {
    for (const voter of voterStatuses.voters) {
      if ((!voter.hasVoted) && voter.sentMessage && (!voter.sentMessage.statusFinal) && (voter.sentMessage.sentTime > new Date().getTime() - maxPollTime)) {
        return false;
      }
    }
  }
  return true;
}

/** Updates the TsOneVoter status corresponding to the VoterId of the TsVoterStatus -- DEPRECATED. */
export const updateOneVoterStatus = (aVoterStatus: TsVoterStatus, voterStatuses?: TsVoterStatuses): void => {
  if (voterStatuses && voterStatuses.voters && aVoterStatus) {
    for (const voter of voterStatuses.voters) {
      if (voter.voterId === aVoterStatus.voterId) {
        voter.displayName = aVoterStatus.displayName;
        voter.hasVoted = aVoterStatus.hasVoted;
        voter.sentMessage = aVoterStatus.sentMessage;
        voter.votedTime = aVoterStatus.votedTime;
        break;
      }
    }
  }
}

export const updateVoterMessageStatus = (voter: TsOneVoter, voterStatus: TsVoterStatus): void => {
  if (voter && voterStatus) {
    voter.sentMessage = voterStatus.sentMessage;
  }
}

export const voterById = (voters: TsOneVoter[], voterId: string): TsOneVoter | undefined => {
  if (voters && voterId) {
    for (const voter of voters) {
      if (voter.voterId === voterId) {
        return voter;
      }
    }
  }
}

export const voterCodeById = (codesResponse: TsCodesResponse, voterId: string): string | undefined => {
  if (codesResponse) {
    for (let i = 0; i < codesResponse.voterIds.length; i++) {
      if (codesResponse.voterIds[i] === voterId) {
        return codesResponse.voteCodes[i];
      }
    }
  }
}

export const numVotesIfAvailable = (scrutinView: TsScrutinViewResponse): number => {
  if (scrutinView.sStatus === 'ACTIVE') {
    return (scrutinView as TsActiveScrutinViewResponse).numVotes;
  }
  if (scrutinView.sStatus === 'TESTING') {
    return (scrutinView as TsTestingScrutinViewResponse).numVotes;
  }
  if (scrutinView.sStatus === 'FINISHED') {
    return (scrutinView as TsFinishedScrutinViewResponse).numVotes;
  }
  return 0;
}

export const resultsIfAvailable = (scrutinView: TsScrutinViewResponse): TsCandidateResult[] | undefined => {
  if (scrutinView.sStatus === 'TESTING') {
    return (scrutinView as TsTestingScrutinViewResponse).results;
  }
  if (scrutinView.sStatus === 'FINISHED') {
    return (scrutinView as TsFinishedScrutinViewResponse).results;
  }
  return undefined;
}