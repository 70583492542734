import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { Accueil } from './Accueil';
import { ResultsView } from '../uicomponents/ResultsView';
import { EditorContainer } from '../uicomponents/editor/EditorContainer';
import { TestView } from '../uicomponents/TestView';
import { AdminView } from '../uicomponents/AdminView';
import { AppHeader } from '../uicomponents/AppHeader';
import { VotePage } from '../voteui/VotePage';
import { About } from './About';
import i18next from 'i18next';
import { EventEmitter } from 'events';

export const LangSignal = new EventEmitter();
export class Root extends React.Component<{}, { lang: string }> {

  constructor(props: {}) {
    super(props);
    this.state = { lang: i18next.language };
  }

  componentDidMount() {
    LangSignal.on('setlang', () => {
      this.setState({ lang: i18next.language });
    });
  }

  public render() {
    // Maybe: /fr/.. /en/... /dflt/...
    return (

      <BrowserRouter>
        <Route path="/" component={AppHeader} />
        <Route path="/" exact component={Accueil} />
        <Route path="/about" component={About} />
        <Route path='/create' component={EditorContainer} />
        <Route path='/edit/:mac/:scrutinId' component={EditorContainer} />
        <Route path='/admin/:mac/:scrutinId' component={AdminView} />
        <Route path='test/:mac' component={TestView} />
        <Route path='/results/' component={ResultsView} />
        <Route path='/vote/'>
          <VotePage />
        </Route>
      </BrowserRouter>
    );
  }
}
